import { AddressBookRecord } from "./addressBookRecord";

export enum LetterTypes
    {
        PROVIDER_LETTER = 10121,
        MEMBER_LETTER = 10119
    }

export class LetterParameters {
    authIntakeId!: number;
    authIntakeNotificationId!: number;
    freeFormCount!: number;
    letterTypeId!: LetterTypes;
    authDetailNumber!: string;
    isCover!: boolean;
    userName!: string;
    rfiId!: number;
    reportName!: string;
    isUpdate!: boolean;
    timeZoneOffset!: number;
    memberId!: string;
    address!: AddressBookRecord;
    authDetailId!: number;
}

