import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { DialogService } from './shared/guards/dialog.service';
import { Subscription } from 'rxjs';
import { AppSettings } from './shared/appsettings';
import { HttpClient } from '@angular/common/http';
import { SignalrService } from './services/signalr.service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  public userAuthenticated = false;
  lastPing?: Date = undefined;
  idleState = 'Not started.';
  timedOut = false;
  public constructor(private titleService: Title, private authService: AuthService,
    private appSettings: AppSettings, public signalRService: SignalrService, private http: HttpClient, private router: Router,
    private idle: Idle, private keepalive: Keepalive) {
    this.setTitle(`Affinitë - v${environment.appVersion}`);
    
    this.authService.loginChanged
      .subscribe(userAuthenticated => {
        this.userAuthenticated = userAuthenticated;
      })

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
        if (this.browserRefresh){
          this.authService.BrowserRefresh();
        }
      }
    });

    let idleTimeout = this.appSettings.idleTimeoutSeconds;
    let defaultTimeout = 15 * 60; //minutes
    let systemIdleTimeout = defaultTimeout;

    if (idleTimeout) systemIdleTimeout = +idleTimeout;

    idle.setIdle(systemIdleTimeout);
    idle.setTimeout(15); //seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();

      Swal.close();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.authService.logout();

      Swal.close();
      Swal.fire('Timed Out!', 'Your session has timed out and you need to log in again!', 'warning');
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ll be logged out in 15 seconds!';

      Swal.fire({
        title: 'Session Timing Out',
        text: this.idleState,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#5890a3',
        cancelButtonColor: '#d22329',
        confirmButtonText: 'Keep me logged in!'
      }).then((result) => {
        if (result.value) {
          this.stay();
        }
      });
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.authService.loginChanged
      ?.subscribe((user) => {
        if (user) {
          idle.watch();
          this.timedOut = false;
        } else {
          idle.stop();
        }
      });

    // this.subscription = this.eventService.subscribe('idleTimeout', (payload) => {
    //   let idleTimeout = payload.idleTimeout;
    //   let defaultTimeout = 15;
    //   let systemIdleTimeout = defaultTimeout;
  
    //   if (idleTimeout) systemIdleTimeout = +idleTimeout * 60;
  
    //   idle.setIdle(systemIdleTimeout);
    // });
  }
  
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  stay() {
    this.reset();
  }

  displayHeader = true;
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  bnIdleSub!: Subscription;
  authSub!: Subscription;
  user!: any;
  browserRefresh = false;

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.authSub?.unsubscribe();
  }
}
