import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { Task } from "../../../../models/task/task";
import { Observable, Subscription, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TASKTYPE, TASKPRIORITY, TASK_SNAPSHOTFILTERS, MYQUEUETASKS } from 'projects/um/src/assets/constants';
import { LookupValue } from 'projects/um/src/app/models/Lookups/lookupValue';
import { LookupService } from 'projects/um/src/app/services/lookup.service';
import { TokenStorageService } from 'projects/um/src/app/services/token-storage.service';
import { FilterService } from 'projects/um/src/app/services/filter-service.service';
import { QueueService } from 'projects/um/src/app/services/queue.service';
import { User } from 'projects/um/src/app/models/user';
import { ConfigService } from 'projects/um/src/app/services/config-service.service';

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
})

export class TaskFilterComponent implements OnInit, OnDestroy {
@ViewChild('filterTaskDialogTemplate') filterTaskTemplate: TemplateRef<any> | undefined;
@Input() openDialog!: EventEmitter<any>;
@Input() tasks!: Task[];
@Input() filterType!: string;
@Output() dialogEvent: EventEmitter<Task[]> = new EventEmitter<Task[]>();
@Output() displayCountEvent: EventEmitter<any> = new EventEmitter<any>();

@Input() badgeData!: any;

taskQueueFilterGroup: UntypedFormGroup;
taskTypes!: LookupValue[];
taskPriorities!: LookupValue[];

userConfig: any;
directReports!: Observable<User[]>|undefined;
isManager: boolean = false;

constructor (private dialog: MatDialog, private lookupService: LookupService, private tokenService: TokenStorageService, 
  private filterService: FilterService, private queueService: QueueService, private configService: ConfigService) {
  
      this.taskQueueFilterGroup = new UntypedFormGroup({
        dueDateStart: new UntypedFormControl(),
        dueDateEnd: new UntypedFormControl(),
        typeId: new UntypedFormControl(),
        priorityId: new UntypedFormControl(),
        memberId: new UntypedFormControl(),
        authNumber: new UntypedFormControl(),
        snapshotFilter: new UntypedFormControl(),
        owner: new UntypedFormControl()
      });
}

sub!: Subscription;
authStatus!: LookupValue[];
authStatusReason!: LookupValue[];

ngOnInit(): void {
  this.sub = this.lookupService.LookupData.subscribe(data => {
    this.authStatus = data.filter(d => d.lookupSetID == 116);
    this.authStatusReason = data.filter(d => d.lookupSetID == 128);
  });
  this.sub.add(this.queueService.SnapshotFilterToApply.subscribe(data => {
    if (data && data.filterType !== -1 && TASK_SNAPSHOTFILTERS.includes(data.filterType) && this.tasks && this.tasks.length >0){
      if (this.filterType == MYQUEUETASKS) {
        this.resetFilter();
        this.taskQueueFilterGroup.controls['snapshotFilter'].setValue(data.filterType);
        this.filterService.saveAsSessionFilter(this.taskQueueFilterGroup, this.filterType);
        this.tasks = this.filterService.applySessionFilter(this.tasks, this.filterType, [this.authStatus, this.authStatusReason]);
        this.displayCountEvent.emit(this.tasks.filter(a => a.hidden == false).length);
        this.dialogEvent.emit(this.tasks);
      }
    }
  }));
  this.sub.add(this.lookupService.LookupData.subscribe(data => {
    this.taskTypes = data.filter(t => t.lookupSetID == TASKTYPE)
  }));          
  this.sub.add(this.lookupService.LookupData.subscribe(data => {
    this.taskPriorities = data.filter(t => t.lookupSetID == TASKPRIORITY)
  }));

  this.openDialog.subscribe(() => {
    const dialogRef = this.dialog.open(DialogComponent, {
        width: '30em',
        data: <IDialogConfig>{
        title: 'Filter Tasks',
        dialogContent: this.filterTaskTemplate,
        acceptButtonTitle1: 'Apply',
        acceptButtonTitle2: 'Reset',
        declineButtonTitle: 'Cancel'
        }
    });

    const group = this.filterService.getSessionFilter(this.filterType);
    this.taskQueueFilterGroup = this.filterService.getSavedGroup(group, this.taskQueueFilterGroup);

    this.sub.add(dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
        if (this.taskQueueFilterGroup.valid) {
          this.filterService.saveAsSessionFilter(this.taskQueueFilterGroup, this.filterType);
          this.tasks = this.filterService.applySessionFilter(this.tasks, this.filterType);  
          this.displayCountEvent.emit(this.tasks.filter(a => a.hidden == false).length);
          this.dialogEvent.emit(this.tasks);            
          dialogRef.close();
        }
      }));
  
      this.sub.add(dialogRef.componentInstance.clickAcceptButton2.subscribe(() => {
        this.resetFilter();
        this.dialogEvent.emit(this.tasks);
      }));

      this.userConfig = this.tokenService.getUserConfig();
      if (this.userConfig?.role?.toLowerCase() === 'um manager'){
        this.isManager = true;
      } else {
        this.isManager = false;
      }
      
      if (this.userConfig?.role?.toLowerCase() === 'um manager' && this.filterType == MYQUEUETASKS) {
        this.directReports = this.configService.getDirectReports()?.pipe(
          map((response: User[])=>{
            return response;
          })
        );
      } 
    });
}

ngOnDestroy(): void {
    this.sub.unsubscribe();
}

resetFilter() {
    this.taskQueueFilterGroup.reset();
    this.tasks.map(f => { f.hidden = false;} );
    this.filterService.resetSessionFilter(this.filterType);
  }
}
