import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from "../../../models/task/task";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DATE_WITH_SECONDS_AMPM } from 'projects/um/src/assets/constants';
import { AppSettings } from '../../appsettings';

@Component({
  selector: 'app-queue-task',
  templateUrl: './queue-task.component.html',
  styleUrls: ['./queue.component.scss']
})
export class TaskQueueComponent implements OnInit {
  @Input() task!: Task;
  @Input() isSelectable: boolean = false;
  @Input() isMyTask: boolean = false;
  @Output() taskAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private appSettings: AppSettings) {
    this.timezone = this.appSettings.timeZone;
   }

  onChange(ob: MatCheckboxChange) {
    this.taskAction.emit({ checked:ob.checked, taskId: this.task.taskId });
  } 

  isPastDue = false;
  isSemiUrgent = false;
  taskClass!: string;
  urgentTooltipText = 'Urgent Request';
  DateFormat = DATE_WITH_SECONDS_AMPM;
  timezone = '';
  ngOnInit(): void {
    this.isPastDue = new Date(this.task.dueDate) < new Date();
    const hours = this.calculateDateDiff(this.task);
    this.isSemiUrgent =  hours < 24 && hours > 0;
    this.taskClass = this.isPastDue ? 'urgent' : this.isSemiUrgent ? 'semi-urgent' : this.task.showAsNew ? 'new' : '';
  }

  calculateDateDiff(data:Task){
    let date = new Date(data.dueDate);
    let currentDate = new Date();

    let hours = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 );
    return hours;
  }
}
