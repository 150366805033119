import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core"
import { Subscription } from "rxjs";
import { ACCESS_MASK_CREATE, ACCESS_MASK_DELETE, ACCESS_MASK_EDIT, ACCESS_MASK_READ, ACCESS_MASK_DOWNLOAD, ACCESS_MASK_PRINT, ACCESS_MASK_SAVE } from "../../../assets/constants";
import { AccessControl } from "../../models/rbac/accessControl";
import { AccessList } from "../../models/rbac/accessList";
import { ConfigService } from "../../services/config-service.service";
import { TokenStorageService } from "../../services/token-storage.service";

@Directive({
  selector: '[controlBehavior]',
})
//#8409 - Updated access rights for UR Research Reviewer in DB
export class ControlBehaviorDirective implements OnInit, OnDestroy {
  @Input() controlBehavior: string = '';
  @Input() overrideProperty: string = '';
  //@Input() overrideValue: boolean = false;
  @Input()
  set overrideValue(condition:boolean) {
    this._overrideValue = condition
    this.applyOverrides();
  }
  @Input() action: string = '';
  _overrideValue = false;
  disable = true;
  accessControl!: AccessList[];
  rbac!: AccessControl;
  accessSub!: Subscription;
  userConfig: any;

  constructor(private elmRef: ElementRef,
    private renderer: Renderer2, private configService: ConfigService, private tokenStorage: TokenStorageService) {
    this.userConfig = this.tokenStorage.getUserConfig();
  }

  ngOnInit(): void {
    this.accessSub = this.configService.accessList.subscribe(data => {
      if (data.length > 0) {
        this.accessControl = data;
        this.setAccessRights();
        this.setControlAttributes();
        this.applyOverrides();
      }
    });
  }

  applyOverrides(): void {
    if (this.overrideProperty && this.overrideProperty == 'readonly') {
      if (this._overrideValue) {
        this.disableControl();
      } else if (!this._overrideValue) {
        this.enableControl();
      } else if (this.rbac && (!this.rbac.canEdit && !this.rbac.canCreate)) {
        this.disableControl();
      } else {
        this.enableControl();
      }
    } else if (this.overrideProperty && this.overrideProperty == 'visible') {
      if (this._overrideValue && !this.action) {
        this.showControl();
      } else if (this.action) {
        this.setActionRbac();
      }
      if (!this._overrideValue && this.action) {
        this.hideControl();
      }
      if (!this._overrideValue && !this.action) {
        this.hideControl();
      }
    }
  }

  ngOnDestroy() {
    this.accessSub?.unsubscribe();
  }

  setActionRbac() {
    switch (this.action.toLowerCase()) {
      case 'edit':
        {
          if (this.rbac && this.rbac.canEdit) {
            this.showControl();
          } else {
            this.hideControl();
          }
        }
        break;
      case 'delete':
        {
          if (this.rbac && this.rbac.canDelete) {
            if (this.overrideProperty) { //There is an override for this action
              if (this._overrideValue) {
                this.showControl();
              } else {
                this.hideControl();
              }
            } else { //No Override
              this.showControl();
            }
          } else {
            if (this.overrideProperty) { //There is an override for this action
              if (this._overrideValue) {
                this.showControl();
              } else {
                this.hideControl();
              }
            } else {
              this.hideControl();
            }
          }
        }
        break;
      case 'print':
        {
          if (this.rbac && this.rbac.canPrint) {
            this.showControl();
          } else {
            this.hideControl();
          }
        }
        break;
      case 'download':
        {
          if (this.rbac && this.rbac.canDownload) {
            this.showControl();
          } else {
            this.hideControl();
          }
        }
        break;
      case 'save':
        {
          if (this.rbac && (this.rbac.canEdit || this.rbac.canCreate)) {
            this.enableControl();
          } else {
            this.disableControl();
          }
        }
        break;
      case 'preview':
        {
          if (this.rbac && (this.rbac.canDownload || this.rbac.canRead)) {
            this.enableControl();
          } else {
            this.disableControl();
          }
        }
        break;
      case 'refer':
        {
          if (this.rbac && (this.userConfig.role.toLowerCase() == 'it support' || this.rbac.canEdit)) {
            this.enableControl();
          } else {
            this.disableControl();
          }
        }
        break;
    }
  }

  setControlAttributes() {
    if (this.rbac) {
      if (!this.action) { //Access for the top most level element. e.g. the Tab
        if (!this.rbac.canCreate && !this.rbac.canEdit && this.rbac.canRead) {
          this.disableControl();
        } else if ((this.rbac.canCreate && this.rbac.canEdit) && this.rbac.canRead) {
          this.enableControl();
        } else if (this.rbac.canCreate) {
          this.enableControl();
        } else if (!this.rbac.canRead) {
          this.hideControl();
        } else if (!this.rbac.canSave) {
          this.disableControl();
        }
      } else {
        this.setActionRbac();
      }
    } else {
      this.hideControl();
    }
  }

  setAccessRights() {
    if (this.accessControl && this.controlBehavior) {
      const controlRbac = this.accessControl.find(a => a.appClassDescription?.toLowerCase() == this.controlBehavior?.toLowerCase())?.accessList;
      if (controlRbac) {
        this.rbac = new AccessControl();
        this.rbac.canRead = (controlRbac & ACCESS_MASK_READ) != 0;
        this.rbac.canCreate = (controlRbac & ACCESS_MASK_CREATE) != 0;
        this.rbac.canEdit = (controlRbac & ACCESS_MASK_EDIT) != 0;

        this.rbac.canDelete = (controlRbac & ACCESS_MASK_DELETE) != 0;

        this.rbac.canDownload = (controlRbac & ACCESS_MASK_DOWNLOAD) != 0;

        this.rbac.canPrint = (controlRbac & ACCESS_MASK_PRINT) != 0;
        this.rbac.canSave = (controlRbac & ACCESS_MASK_SAVE) != 0;
      }
    }
  }

  disableControl() {
    this.renderer.setAttribute(this.elmRef.nativeElement, 'disabled', 'true');
  }

  enableControl() {
    this.renderer.removeAttribute(this.elmRef.nativeElement, 'disabled');
  }

  hideControl() {
    this.renderer.setAttribute(this.elmRef.nativeElement, 'hidden', 'true');
  }

  showControl() {
    this.renderer.removeAttribute(this.elmRef.nativeElement, 'hidden');
  }
}
