import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FaxQueueItem } from 'projects/um/src/app/models/fax/faxQueueItem';
import { DATE_WITH_SECONDS_AMPM } from '../../../../../assets/constants';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { FormService } from 'projects/um/src/app/services/form-service.service';
import { SignalrService } from 'projects/um/src/app/services/signalr.service';
import { ConfirmDialogComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { AppSettings } from '../../../appsettings';

@Component({
  selector: 'app-fax-queue',
  templateUrl: './fax-queue.component.html',
  styleUrls: ['./fax-queue.component.scss']
})
export class FaxQueueComponent implements OnInit {
  @Input() item!: FaxQueueItem;
  @Output() removeFaxes = new EventEmitter<{pdfId: string, action: string}>();

  dateFormat = DATE_WITH_SECONDS_AMPM;
  splittingFax = false;
  selectedFax!: {pdfId: string, action: string};
  userConfig: any;
  timezone: string = '';
  constructor(private tokenStorage: TokenStorageService, private formService: FormService, private signalR : SignalrService, private confirmDialog: ConfirmDialogComponent,
      private appSettings: AppSettings) {
    this.userConfig = this.tokenStorage.getUserConfig();
    this.timezone = this.appSettings.timeZone;
  }

  ngOnInit(): void {
  }

  onChange($event: any) {
    if ($event.checked) {
      this.selectedFax = {pdfId:$event.source.value, action:'add'};
    } else {
      this.selectedFax = {pdfId:$event.source.value, action:'remove'};
    }
    this.removeFaxes.emit(this.selectedFax);
  }

  unlockFax(item: FaxQueueItem){
	if (item.locked && this.userConfig.role.toLowerCase() == 'superuser') {
		const dialogRef = this.confirmDialog.open({
			title: 'Unlock Fax',
			message: `Are you sure you want to unlock this fax?`,
			acceptButtonText: 'Yes',
			declineButtonText: 'No'
		});

		dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
			if (item){
				this.formService.unlockFax(item.internalId);
				this.signalR.broadcastMessage(`unlocked:${item.internalId}`);
				this.signalR.clientMessage(`Fax ${item.name} has been unlocked by Supervisor`, item.lockedByUserName);
			}
			dialogRef.close();
		});

		dialogRef.componentInstance.clickDeclineButton.subscribe(() => {
			dialogRef.close();
		})
	}
  }
}
