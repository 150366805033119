import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@CommonLib/components/material/material.module';
import { TableComponent } from './table.component';
import { DataPropertyGetterPipeComponent } from './data-property-getter-pipe/data-property-getter-pipe.component';
import { DateFormatterPipeComponent } from './date-formatter-pipe/date-formatter-pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    TableComponent,
    DataPropertyGetterPipeComponent,
    DateFormatterPipeComponent
  ],
  exports: [
    TableComponent,
    DataPropertyGetterPipeComponent,
    DateFormatterPipeComponent
  ]
})
export class TableModule { }
