<div class="queue-container" [ngClass]=[taskClass] data-layout="row" >
  <div class="select-queue" *ngIf="isSelectable">
    <mat-checkbox aria-label="Select or deselect task ID {{ task.taskId.toString() }}" [(ngModel)]="task.checked" (change)="onChange($event)" [value]="task.taskId.toString()"></mat-checkbox>
  </div>
  <div class="queue-content full-width">
    <div class="data-list">
      <ul>
        <li class="mb-5">
          <span class="label cdk-visually-hidden">Member Name: </span>
          <span class="value"><span class="data-header">{{ task.memberName }}</span></span>
        </li>
        <li>
          <span class="label">Member ID: </span>
          <span class="value">{{ task.memberId }}</span>
        </li>
        <li>
          <span class="label">Task Type: </span>
          <span class="value">{{ task.taskType }}</span>
        </li>
        <li>
          <span class="label">Due: </span>
          <span class="value">{{ task.dueDate | date: DateFormat : timezone}}</span>
        </li>
        <li class="with-icon">
          <span class="label">Priority: </span>
          <span class="value" [ngClass]="{'text-danger': task.priorityRank == 1}">
            {{ task.priorityDescription }}
          </span>
          <mat-icon class="material-icons-outlined icon-danger icon-size-xs suffix" aria-hidden="false" title="Urgent Request" aria-label="Urgent Request" *ngIf="task.priorityRank == 1">error</mat-icon>
        </li>
        <li>
          <span class="label">Authorization #: </span>
          <span class="value"><a [routerLink]="['member/authorization-intake/'+ task.authIntakeId + '/' + task.authDetailNumber]" [skipLocationChange]="true">{{ task.authDetailNumber + '-' + task.authNumber }}</a></span>
        </li>
      </ul>
    </div>
  </div>
  <div class="action" *ngIf="isMyTask">
    <button mat-icon-button title="View this task" aria-label="View this task" (click)="dialogTask.openTaskDialog(task)">
      <mat-icon class="icon" aria-hidden="false">visibility</mat-icon>
    </button>
  </div>
</div>

<app-dialog-task #dialogTask></app-dialog-task>

