import { Component, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { EventEmitter } from '@angular/core';
import { User }  from "../../../../models/user";
import { ReferTaskTypes } from "../../../../../assets/constants";
import { Queue } from '../../../../models/configuration/queue';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from 'projects/um/src/app/services/config-service.service';

@Component({
  selector: 'app-dialog-auth',
  templateUrl: './dialog-auth.component.html',
  styleUrls: ['./dialog-auth.component.scss']
})
export class DialogAuthComponent implements OnInit, OnDestroy {
  @ViewChild('referAuthDialogTemplate') referAuthTemplate: TemplateRef<any> | undefined;

  @Output() referDialogEvent: EventEmitter<any> = new EventEmitter<any>();

  teamMemberSearchCtrl = new UntypedFormControl(null, Validators.required);
  referGroup = new UntypedFormGroup({
    workQueueCtrl: new UntypedFormControl({value: null, disabled: true}, Validators.required),
  })

  get workQueueCtrl() {
    return this.referGroup.controls['workQueueCtrl'];
  }
  filteredOptions!: Observable<User[]>;
  workQueueTypes!: Queue[];


  constructor(private dialog: MatDialog, private configService: ConfigService) {
 }

  //Refer Auth Dialog
  openReferAuthDialog(sensitiveData: boolean, healthPlanEmployee: boolean = false) {
    if (sensitiveData){
      this.userListData = this.userListData.filter(u => u.canViewSensitiveData);
    }
    if (healthPlanEmployee){
      this.userListData = this.userListData.filter(u => u.employeeDataAccess);
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '35em',
      data: <IDialogConfig>{
        title: 'Refer Authorization',
        dialogContent: this.referAuthTemplate,
        acceptButtonTitle1: 'Refer',
        declineButtonTitle: 'Cancel'
      }
    });

    dialogRef.componentInstance.clickDeclineButton.subscribe(e => {
      dialogRef.close();
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(e => {
      if (this.validateReferDialog()) {
        this.referDialogEvent.emit({ referType: this.referType, workQueueTypeId: this.workQueueTypeId, teamMemberId: this.referSelectedUserName });
        this.teamMemberSearchCtrl.reset();
        this.referGroup.reset();
        dialogRef.close();
      }
    });
  }

  //toggle active state for the sections
  //not really an elegant way.. room for improvement
  referType: number = 0;
  workQueueTypeId!: string;
  userListData!: User[];
  sub6!: Subscription;
  referSelectedUserName!: string;
  groupSub!: Subscription;

  sectionId: string = '';
  myAssignmentsSelected: boolean = true;
  workQueueSelected: boolean = false;
  
  teamMemberSelected: boolean = false;
  toggleActiveState(event: any) {
    this.myAssignmentsSelected = false;
    this.workQueueSelected = false;
    this.referGroup.controls['workQueueCtrl'].disable();
    this.teamMemberSelected = false;
    this.sectionId = event.target.closest('section').id;
    if (this.sectionId == 'myAssignments') {
      this.referType = ReferTaskTypes.MY_ASSIGNMENT;
      this.myAssignmentsSelected = true;
      this.teamMemberSearchCtrl.reset();
      this.workQueueCtrl.reset();    }
    if (this.sectionId == 'workQueue') {
      this.referType = ReferTaskTypes.WORK_QUEUE;
      this.workQueueSelected = true;
      this.referGroup.controls['workQueueCtrl'].enable();
      this.teamMemberSearchCtrl.reset();
    }
    if (this.sectionId == 'teamMember') {
      this.referType = ReferTaskTypes.TEAM_MEMBER;
      this.teamMemberSelected = true;
      this.workQueueCtrl.reset();
    }
  };

  OnQueueSelectionChange(event: any) {
    this.workQueueTypeId = event.value;
  }

  selectedTeamMember(event:any) {
    this.referSelectedUserName = event.option.value.userName;
  }
  
  ngOnInit(): void {
    this.sub6 = this.configService.userList.subscribe(data => {
      this.userListData = data;
      this.filteredOptions = this.teamMemberSearchCtrl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (value != undefined) {
            if (typeof value === 'string' ) return  value; 
            else return value.userName;
          }}),
        map(userName => userName ? this._filter(userName) : this.userListData.slice())
      );
    });
    this.groupSub = this.configService.groupList.subscribe(data => {
      if (data.length > 0) {
        this.workQueueTypes = data;
      }
    });
  }

  ngOnDestroy():void {
    this.groupSub?.unsubscribe();
    this.sub6?.unsubscribe();
  }

  displayFn(user: User): string {
    return user && (user.firstName || user.lastName) ? user.firstName+' '+user.lastName: '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.userListData.filter(option => {
      return option.firstName?.toLowerCase().includes(filterValue) ||
              option.lastName?.toLowerCase().includes(filterValue);
    });
  }

  private validateReferDialog():boolean {
    let isClose = true;
    switch (this.referType) {
      case ReferTaskTypes.TEAM_MEMBER:
        if (!this.teamMemberSearchCtrl.valid || this.teamMemberSearchCtrl.value === null) {
          this.teamMemberSearchCtrl.markAllAsTouched();
          isClose = false;
        }
      break;
      case ReferTaskTypes.WORK_QUEUE:
        if (!this.referGroup.valid || this.workQueueCtrl.value === 0) {
          this.referGroup.markAllAsTouched();
          isClose = false;
        }
      break;
    }
    return isClose;
  }
}
