import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { Observable, Subscription, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LookupValue } from 'projects/um/src/app/models/Lookups/lookupValue';
import { LookupService } from 'projects/um/src/app/services/lookup.service';
import { TokenStorageService } from 'projects/um/src/app/services/token-storage.service';
import { AuthQueueData } from '../../queue/AuthQueueData';
import { Queue } from 'projects/um/src/app/models/configuration/queue';
import { ConfigService } from 'projects/um/src/app/services/config-service.service';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe.decorator';
import { FilterService } from 'projects/um/src/app/services/filter-service.service';
import { QueueService } from 'projects/um/src/app/services/queue.service';
import { MYQUEUEAUTHS, TASK_SNAPSHOTFILTERS } from 'projects/um/src/assets/constants';
import { User } from 'projects/um/src/app/models/user';
import { AppSettings } from '../../../appsettings';

@AutoUnsubscribe

@Component({
  selector: 'app-auth-filter',
  templateUrl: './auth-filter.component.html',
})

export class AuthFilterComponent implements OnInit {
@ViewChild('filterAuthDialogTemplate') filterAuthTemplate: TemplateRef<any> | undefined;
@Input() openDialog!: EventEmitter<any>;
@Input() auths!: AuthQueueData[];
@Output() dialogEvent: EventEmitter<any> = new EventEmitter<any>();
@Input() filterType!: string;
@Output() displayCountEvent: EventEmitter<any> = new EventEmitter<any>();


authFilterGroup!: UntypedFormGroup;
authType!: LookupValue[];
urgency!: LookupValue[];
authStatus!: LookupValue[];
authStatusReason!: LookupValue[];
providerList!: string[];
lobList!: string[];
queueList!: Queue[];
orgRegionList!: string[];
reqTypeList!: string[];
stateList!: string[];
snapshotFilter!: number;
placeofServiceList!: string[];

sub!: Subscription;

userConfig: any;
directReports!: Observable<User[]>|undefined;
isManager: boolean = false;
maxQueueCount = 0;

constructor (public dialog: MatDialog, private lookupService: LookupService, private tokenService: TokenStorageService, 
  private filterService: FilterService, private configService: ConfigService, private queueService: QueueService, private appSettings: AppSettings) {
  
  this.authFilterGroup = new FormGroup({
    dueDateStart: new FormControl(),
    dueDateEnd: new FormControl(),
    authTypeId: new FormControl(),
    urgencyID: new FormControl(),
    authStatusID: new FormControl(),
    srvcProvider: new FormControl(),
    memberId: new FormControl(),
    authNumber: new FormControl(),
    lob: new FormControl(),
    authGroupId: new FormControl(),
    cmOrgRegion: new FormControl(),
    requestType: new FormControl(),
    srvcState: new FormControl(),
    snapshotFilter: new FormControl(),
    placeofService: new FormControl(),
    authOwner: new FormControl()
  });

  this.userConfig = this.tokenService.getUserConfig();
  this.maxQueueCount = this.appSettings.maxQueueCount;
}

ngOnInit(): void {  
  this.sub = this.lookupService.LookupData.subscribe(data => {
    this.authType = data.filter(d => d.lookupSetID == 108);
    this.urgency = data.filter(d => d.lookupSetID == 109);
    this.authStatus = data.filter(d => d.lookupSetID == 116);
    this.authStatusReason = data.filter(d => d.lookupSetID == 128);
  });

  this.sub.add(this.configService.groupList.subscribe(data => {
    if (data.length > 0) {
      this.queueList = data;
    }
  }));

  this.sub.add(this.queueService.SnapshotFilterToApply.subscribe(data => {
    if (data && data.filterType !== -1 && !TASK_SNAPSHOTFILTERS.includes(data.filterType)){
      if (this.filterType == MYQUEUEAUTHS) {
        this.resetFilter();
        this.authFilterGroup.controls['snapshotFilter'].setValue(data.filterType);
        this.filterService.saveAsSessionFilter(this.authFilterGroup, this.filterType);
        this.auths = this.filterService.applySessionFilter(this.auths, this.filterType, [this.authStatus, this.authStatusReason]);
        this.displayCountEvent?.emit(this.auths.filter(a => a.hidden == false).length);
      }
    }
  }));

  this.openDialog.subscribe((value) => {
    if (value){
      const dialogRef = this.dialog.open(DialogComponent, {
          width: '30em',
          data: <IDialogConfig>{
          title: 'Filter Authorizations',
          dialogContent: this.filterAuthTemplate,
          acceptButtonTitle1: 'Apply',
          acceptButtonTitle2: 'Reset',
          declineButtonTitle: 'Cancel'
          }
      });

      var availableQueues: number[] = [];
      this.lobList = [...Array.from(new Set(this.auths.map((a) => a.lob)))];
      this.stateList = [...Array.from(new Set(this.auths.map((a) => a.srvcState).filter(a => a !== null)))];
      this.placeofServiceList = [...Array.from(new Set(this.auths.map((a) => a.placeofService).filter(a => a !== null)))];
      availableQueues = [...Array.from(new Set(this.auths.map((a) => a.authGroupId)))];
      this.reqTypeList = [...Array.from(new Set(this.auths.map((a) => a.requestType)))];
      this.orgRegionList = [...Array.from(new Set(this.auths.map((a) => a.cmOrgRegion)))];

      if (availableQueues.length > 0 && (availableQueues.length == 1 && availableQueues[0] !== 0)) {
        this.queueList = this.queueList?.filter(({ queueId }) => availableQueues.includes(queueId));
      }   
      this.providerList = [];
      
      this.auths.forEach(element => {
        if (element.srvcProvider && element.srvcProvider.trim().length > 0 && this.providerList.indexOf(element.srvcProvider) < 0)
          this.providerList.push(element.srvcProvider);
      });

      const group = this.filterService.getSessionFilter(this.filterType);
      this.authFilterGroup = this.filterService.getSavedGroup(group, this.authFilterGroup);
    
      this.sub.add(dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
          if (this.authFilterGroup.valid) {
            this.dialogEvent.emit(true);
            this.filterService.saveAsSessionFilter(this.authFilterGroup, this.filterType);
            this.auths = [...this.filterService.applySessionFilter(this.auths, this.filterType,  [this.authStatus, this.authStatusReason])];
            this.displayCountEvent.emit(this.auths.filter(a => a.hidden == false).length);
            dialogRef.close();
          }
      }));

      this.sub.add(dialogRef.componentInstance.clickAcceptButton2.subscribe(() => {
          this.resetFilter();
          this.dialogEvent.emit(true);
      }));
      this.userConfig = this.tokenService.getUserConfig();
      if (this.userConfig?.role?.toLowerCase() === 'um manager' || this.userConfig?.role?.toLowerCase() === 'superuser'){
        this.isManager = true;
      } else {
        this.isManager = false;
      }
        
      if ((this.userConfig?.role?.toLowerCase() === 'um manager' || this.userConfig?.role?.toLowerCase() === 'superuser') && this.filterType == MYQUEUEAUTHS) {
        this.directReports = this.configService.getDirectReports()?.pipe(
          map((response: User[])=>{
            return response;
          })
        );
      } 
    }
  });
}

  resetFilter() {
    this.authFilterGroup.reset();
    this.auths.map(f => { f.hidden = false; });
    this.auths = [...this.auths.slice(0,this.maxQueueCount)];
    this.filterService.resetSessionFilter(this.filterType);
  }

  getSessionFilter(formGroup: UntypedFormGroup, filter_type: string) {
    const filterMap = this.tokenService.getSessionFilter(filter_type) as Map<string,string>;
    let filterValues: {[k: string]: any} = {};
    if (filterMap) {
    filterValues = filterMap.get(filter_type) as Object;
        if (filterValues) {
          let keys = Object.keys(filterValues)
          for (let key of keys) {
              const value = filterValues[key]
              formGroup.controls[key].setValue(value);
          }
        }
        return formGroup;
    }
        return null;
  }
  
}
