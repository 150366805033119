export class AuthIntakeReviewDetail {
  authReviewDetailId!: number;
  authDetailID!: number;
  authReviewId!: number;
  reviewTypeId!: number;
  guidelineTypeId!: number;
  guidelineName!: string;
  guidelineUrl!: string;
  guidelineRecUnits!: number | null;
  guidelineResults!: string;
  guidelineDesc!: string;
  authReviewNotes!: string;
  rationale!: string;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete: boolean = false;
  copiedValue: boolean = false;
  readOnly: boolean = false;
}
