export class AuthIntakeDisposition {
    authIntakeDispositionId!: number;
    authIntakeId!: number;
    actualDischargeDate!: string|null;
    dispositionId!: number;
    dispositionNotes!: string;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete: boolean = false;
}
