import { NotificationLetter } from "./NotificationLetter";
import { OutboundDocument } from "./OutboundDocument";

export class UMRuleCheckInput {
  authIntakeId!: number;
  authDetailId!: number;
  authorizationNumber!: string;
  memberId!: string;
  mvdid!: string;
  userName!: string;
  userRole!: string; //Logged in user RoleName
  userQueues!: string[] | null;
  formEvent!: string; //SaveForm/SubmitForm
  outboundDocuments!: OutboundDocument[] | null;
  NotificationLetters!: NotificationLetter[] | null;
}
