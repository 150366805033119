<div class="queue-utilities" data-layout="row" data-layout-align="space-between center">
  <button mat-flat-button color="vdt-primary"
          (click)="removeCompletedFax()"
          [disabled]="selectedFax.length == 0"
          matTooltip="Remove Completed"
          matTooltipPosition="below"
          matTooltipClass="tooltip"
          aria-label="Remove Completed">
    Remove: {{ selectedFax.length }}
  </button>
  <div data-layout="row" data-layout-align="space-between center">
    <span class="count">Showing: {{ faxesCount }}</span>
    <app-refresh (iconClick)="refreshQueue()" [title]="refreshTitleText"></app-refresh>
  </div>
  <button mat-icon-button title="Filter queue" (click)="openFilterDialog()" matTooltipClass="multiline-tooltip" matTooltip="{{filterBadgeData.text}}">
    <mat-icon matBadge={{filterBadgeData.count}}
              matBadgeColor="warn"
              matBadgeSize="small"
              class="material-icons-outlined icon-dark"
              aria-hidden="false"
              aria-label="Filter queue"
              [matBadgeHidden]="filterBadgeData.count==0">
      filter_list
    </mat-icon>
  </button>
  <span class="cdk-visually-hidden">
    Notification icon with overlaid badge showing the number 4
  </span>
</div>
<cdk-virtual-scroll-viewport name="teamFaxes" itemSize="10" autosize style="height: calc(100vh - 250px)">
  <div *cdkVirtualFor="let item of queues" class="nowrap">
    <app-fax-queue [item]="item" (removeFaxes)="removeFaxEvent($event)"></app-fax-queue>
  </div>
</cdk-virtual-scroll-viewport>

<!-- dialog content: Filter Faxes -->
<ng-template #filterFaxDialogTemplate>
  <form [formGroup]="dateRangeFaxTeamQueue">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Received Date Range</mat-label>
      <mat-date-range-input [rangePicker]="dateRangeFaxTeamQueuePicker" cdkFocusInitial>
        <input matStartDate placeholder="Start date" formControlName="faxStartDate">
        <input matEndDate placeholder="End date" formControlName="faxEndDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangeFaxTeamQueuePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangeFaxTeamQueuePicker></mat-date-range-picker>
    </mat-form-field>

    <hr />

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Work Queue</mat-label>
      <mat-select formControlName="faxQueue"
                  (openedChange)="filterFaxQ.value=''">
        <input matInput placeholder="Search" #filterFaxQ>
        <div *ngFor="let a of faxQueues">
          <mat-option [value]="a.queueId" *ngIf="a.queueName.toLowerCase().includes((filterFaxQ.value).toLowerCase())">{{ a.queueName }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

  </form>
</ng-template>
