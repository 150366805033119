import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, map } from "rxjs";
import { BenefitCheckRuleResult } from "../models/RuleBasedWorkflow/benefitCheckRuleResult";
import { UMRuleCheckInput } from "../models/RuleBasedWorkflow/UMRuleCheckInput";
import { AppSettings } from "../shared/appsettings";
import { NotificationService } from "../shared/notification.service";
import { ApiResponse } from "../models/api/apiResponse";

@Injectable({
  providedIn: 'root'
})

export class RuleEngineWorkflowService {
  private ruleEngineSvcBaseUrl!: string;
  public runTempWorkflowSubj = new Subject<string>();
  public benefitCheckResult = new Subject<BenefitCheckRuleResult>();

  constructor(private http: HttpClient, private appConfig: AppSettings, private notificationService: NotificationService) {
    this.ruleEngineSvcBaseUrl = this.appConfig.ruleEngineSvcBaseUrl;
  }

  startLetterWorkflow(umRuleCheckInput: UMRuleCheckInput) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let runTempWorkflowUrl = this.ruleEngineSvcBaseUrl + "RuleEngine/RunTempWorkflow";
    this.http.post<ApiResponse<string>>(runTempWorkflowUrl, JSON.stringify(umRuleCheckInput), options).subscribe(
      data => {
        if (data.succeeded == true) {
          this.runTempWorkflowSubj.next(JSON.stringify(data));
        }
      }
    );
  }

  runBenefitsCheck(umRuleCheckInput: UMRuleCheckInput): Observable<BenefitCheckRuleResult> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let runTempWorkflowUrl = this.ruleEngineSvcBaseUrl + "RuleEngine/Run";
    return this.http.post<BenefitCheckRuleResult>(runTempWorkflowUrl, JSON.stringify(umRuleCheckInput), options).pipe(
      map((response: BenefitCheckRuleResult)=>{
        return response;
      })
    );
    //   data => {
    //     if (data && data.succeeded) {
    //       this.benefitCheckResult.next(data);
    //     }
    //     else {
    //       if (data.message) {
    //         this.notificationService.success(data.message);
    //       }
    //     }
    //   }
    // );
  }
}
