import { FilterBase } from "../filter/FilterBase";
import { Task } from "./task";

export class TasksData {
  tasks!: Task[];
  rowCount!: number;
  uniqueValues!: string;
  overdueCount!: number;
}

