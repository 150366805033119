import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../_alert';
import { ApplicationInsightService } from './application-insight.service';
import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector, private errorService: ErrorService ) { }

  handleError(error: Error | HttpErrorResponse ) {
    let router = this.injector.get(Router);
    const notificationService = this.injector.get(NotificationService);
    const alertService = this.injector.get(AlertService);
    console.log('URL:' + router.url);
    if (error instanceof HttpErrorResponse) {
      // Server error
      alertService.error(this.errorService.getHttpError(error));
      notificationService.error(this.errorService.getHttpError(error));
      //alert(this.errorService.getHttpError(error));
    } else {
      // Client Error
      //Hide the value changed after initialization error for the purposes of DEMO
      if (error.message.indexOf('NG0100') !== 0) {
        alertService.error(this.errorService.getClientSideError(error));
        notificationService.error(this.errorService.getClientSideError(error));
      }
     // alert(this.errorService.getClientSideError(error));
    }
    // Always log errors
    this.logError(error);
    /**
     * Add reference to ApplicationInsightService in the contructor - private applicationInsights: ApplicationInsightService
     *  Uncomment the following line to start logging errors in ApplicationInsights
     * Additional step - Add the connection string in the environment.ts file
     * appInsightsConfig: {
        connectionString: ''
        }
     * Uncomment the logErrorOnApplicationInsight(error:Error) method below.
     */
    //this.logErrorOnApplicationInsight(error);
  }

  // logErrorOnApplicationInsight(error: Error) {
  //   //const applicationInsightService = this.injector.get(ApplicationInsightService);
  //   //return applicationInsightService.logError(error);
  //   return this.applicationInsights.logError(error);
  // }

  logError(error: Error | HttpErrorResponse) {
    console.error(error);
  }
}
