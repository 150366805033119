import { Injectable } from "@angular/core";
import { FormArray, FormGroup, UntypedFormArray, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { PROVIDER_TAB_NAME, REQUEST_TAB_NAME } from "../../assets/constants";
import { TabError } from "../models/validation/tabError";
import { TabErrorCount } from "../models/validation/tabErrorCount";
import { TabStatus } from "../models/validation/tabStatus";
import { TooltipService } from "./tooltip.service";

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  public requestTabFormGroup = new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  public providerTabFormGroup = new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  public TabStatus = new BehaviorSubject<TabStatus>(new TabStatus());
  public TabErrors = new BehaviorSubject<TabError[]>([]);
  public TabErrorCounts = new BehaviorSubject<TabErrorCount[]>([]);

  tabErrCounts: TabErrorCount[] = [];
  tErrors: TabError[] = [];
  currentTabErrors: TabError[] = [];
  additionalGroup!: UntypedFormGroup[] | undefined;

  constructor(private tooltipService: TooltipService) { }

  resetTabStatus(){
    this.TabStatus.next({tabName:'',tabValid:true})
  }
  
  formValid() {
    this.tabErrCounts.forEach(te => {
      if (te.tabErrorCount > 0) {
        return false;
      }
      return true;
    })

    return true;
  }

  getTabErrors(container: UntypedFormGroup | UntypedFormArray | FormGroup | FormArray, formName: string, validFields?: string[]): number {
    let errorCount = 0;
    this.currentTabErrors = [];
 
    Object.keys(container.controls).forEach(key => {
      const control = container.get(key);

      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        errorCount += this.getTabErrors(control, formName, validFields);
        return;
      }
      if (control instanceof FormGroup || control instanceof FormArray) {
        errorCount += this.getTabErrors(control, formName, validFields);
        return;
      }
      const controlErrors: ValidationErrors | null | undefined = container.get(key)?.errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          let te = new TabError();
          te.tabError = this.tooltipService.getErrorText(key, keyError, controlErrors[keyError]);
          te.tabName = formName;
          this.currentTabErrors.push(te);
          errorCount++;
        });
      }
    })

    if (this.additionalGroup && this.additionalGroup.length > 0) {
      this.additionalGroup.forEach(g => {
        Object.keys(g.controls).forEach(key => {
          if (validFields?.includes(key)){
            const controlErrors: ValidationErrors | null | undefined = g.get(key)?.errors;
            if (controlErrors) {
              Object.keys(controlErrors).forEach(keyError => {
                let te = new TabError();
                te.tabError = this.tooltipService.getErrorText(key, keyError, controlErrors[keyError]);
                te.tabName = formName;
                this.currentTabErrors.push(te);
                errorCount++;
              });
            }
          }
        })
      })
    }

    return errorCount;
  }

  tabValidation(formGroup: FormGroup, formName: string, additionaError?: number, additionalErrorMsg?: string, additionalFormGroup?: FormGroup[], validFields?: string[]) {
    this.additionalGroup = additionalFormGroup;
    let validationErrors = this.setErrorCounts(formGroup, formName, additionaError, additionalErrorMsg, validFields);

    this.setTabStatus(formName, validationErrors);

    const a = this.tErrors.findIndex((e) => {
      return e.tabName == formName;
    })
    if (a !== -1) {
      this.tErrors.splice(a, 1);
    }
    const e = this.tErrors.concat(this.currentTabErrors);
    this.TabErrors.next(e);

    let ec = new TabErrorCount();
    ec.tabName = formName;
    ec.tabErrorCount = validationErrors;
    const index = this.tabErrCounts.findIndex((e) => {
      return e.tabName == formName;
    })
    if (index !== -1) {
      this.tabErrCounts.splice(index, 1);
    }
    this.tabErrCounts.push(ec);
    this.TabErrorCounts.next(this.tabErrCounts);
    if (formName == REQUEST_TAB_NAME) {
      this.requestTabFormGroup.next(formGroup);
    }
    if (formName == PROVIDER_TAB_NAME) {
      this.providerTabFormGroup.next(formGroup);
    }
  }

  setErrorCounts(formGroup: UntypedFormGroup, formName: string, additionaError?: number, additionalErrorMsg?: string, validFields?: string[]) {
    let validationErrors = this.getTabErrors(formGroup, formName, validFields);
    const i = this.currentTabErrors.findIndex((e) => {
      return e.tabError == additionalErrorMsg;
    })
    if (i !== -1) {
      this.currentTabErrors.splice(i, 1);
    }
    if (additionaError !== undefined && additionaError > 0) {
      let te = new TabError();
      te.tabError = additionalErrorMsg ?? '';
      te.tabName = formName;
      this.currentTabErrors.push(te);
      validationErrors += additionaError;
    }

    if (this.currentTabErrors.length == 0) {
      let te = new TabError();
      te.tabError = '';
      te.tabName = formName;
      this.currentTabErrors.push(te);
    }

    return validationErrors;
  }

  setTabStatus(formName: string, validationErrors: number) {
    let status = new TabStatus();
    status.tabName = formName;

    if (validationErrors > 0) {
      status.tabValid = false;
    } else {
      status.tabValid = true;
    }

    this.TabStatus.next(status);
  }
}
