import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, catchError, map, retry, take, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api/apiResponse";
import { AuthQueueData } from '../shared/components/queue/AuthQueueData';
import { AppSettings } from "../shared/appsettings";
import { TokenStorageService } from "./token-storage.service";
import { UmDashboardCounts } from "../models/dashboard/umDashboardCounts";
import { AuthIntakeDisposition } from '../models/authForm/authIntakeDisposition';
import { MemberAuthData } from "../shared/components/auth-search/MemberAuthData";
import { NotificationService } from "../shared/notification.service";
import { AlertService } from "../_alert";
import { DueDateResult } from "../models/rules/dueDateResult";
import { DatePipe } from "@angular/common";
import { AuthQueueUpdate } from "../home/team-queue-auths/team-queue-auths.component";
import { HistoryEvent } from "../models/chartHistory/historyEvent";
import { Router } from "@angular/router";
import { NewAuthAttachment } from "../models/storage/newAuthAttachment";
import { ApprovedUnitsHistory } from "../models/authView/approvedUnitsHistory";
import { ReportService } from "./report-service.service";
import { LetterTemplate } from "../models/letters/letterTemplate";
import { LookupService } from "./lookup.service";
import { LookupValue } from "../models/Lookups/lookupValue";
import { RuleEngineWorkflowService } from "./ruleEngine-workflow-service";
import { UMRuleCheckInput } from "../models/RuleBasedWorkflow/UMRuleCheckInput";
import { Queue } from "../models/configuration/queue";
import { ConfirmDialogComponent } from "../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import { BusinessRulesService } from "./business-rules.service";
import { AuthIntakeForm } from "../models/authForm/authIntakeForm";
import { AuthIntakeListItem } from "../models/authView/authIntakeListItem";
import { AuthIntakeNote } from "../models/authForm/authIntakeNote";
import { AuthIntakeRecentEvent } from "../models/authView/authIntakeRecentEvent";
import { AuthIntakeReview } from "../models/authForm/authIntakeReview";
import { AuthReviewOutcomeReasonMap } from "../models/authView/authReviewOutcomeReasonMap";
import { AuthStatusReasonMap } from "../models/authView/authStatusReasonMap";
import { DupCheckResult } from "../models/rules/dupCheckResult";
import { AuthIntakeSupervisorAuthsData } from "../models/supervisorTools/authIntakeSupervisorAuthsData";
import { TemporaryProviderAddress } from "../models/authView/TemporaryProviderAddress";
import { QueueService } from "./queue.service";
import { DATE_WITH_SECONDS, LookupSets } from "../../assets/constants";
import { DocumentService } from "@CommonLib/services/docStorage.service";
import { AuthLineItem } from "../models/authForm/authLineItem";
import { AuthFinalStatus } from "../models/authForm/authFinalStatus";
import Swal from "sweetalert2";
import { EventService } from "@CommonLib/services/events.service";

@Injectable({
  providedIn: 'root'
})
/**
 * @brief 1/25/2024 Bhupinder Changed DocumentService reference to Common project.
 */
export class FormService {
  private formServiceBaseUrl!: string;
  private _authForm!: AuthIntakeForm;
  private _authFormList!: AuthIntakeListItem[];
  private _recentEvents!: AuthIntakeRecentEvent[];
  private _authWorkQueueByGroup!: AuthQueueData[];
  private _authWorkQueueByOwner!: AuthQueueData[];

  AuthFormList = new BehaviorSubject<AuthIntakeListItem[]>([]);
  RecentEventList = new BehaviorSubject<AuthIntakeRecentEvent[]>([]);
  private authWorkQueueList = new BehaviorSubject<AuthQueueData[]>([]);
  AuthWorkQueueList = this.authWorkQueueList.asObservable();

  private authWorkQueueListByOwner = new BehaviorSubject<AuthQueueData[]>([]);
  AuthWorkQueueListByOwner = this.authWorkQueueListByOwner.asObservable();

  private authIntakeForm = new BehaviorSubject<AuthIntakeForm>(new AuthIntakeForm());
  AuthForm = this.authIntakeForm.asObservable();

  private queueUpdated = new BehaviorSubject<boolean>(false);
  QueueUpdated = this.queueUpdated.asObservable();

  private authIntakeSupervisorList = new BehaviorSubject<AuthIntakeSupervisorAuthsData>(new AuthIntakeSupervisorAuthsData);
  authIntakeSupervisorListObs = this.authIntakeSupervisorList.asObservable();

  private temporaryProviderAddress = new BehaviorSubject<TemporaryProviderAddress[]>([]);
  TemporaryProviderAddress = this.temporaryProviderAddress.asObservable();

  private tempProvAddressUpdated = new BehaviorSubject<boolean>(false);
  TempProvAddressUpdated = this.tempProvAddressUpdated.asObservable();

  private approvalHistorySubj = new BehaviorSubject<ApprovedUnitsHistory[]>([]);
  approvalHistoryObs = this.approvalHistorySubj.asObservable();

  public SearchResult = new BehaviorSubject<MemberAuthData[]>([]);

  public dueDate = new Subject<DueDateResult | undefined>();

  public DashboardCounts = new BehaviorSubject<UmDashboardCounts>(new UmDashboardCounts);

  public SelectedTab = new BehaviorSubject(0);

  public AuthStatusReasonMap = new BehaviorSubject<AuthStatusReasonMap[]>([]);

  public AuthReviewOutcomeReasonMap = new BehaviorSubject<AuthReviewOutcomeReasonMap[]>([]);

  public DiscardChanges = false;

  public ConcurrentAuth = new BehaviorSubject(false);

  public NewAuths = new BehaviorSubject<number>(0);

  public NewTeamAuths = new BehaviorSubject<number>(0);

  private originalAuthForm!: string;

  private removedDisposition = new AuthIntakeDisposition();
  private DispostionWasRemoved: boolean = false;
  private removedReview = new AuthIntakeReview();
  private ReviewWasRemoved: boolean = false;
  public AuthNumber = new BehaviorSubject<string>('');
  public PrevAuthId = '';
  public NewAuthAttachment!: NewAuthAttachment | undefined;
  public AttachmentDate!: string;
  private userConfig: any;
  private letterTemplateList!: LetterTemplate[];
  private lookupData!: LookupValue[];
  private userQueues!: Queue[];
  private rulesSave = false;
  private benefitCheckRules = false;
  private rulesMessage = '';
  public PreviosLos = new BehaviorSubject<number>(0);
  public AuthNote = new BehaviorSubject<AuthIntakeNote>(new AuthIntakeNote());
  private displayedMessage = '';
  private blankAuthForm: AuthIntakeForm = new AuthIntakeForm();
  public faxUnlocked = new BehaviorSubject(false);
  private configUser: any;
  //Variables for Auth Status
  private draftStatus = 10070;
  private draftStatusReason = 10174;
  private newStatus = 10118;
  private newStatusReason = 10166;

  //11251 Added logic in SP to propagate changes from parent to child auths
  constructor(private http: HttpClient, private config: AppSettings, private tokenService: TokenStorageService,
    private notificationService: NotificationService, private alertService: AlertService,
    private router: Router, private reportService: ReportService, private lookupService: LookupService, private rulesWorkflowService: RuleEngineWorkflowService,
    public dialog: ConfirmDialogComponent, private businessRules: BusinessRulesService, private documentService: DocumentService, private tokenStorage: TokenStorageService,
    private queueService: QueueService, private eventService: EventService) {
    this.formServiceBaseUrl = this.config.formServiceBaseUrl;
    this.user = this.tokenService.getUser();
    this.lookupService.LookupData.subscribe(l => {
      if (l && l.length > 0) {
        this.lookupData = l;
        var ds = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'draft' && l.lookupSetID == LookupSets.AUTHORIZATION_STATUS) //Auth Status Set
        var dsr = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'draft' && l.lookupSetID == LookupSets.AUTHORIZATION_STATUS_REASON) //Auth Status Reason Set
        var ns = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'new' && l.lookupSetID == LookupSets.AUTHORIZATION_STATUS) //Auth Status Set
        var nsr = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'new request' && l.lookupSetID == LookupSets.AUTHORIZATION_STATUS) //Auth Status Set
        if (ds) {
          this.draftStatus = ds.lookupValueID;
        }
        if (dsr) {
          this.draftStatusReason = dsr.lookupValueID;
        }
        if (ns) {
          this.newStatus = ns.lookupValueID;
        }
        if (nsr) {
          this.newStatusReason = nsr.lookupValueID;
        }
      }
    })
    if (config.features && config.features['benefitCheckRules']) {
      this.benefitCheckRules = JSON.parse(config.features['benefitCheckRules']);
    }
  }

  private user: any;

  resetAuthForm() {
    this.authIntakeForm.next(this.blankAuthForm);
  }
  resetAuthFormList() {
    this.AuthFormList.next([]);
  }
  changeEligibility() {
    this.authIntakeForm.next(this._authForm);
  }
  refreshAuthFormData() {
    this.authIntakeForm.next(this._authForm);
  }
  updateAuthCount(newCount: number) {
    let currentCount = this.NewTeamAuths.value;
    currentCount = currentCount + newCount;
    this.NewTeamAuths.next(currentCount);
  }

  resendLetter(authNotificationId: number) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeNotificationID", authNotificationId);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    let formResendUrl = this.formServiceBaseUrl + "Forms/ResendLetter";
    this.http.post(formResendUrl, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  getFormsList(mvdId: string): Observable<AuthIntakeListItem[] | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    this.configUser = this.tokenStorage.getUserConfig();
    let canViewSensitiveData = false;
    if (this.configUser && this.configUser.length > 1) {
      canViewSensitiveData = this.configUser.find((c: any) => c.userName == this.user.profile.preferred_username).canViewSensitiveData;
    } else if (this.configUser) {
      canViewSensitiveData = this.configUser.canViewSensitiveData;
    }
    let queryParams = new HttpParams();
    queryParams = queryParams.append("mvdId", mvdId);
    queryParams = queryParams.append("canViewSensitiveData", canViewSensitiveData);
    let formListUrl = this.formServiceBaseUrl + "Forms/GetAuthFormListAsync";
    return this.http.get<ApiResponse<AuthIntakeListItem[]>>(formListUrl, { headers: headers, params: queryParams }).pipe(map(
      data => {
        console.log("GetAuthFormListAsync");
        if (data.succeeded == true) {
          this._authFormList = data.data!;
          this.AuthFormList.next(this._authFormList);
        }
        return data.data;
      }
    ));
  }

  getAuthForm(id: string, authNumber: string, ruleEngineFormEvent: string = 'None'): Observable<AuthIntakeForm | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeId", id);
    queryParams = queryParams.append("authDetailNumber", authNumber);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    let formUrl = this.formServiceBaseUrl + "Forms/AuthIntakeAsync";
    return this.http.get<ApiResponse<AuthIntakeForm>>(formUrl, { headers: headers, params: queryParams }).pipe(map((data) => {
      console.log("AuthForm-" + id);
      if (data.succeeded == true) {
        this._authForm = data.data!;
        this.formLocked();

        if (this._authForm.authIntakeHeader.mvdId == undefined && !id.toLowerCase().startsWith("draft")) {
          this._authForm.authIntakeHeader.mvdId = id;
        }
        if (this.NewAuthAttachment) {
          let docAuthNumber = this._authForm.authIntakeDetail[0].authDetailNumber + '-' + this._authForm.authIntakeHeader.authNumber;
          let valueId = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'processed' && l.lookupSetID == 145)?.lookupValueID
          if (!valueId) {
            valueId = 10327;
          }
          if (!this.user) { this.user = this.tokenService.getUser(); }
          this.documentService.updateFaxStatus(this.NewAuthAttachment.docStorageId, valueId, -1, docAuthNumber, this.NewAuthAttachment.docDisplayName,
            this._authForm.authIntakeDetail[0].authDetailId, true, this.config.storageServiceBaseUrl, this.user.preferred_username, false);
          this.NewAuthAttachment = undefined;
        }
        this.originalAuthForm = JSON.stringify(this._authForm);
        this.authIntakeForm.next(this._authForm);
        this.AuthNumber.next(this._authForm.authIntakeHeader.authNumber);

        if (authNumber !== '0001' && authNumber !== '' && authNumber !== undefined) {
          this.ConcurrentAuth.next(true);
        } else {
          this.ConcurrentAuth.next(false);
        }

        //Only trigger RuleEngine if form is saved by Save or Submit Button click
        if ((ruleEngineFormEvent === 'SaveForm' || ruleEngineFormEvent === 'SubmitForm') && this.benefitCheckRules) {
          this.runRules(ruleEngineFormEvent);
        } else if (this.rulesMessage && this.rulesMessage !== '') {
          this.processRulesResult();
        }
        return data.data;
      }
      return data.data;
    }
    ));
  }

  getAuthFormCopy(authDetailId: string, mvdid: string): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    if (!this.user) {
      this.user = this.tokenStorage.getUser();
    }

    let queryParams = new HttpParams();
    queryParams = queryParams.append("authDetailId", authDetailId);
    queryParams = queryParams.append("mvdid", mvdid);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);

    let formUrl = this.formServiceBaseUrl + "Forms/CopyAuth";
    this.http.get<ApiResponse<AuthIntakeForm>>(formUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this._authForm = data.data!;
          this.authIntakeForm.next(this._authForm);
        }
      }
    );
  }

  async formLocked() {
    if (this._authForm.authIntakeDetail[0].lockedBy !== null && this._authForm.authIntakeDetail[0].lockedBy !== undefined
      && this._authForm.authIntakeDetail[0].lockedBy !== this.user.profile.preferred_username) {
      await Swal.fire({
        title: 'Authorization Locked',
        text: `This authorization is currently being edited by ${this._authForm.authIntakeDetail[0].lockedBy}. Please try again later.`,
        icon: 'info', //possible values - error/success/info/question
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#5890a3',
        confirmButtonText: 'OK'
      }).then((result) => {
        this.DiscardChanges = true;
        this.router.navigate(['/'], { skipLocationChange: true });
      });
    }
  }

  unlockForm(id: number) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    this.NewAuthAttachment = undefined;
    let unlockEndpoint = this.formServiceBaseUrl + "Forms/UnlockAuthForm";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("authDetailID", id);

    this.http.post(unlockEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  unlockFormByAuth(authNumber: number, authDetailNum: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    this.NewAuthAttachment = undefined;
    let unlockEndpoint = this.formServiceBaseUrl + "Forms/UnlockAuthFormByAuthNum";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("authNumber", authNumber);
    queryParams = queryParams.append("authDetailNumber", authDetailNum);

    this.http.post(unlockEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  createConcurrentAuthForm(id: string, authNumber: string): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    this.ConcurrentAuth.next(true);
    let reqType = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'fax' && l.lookupSetID == 111)
    let concReqType = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'concurrent' && l.lookupSetID == 112)
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeId", id);

    let formUrl = this.formServiceBaseUrl + "Forms/AuthIntakeConcurrentAsync";
    this.http.get<ApiResponse<AuthIntakeForm>>(formUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        console.log("ConcurrentAuthForm");
        if (data.succeeded == true && data.data) {
          if (this.AttachmentDate) {
            data.data.authIntakeHeader.createdDate = this.AttachmentDate ?? new Date().toISOString();
            data.data.authIntakeDetail[0].requestDate = this.AttachmentDate ?? new Date().toISOString();
            data.data.authIntakeDetail[0].requestOriginId = reqType?.lookupValueID ?? 10028;
            this.AttachmentDate = '';
          }
          data.data.authIntakeDetail[0].requestTypeId = concReqType?.lookupValueID ?? 10114;
          this._authForm = data.data!;
          this.authIntakeForm.next(this._authForm);
          if (this._authForm.authIntakeHeader.mvdId == undefined && !id.toLowerCase().startsWith("draft")) {
            this._authForm.authIntakeHeader.mvdId = id;
          }

          this._authForm.authIntakeDetail[0].authOwner = this.user.profile.preferred_username;

          this.originalAuthForm = JSON.stringify(this._authForm);
          this.ConcurrentAuth.next(true);
        }
      }
    );
  }

  getChartHistory(mvdId: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let sensitiveData = false;
    this.userConfig = this.tokenService.getUserConfig();
    if (this.userConfig && this.userConfig.canViewSensitiveData != undefined) {
      sensitiveData = this.userConfig.canViewSensitiveData;
    }
    let queryParams = new HttpParams();
    queryParams = queryParams.append("mvdId", mvdId);
    queryParams = queryParams.append("canViewSensitiveData", sensitiveData);
    let chartHistoryUrl = this.formServiceBaseUrl + "ChartHistory/GetChartHistory";
    return this.http.get<ApiResponse<HistoryEvent[]>>(chartHistoryUrl, { headers: headers, params: queryParams }).pipe(
      map((data) => {
        console.log("GetChartHistory");
        if (data.succeeded == true) {
          return data.data;
        }
        return null;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(error.message));
  }

  modelChanged() {
    this.reportService.letterTemplateObs.subscribe(data => {
      if (data.length > 0) {
        this.letterTemplateList = data;
      }
    });
    //Check if the current auth status is closed. Closed auths should not be modified so no need to prompt user for saving changes.
    //Caveat - this will need to be revisited as we are giving users an ability to add Disposition/Notes/Attachments on a closed auth without clicking Edit.
    if (this.businessRules.isAuthClosed(this.authIntakeForm.value)) {
      return false;
    }
    if (this.DiscardChanges || !this.originalAuthForm) {
      return false;
    }
    let tempForm: AuthIntakeForm = JSON.parse(this.originalAuthForm);

    if (tempForm.authIntakeHeader.authNumber != undefined && tempForm.authIntakeDetail[0].authDetailNumber !== null) {
      tempForm.authIntakeHeader.displayAuthNumber = tempForm.authIntakeDetail[0].authDetailNumber.toString() + '-' + tempForm.authIntakeHeader.authNumber;
    }

    this.authIntakeForm.value.authIntakeProcCodes.forEach(p => {
      if (p.shortDescription) {
        let sd = tempForm.authIntakeProcCodes.find(t => t.authIntakeProcCodeID == p.authIntakeProcCodeID);
        if (sd) {
          sd.shortDescription = p.shortDescription;
          sd.saved = p.saved;
        }
      }
    })
    //We are manually adding the provider Id for OTHER providers when a deleted provider is re-added. Since get form does not return the ProviderId for other providers (for some bizarre reason)
    //handle the comparison.
    this.authIntakeForm.value.authIntakeOtherProviders.forEach(p => {
      let sd = tempForm.authIntakeOtherProviders.find(t => t.npi == p.npi && t.providerTypeId == p.providerTypeId);
      if (p.providerId) {
        if (sd) {
          sd.providerId = p.providerId;
          sd.saved = p.saved;
        }
      }
      p.saved = sd?.saved!;
    })

    if (tempForm.authIntakeDxes !== undefined && tempForm.authIntakeDxes.length > 0) {
      tempForm.authIntakeDxes.forEach(d => {
        if (d.dxCodeId !== undefined) {
          let newDx = this.authIntakeForm.value.authIntakeDxes.find(dx => dx.dxCodeId == d.dxCodeId);
          if (newDx && newDx.saved) {
            d.saved = true;
          }
        }
      })
    }
    this.authIntakeForm.value.authIntakeReviewDetail.forEach(r => {
      let sd = tempForm.authIntakeReviewDetail.find(t => t.authReviewDetailId == r.authReviewDetailId);
      if (sd) {
        sd.readOnly = r.readOnly;
      }
    })
    var tempDisposition = new AuthIntakeDisposition();
    if (this.authIntakeForm.value.authIntakeDisposition.length > 0
      && !this.authIntakeForm.value.authIntakeDisposition[0].actualDischargeDate
      && !this.authIntakeForm.value.authIntakeDisposition[0].dispositionId
      && (!this.authIntakeForm.value.authIntakeDisposition[0].dispositionNotes || this._authForm.authIntakeDisposition[0].dispositionNotes.length < 1)
      && this.authIntakeForm.value.authIntakeDispositionDetail.length < 1
      && JSON.stringify(tempForm.authIntakeDisposition[0]) !== JSON.stringify(this.authIntakeForm.value.authIntakeDisposition[0])) {
      tempDisposition.authIntakeDispositionId = this.authIntakeForm.value.authIntakeDisposition[0].authIntakeDispositionId;
      tempDisposition.authIntakeId = this.authIntakeForm.value.authIntakeDisposition[0].authIntakeId;
      tempDisposition.actualDischargeDate = this.authIntakeForm.value.authIntakeDisposition[0].actualDischargeDate;
      tempDisposition.dispositionId = this.authIntakeForm.value.authIntakeDisposition[0].dispositionId;
      tempDisposition.dispositionNotes = this.authIntakeForm.value.authIntakeDisposition[0].dispositionNotes;
      tempDisposition.createdDate = this.authIntakeForm.value.authIntakeDisposition[0].createdDate;
      tempDisposition.createdBy = this.authIntakeForm.value.authIntakeDisposition[0].createdBy;
      tempDisposition.updatedDate = this.authIntakeForm.value.authIntakeDisposition[0].updatedDate;
      tempDisposition.updatedBy = this.authIntakeForm.value.authIntakeDisposition[0].updatedBy;
      tempDisposition.obsolete = this.authIntakeForm.value.authIntakeDisposition[0].obsolete;
      tempForm.authIntakeDisposition.push(tempDisposition);
    }

    var tempReview = new AuthIntakeReview();
    if (this.authIntakeForm.value.authIntakeReview.length > 0 && (this.authIntakeForm.value.authIntakeReview.filter(r => !r.reviewTypeId || r.reviewTypeId <= 0).length > 0)) {
      let dummyReview = this.authIntakeForm.value.authIntakeReview.filter(r => !r.reviewTypeId || r.reviewTypeId <= 0)[0];
      tempReview.authDetailId = dummyReview.authDetailId;
      tempReview.authReviewId = dummyReview.authReviewId;
      tempReview.createdBy = dummyReview.createdBy;
      tempReview.createdDate = dummyReview.createdDate;
      tempReview.obsolete = dummyReview.obsolete;
      tempReview.outcomeReason = dummyReview.outcomeReason;
      tempReview.rationale = dummyReview.rationale;
      tempReview.reviewOutcome = dummyReview.reviewOutcome;
      tempReview.reviewType = dummyReview.reviewType;
      tempReview.reviewTypeId = dummyReview.reviewTypeId;
      tempReview.updatedBy = dummyReview.updatedBy;
      tempReview.updatedDate = dummyReview.updatedDate;

      tempForm.authIntakeReview.push(tempReview)
    }
    var notes!: AuthIntakeNote[];
    var updatedNotes: AuthIntakeNote[] = JSON.parse(JSON.stringify(this.authIntakeForm.value.authIntakeNotes));
    if (tempForm.authIntakeNotes.length > 0) {
      notes = tempForm.authIntakeNotes.filter(n => n.obsolete == false);
      notes.forEach(tempNote => {
        var newNote = updatedNotes.find(n => n.authNoteId == tempNote.authNoteId);
        if (newNote) {
          tempNote.hidden = newNote.hidden;
          tempNote.isEdited = newNote.isEdited;
          tempNote.isEditable = newNote.isEditable;
          tempNote.isDeletable = newNote.isDeletable;
          tempNote.isReadable = newNote.isReadable;
        }
      })
    }

    tempForm = this.sortAuthForm(tempForm);
    var tempForm2 = this.sortAuthForm(this.authIntakeForm.value);

    //var result2 = this.compareObjects(tempForm, tempForm2);
    //tempForm = Original Form
    //tempForm2 = Current version
    if (JSON.stringify(tempForm) != JSON.stringify(tempForm2)) {
      return true;
    }
    return false;
  }

  getLetterName(templateId: number) {
    if (this.letterTemplateList) {
      let letterName = this.letterTemplateList!.find(l => l.letterTemplateId == templateId)?.letterName!;
      if (letterName) {
        return letterName;
      }
      return null;
    }
    return '';
  }

  sortAuthForm(authForm: AuthIntakeForm) {
    if (authForm.authIntakeAttachments && authForm.authIntakeAttachments.length > 0) {
      authForm.authIntakeAttachments = authForm.authIntakeAttachments.sort((a, b) => a.authIntakeAttachmentId - b.authIntakeAttachmentId);
    }
    if (authForm.authIntakeDisposition && authForm.authIntakeDisposition.length > 0) {
      authForm.authIntakeDisposition = authForm.authIntakeDisposition.sort((a, b) => a.authIntakeDispositionId - b.authIntakeDispositionId);
    }
    if (authForm.authIntakeDispositionDetail && authForm.authIntakeDispositionDetail.length > 0) {
      authForm.authIntakeDispositionDetail = authForm.authIntakeDispositionDetail.sort((a, b) => a.authIntakeDispositionDetailId - b.authIntakeDispositionDetailId);
    }
    if (authForm.authIntakeDxes && authForm.authIntakeDxes.length > 0) {
      authForm.authIntakeDxes = authForm.authIntakeDxes.sort((a, b) => a.dxCodeId < b.dxCodeId ? -1 : 1);
    }
    if (authForm.authIntakeNotes && authForm.authIntakeNotes.length > 0) {
      authForm.authIntakeNotes = authForm.authIntakeNotes.sort((a, b) => a.authNoteId - b.authNoteId);
    }
    if (authForm.authIntakeNotificationDetail && authForm.authIntakeNotificationDetail.length > 0) {
      authForm.authIntakeNotificationDetail = authForm.authIntakeNotificationDetail.sort((a, b) => a.authIntakeNotificationDetailId - b.authIntakeNotificationDetailId);
    }
    if (authForm.authIntakeNotifications && authForm.authIntakeNotifications.length > 0) {
      authForm.authIntakeNotifications = authForm.authIntakeNotifications.sort((a, b) => a.authIntakeNotificationId - b.authIntakeNotificationId);
    }
    if (authForm.authIntakeOtherProviders && authForm.authIntakeOtherProviders.length > 0) {
      authForm.authIntakeOtherProviders = authForm.authIntakeOtherProviders.sort((a, b) => a.npi < b.npi ? -1 : 1);
    }
    if (authForm.authIntakeProcCodes && authForm.authIntakeProcCodes.length > 0) {
      authForm.authIntakeProcCodes = authForm.authIntakeProcCodes.sort((a, b) => a.authIntakeProcCodeID - b.authIntakeProcCodeID);
    }
    if (authForm.authIntakeReview && authForm.authIntakeReview.length > 0) {
      authForm.authIntakeReview = authForm.authIntakeReview.sort((a, b) => a.authReviewId - b.authReviewId);
    }
    if (authForm.authIntakeReviewDetail && authForm.authIntakeReviewDetail.length > 0) {
      authForm.authIntakeReviewDetail = authForm.authIntakeReviewDetail.sort((a, b) => a.authReviewDetailId - b.authReviewDetailId);
    }
    if (authForm.authIntakeRfi && authForm.authIntakeRfi.length > 0) {
      authForm.authIntakeRfi = authForm.authIntakeRfi.sort((a, b) => a.authRfiid - b.authRfiid);
    }
    if (authForm.authIntakeStatus && authForm.authIntakeStatus.length > 0) {
      authForm.authIntakeStatus = authForm.authIntakeStatus.sort((a, b) => a.authIntakeStatusId - b.authIntakeStatusId);
    }
    if (authForm.authIntakeUserDefinedValues && authForm.authIntakeUserDefinedValues.length > 0) {
      authForm.authIntakeUserDefinedValues = authForm.authIntakeUserDefinedValues.sort((a, b) => a.authUdfID - b.authUdfID);
    }
    return authForm;
  }
  //Method not used currently but keeping it in place as it is useful functionality.
  compareObjects = (a: any, b: any) => {
    if (a === b) return true;
    if (a ?? 'undefined' == b ?? 'undefined') return true;
    if (typeof a != 'object' || typeof b != 'object' || a == null || b == null)
      return false;

    let keysA = Object.keys(a), keysB = Object.keys(b);

    if (keysA.length != keysB.length)
      return false;

    for (let key of keysA) {
      if (!keysB.includes(key))
        return false;

      if (typeof a[key] === 'function' || typeof b[key] === 'function') {
        if (a[key].toString() != b[key].toString())
          return false;
      } else {
        if (!this.compareObjects(a[key], b[key]))
          return false;
      }
    }

    return true;
  }

  getNewAuthForm(id: string): void {
    this.ConcurrentAuth.next(false);
    let form = new AuthIntakeForm();
    let d: number = Date.now();
    form.authIntakeHeader.mvdId = id;
    form.draftKey = 'Draft' + id + d;
    if (this.AttachmentDate || this.NewAuthAttachment) {
      let reqType = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'fax' && l.lookupSetID == 111)

      form.authIntakeHeader.createdDate = this.AttachmentDate ?? new Date().toISOString();
      form.authIntakeDetail[0].requestDate = this.AttachmentDate ?? new Date().toISOString();
      form.authIntakeDetail[0].requestOriginId = reqType?.lookupValueID ?? 10028;
      this.AttachmentDate = '';
    }
    this.authIntakeForm.next(form);
    this._authForm = form;
  }

  searchMemberAndAuth(firstName: string, lastName: string, dob: string, includeTemp: boolean, memberId: string, authNumber: string): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("firstName", firstName);
    queryParams = queryParams.append("lastName", lastName);
    queryParams = queryParams.append("dob", dob);
    queryParams = queryParams.append("includeTemp", includeTemp);
    queryParams = queryParams.append("memberId", memberId);
    queryParams = queryParams.append("authNumber", authNumber);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    let formUrl = this.formServiceBaseUrl + "Forms/SearchMemberAndAuths";
    this.http.get<ApiResponse<MemberAuthData[]>>(formUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        console.log("SearchMemberAndAuths");
        if (data.succeeded == true) {
          this.SearchResult.next(data.data!);
        }
      }
    );
  }

  saveAuthForm(ruleEngineFormEvent: string = 'None'): void {
    //Check the form to make sure we have a valid auth loaded to save. ResetAuthForm method sets the form to a blank form to handle ghosting.
    if (!this._authForm || !this._authForm.authIntakeHeader || !this._authForm.authIntakeHeader.mvdId) {
      return;
    }

    this.removedDisposition = new AuthIntakeDisposition();
    this.DispostionWasRemoved = false;
    this.removedReview = new AuthIntakeReview();
    this.ReviewWasRemoved = false;
    this.setAuthStatus();

    this._authForm.userName = this.user.profile.preferred_username;
    this._authForm.saveDate = new Date().toISOString();

    if (this._authForm.authIntakeDetail[0].authOwner == undefined &&
      this._authForm.authIntakeDetail[0].authGroupId == undefined) {
      this._authForm.authIntakeDetail[0].authOwner = this.user.profile.preferred_username;
    }

    // this._authForm.authIntakeRfi.forEach(r => {
    //   if (r.authRfirecdDate && !this.isIsoDate(r.authRfirecdDate)) {
    //     r.authRfirecdDate = new Date(r.authRfirecdDate).toISOString();
    //   }
    // })

    let formVal!: AuthIntakeForm;
    this.AuthForm.subscribe(val => formVal = val);


    if (!this._authForm.authIntakeDetail[0].expectedDispositionId) {
      this._authForm.authIntakeDetail[0].expectedDispositionId = null;
    }
    this._authForm.authIntakeDetail[0].requestDate = new Date(this._authForm.authIntakeDetail[0].requestDate).toISOString();
    //We are creating a loophole to allow Overall status & reason to be updated manually ONLY if the auth request type is Exception
    const exception = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'initial' && l.lookupStandardLabel.toLowerCase() == 'exception');
    if (this.businessRules.isAuthClosed(this._authForm)) {
      //Exception type does not exist or the Auth request type is not exception. Determine overall status.
      if (!exception || (exception && this._authForm.authIntakeDetail[0].requestTypeId !== exception.lookupValueID)){
        this.getFinalAuthStatus().subscribe(status => {
          if (status) {
            this._authForm.authIntakeDetail[0].currentStatus = status.authStatusId;
            this._authForm.authIntakeDetail[0].currentStatusReasonId = status.authStatusReasonId;
            this.saveForm(formVal, ruleEngineFormEvent);
          }
        })
      } else {
        this.saveForm(formVal, ruleEngineFormEvent);  
      }
    } else {
      this.saveForm(formVal, ruleEngineFormEvent);
    }
    // kludge to handle saving of authIntakeDisposition with no values
    if (this.DispostionWasRemoved) {
      this._authForm.authIntakeDisposition.unshift(this.removedDisposition);
    }
    if (this.ReviewWasRemoved) {
      this._authForm.authIntakeReview.unshift(this.removedReview);
    }
  }

  private saveForm(formVal: AuthIntakeForm, ruleEngineFormEvent: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let saveUrl = this.formServiceBaseUrl + "Forms/SaveAuthIntakeAsync";
    let authIntakeId = '';
    this.cleanupAuthForm();

    if (formVal.authIntakeHeader.mvdId !== undefined) {
      this.http.post<ApiResponse<string>>(saveUrl, JSON.stringify(this._authForm), options).subscribe(
        data => {
          if (data.succeeded == true) {
            if (data.data != undefined) {
              authIntakeId = data.data;
              //Duplicate auth check. SaveAuthForm procedure will return 0000-0000000-1 = AuthDetailNumber-AuthIntakeId-IsDuplicate
              //Check the last value to see if this is a duplicate auth
              if (authIntakeId.split('-').length > 2 && authIntakeId.split('-')[2] == '1'){
                this.openDialog(+authIntakeId.split('-')[1], authIntakeId.split('-')[0], authIntakeId).then(decision => {
                  if (decision == 'override'){
                    this._authForm.overrideDuplicate = true;
                    this.saveForm(formVal, ruleEngineFormEvent);
                  }
                });
              }
              else if (authIntakeId !== '') {
                this.notificationService.success("Information Saved! Retrieving saved Authorization.");
                this.getAuthForm(authIntakeId.split('-')[1], authIntakeId.split('-')[0], ruleEngineFormEvent).subscribe();
              }
            }
          } else {
            this.alertService.error("Save Error: " + data.message);
          }
        }
      );
    }

    if (formVal.authIntakeEditHistory !== null && formVal.authIntakeEditHistory !== undefined) {
      let saveEditUrl = this.formServiceBaseUrl + "Forms/SaveEditReason";
      formVal.authIntakeEditHistory.createdBy = this.user.profile.preferred_username;

      this.http.post<ApiResponse<number>>(saveEditUrl, JSON.stringify(formVal.authIntakeEditHistory), options).subscribe(
        data => {
          if (data.succeeded == true) {
            if (data.data !== undefined && this._authForm.authIntakeEditHistory !== null) {
              this._authForm.authIntakeEditHistory.authEditId = data.data;
            }
          } else {
            this.alertService.error("Save Edit History Error: " + data.message);
          }
        }
      );
    }
  }

  async openDialog(authIntakeId: number, authNumber: string, authId: string ) {
    this.DiscardChanges = false;
    var decision = '';
    await Swal.fire({
      title: 'DUPLICATE: Duplicate Authorization',
      html: `Duplicate Authorization data.</br> Select approriate action below to continue.`,
      icon: 'info', //possible values - error/success/info/question
      allowOutsideClick: false,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: 'Open Existing Auth',
      cancelButtonText: 'Modify Current Auth',
      denyButtonText: 'Create Duplicate'
    }).then((result) => {
      if (result.isConfirmed){
        this.deleteDraft([this._authForm.draftKey]);
        this.getAuthForm(authIntakeId.toString(), authNumber,'None').subscribe();
        decision = 'discard';
      } else if (result.isDenied){
        decision = 'override';
        console.log('Override authorization');
      } else {
        //this.ruleResult.next(true);
        decision = 'cancel';
        this.eventService.SaveButtonState(true);
      }
    });
    return decision;
  }

  runRules(ruleEngineFormEvent: string) {
    this.userConfig = this.tokenService.getUserConfig();
    let umRuleCheckInput = new UMRuleCheckInput();
    umRuleCheckInput.authIntakeId = this._authForm.authIntakeHeader.authIntakeId;
    umRuleCheckInput.authDetailId = this._authForm.authIntakeDetail[0].authDetailId;
    umRuleCheckInput.authorizationNumber = this._authForm.authIntakeHeader.displayAuthNumber;
    umRuleCheckInput.memberId = this._authForm.authIntakeHeader.memberId;
    umRuleCheckInput.mvdid = this._authForm.authIntakeHeader.mvdId;
    umRuleCheckInput.userName = this.user.profile.preferred_username;
    umRuleCheckInput.userRole = this.userConfig.role;
    umRuleCheckInput.formEvent = ruleEngineFormEvent;
    this.rulesWorkflowService.runBenefitsCheck(umRuleCheckInput).subscribe(result => {
      let sb: string = '';
      if (result) {
        sb = '';
        this.rulesMessage = '';
        //Check to make sure rules ran and have a display message
        if (result.data && result.data.length > 0) {
          result.data.forEach(rt => {
            rt.realTimeAction.messages.forEach(m => {
              sb += m.codeType === 'LOS' ? 'InPatient LOS' + ' - ' + m.messageText + '\n' : m.code + ' - ' + m.messageText + '\n';
            })
          })
          this.rulesMessage = sb.toString();
        }
      }
      this.getAuthForm(this._authForm.authIntakeHeader.authIntakeId.toString(), this._authForm.authIntakeDetail[0].authDetailNumber, 'None').subscribe();
    })
  }

  processRulesResult() {
    if (this.rulesMessage && this.rulesMessage != this.displayedMessage) {
      this.displayedMessage = this.rulesMessage;
      //Display modal with rules message
      const dialogRef = this.dialog.open1Btn({
        width: '35em',
        title: 'Benefit Check',
        message: this.rulesMessage,
        acceptButtonText: 'Acknowledge',
        declineButtonText: '',
        displayClose: false
      });
      this.dialog.buttonAction.subscribe((result: any) => {
        if (result == true && this.rulesMessage == this.displayedMessage && this.rulesMessage.trim().length > 0) {
          var benefitCheck = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'benefitscheck' && l.lookupSetID == 117)?.lookupValueID;
          //Create and Save a note with rules message once user clicks Acknowledge
          let note = new AuthIntakeNote();
          note.authDetailId = this._authForm.authIntakeDetail[0].authDetailId;
          note.createdBy = 'UMRulesEngine';
          note.createdDate = new Date().toISOString();
          note.noteText = this.rulesMessage;
          note.noteTypeId = benefitCheck ?? 10343;
          note.updatedBy = 'UMRulesEngine';
          note.updatedDate = new Date().toISOString();
          this._authForm.authIntakeNotes.push(note);
          this.rulesSave = true;
          this.saveAuthForm();
          this.displayedMessage = '';
          this.rulesMessage = '';
        }
        dialogRef.close();
      });
    }
  }

  setAuthStatus() {
    if (this._authForm.authIntakeDetail[0]?.currentStatus <= 0 || this._authForm.authIntakeDetail[0].currentStatus == undefined) {
      this._authForm.authIntakeDetail[0].currentStatus = this.draftStatus; // Default the status to 'Draft';
    }
    if (this._authForm.authIntakeDetail[0].currentStatusReasonId <= 0 || this._authForm.authIntakeDetail[0].currentStatusReasonId == undefined) {
      if (this._authForm.authIntakeDetail[0].currentStatus == this.draftStatus) { //Draft
        this._authForm.authIntakeDetail[0].currentStatusReasonId = this.draftStatusReason; // Default the status reason to 'Draft';
      } else if (this._authForm.authIntakeDetail[0].currentStatus == this.newStatus) { //New
        this._authForm.authIntakeDetail[0].currentStatusReasonId = this.newStatusReason; // Default the status reason to 'New Request';
      }
    }
  }

  cleanupAuthForm() {
    // kludge to handle saving of authIntakeDisposition with no values
    this.removedDisposition = new AuthIntakeDisposition();
    this.DispostionWasRemoved = false;
    if (this._authForm.authIntakeDisposition.length > 0
      && !this._authForm.authIntakeDisposition[0].actualDischargeDate
      && !this._authForm.authIntakeDisposition[0].dispositionId
      && (!this._authForm.authIntakeDisposition[0].dispositionNotes || this._authForm.authIntakeDisposition[0].dispositionNotes.length < 1) && this._authForm.authIntakeDispositionDetail.length < 1) {
      this.removedDisposition.authIntakeDispositionId = this._authForm.authIntakeDisposition[0].authIntakeDispositionId;
      this.removedDisposition.authIntakeId = this._authForm.authIntakeDisposition[0].authIntakeId;
      this.removedDisposition.actualDischargeDate = this._authForm.authIntakeDisposition[0].actualDischargeDate;
      this.removedDisposition.dispositionId = this._authForm.authIntakeDisposition[0].dispositionId;
      this.removedDisposition.dispositionNotes = this._authForm.authIntakeDisposition[0].dispositionNotes;
      this.removedDisposition.createdDate = this._authForm.authIntakeDisposition[0].createdDate;
      this.removedDisposition.createdBy = this._authForm.authIntakeDisposition[0].createdBy;
      this.removedDisposition.updatedDate = this._authForm.authIntakeDisposition[0].updatedDate;
      this.removedDisposition.updatedBy = this._authForm.authIntakeDisposition[0].updatedBy;
      this.removedDisposition.obsolete = this._authForm.authIntakeDisposition[0].obsolete;

      this._authForm.authIntakeDisposition.shift();
      this.DispostionWasRemoved = true;
    }
    //Temporary solution for blank review
    this.removedReview = new AuthIntakeReview();
    this.ReviewWasRemoved = false;
    if (this._authForm.authIntakeReview.length > 0 && (this._authForm.authIntakeReview[0].reviewTypeId <= 0 || this._authForm.authIntakeReview[0].reviewTypeId == undefined)) {
      this.removedReview.authDetailId = this._authForm.authIntakeReview[0].authDetailId;
      this.removedReview.authReviewId = this._authForm.authIntakeReview[0].authReviewId;
      this.removedReview.createdBy = this._authForm.authIntakeReview[0].createdBy;
      this.removedReview.createdDate = this._authForm.authIntakeReview[0].createdDate;
      this.removedReview.obsolete = this._authForm.authIntakeReview[0].obsolete;
      this.removedReview.outcomeReason = this._authForm.authIntakeReview[0].outcomeReason;
      this.removedReview.rationale = this._authForm.authIntakeReview[0].rationale;
      this.removedReview.reviewOutcome = this._authForm.authIntakeReview[0].reviewOutcome;
      this.removedReview.reviewType = this._authForm.authIntakeReview[0].reviewType;
      this.removedReview.reviewTypeId = this._authForm.authIntakeReview[0].reviewTypeId;
      this.removedReview.updatedBy = this._authForm.authIntakeReview[0].updatedBy;
      this.removedReview.updatedDate = this._authForm.authIntakeReview[0].updatedDate;

      this._authForm.authIntakeReview.shift();
      this.ReviewWasRemoved = true;
    }

    if (this._authForm.authIntakeUserDefinedValues && this._authForm.authIntakeUserDefinedValues.length > 0){
      this._authForm.authIntakeUserDefinedValues = this._authForm.authIntakeUserDefinedValues.filter(udf => udf.udfValue);
    }
    //#20067 - Null actualDischargeDate causing errors in SaveAuthIntake method.
    if (this._authForm.authIntakeDisposition && this._authForm.authIntakeDisposition.length > 0){
      this._authForm.authIntakeDisposition = this._authForm.authIntakeDisposition.filter(d => d.actualDischargeDate);
    }
  }

  getDueDateHours(query: string): Observable<DueDateResult | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let ruleEndpoint = this.formServiceBaseUrl + "Forms/GetAuthFormDueDate";

    return this.http.post<ApiResponse<DueDateResult>>(ruleEndpoint, JSON.stringify(query), options).pipe(
      map((data) => {
        if (data.succeeded == true) {
          this.dueDate.next(data.data);
          return data.data;
        } else {
          console.log("Due Date Error:", data.message);
          return undefined;
        }
      }
      ));
  }

  getUmDashboardCounts(): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let pipe = new DatePipe('en-US');
    let today = new Date();
    let newDate = pipe.transform(today, DATE_WITH_SECONDS);
    let queryParams = new HttpParams();
    if (!this.user.profile) {
      this.user = this.tokenService.getUser();
    }
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    if (newDate !== null) {
      queryParams = queryParams.append("runDate", newDate);
    }
    let formDashboardUrl = this.formServiceBaseUrl + "Dashboard/UMDashboard";
    console.log('Dashboard user = ' + this.user.profile.preferred_username);
    this.http.get<ApiResponse<UmDashboardCounts>>(formDashboardUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        console.log("DashboardCounts");
        if (data.succeeded == true) {
          this.DashboardCounts.next(data.data!);
        }
      }
    );
  }

  getAuthIntakeWorkQueueByGroup(groupIds: number[]): Observable<ApiResponse<AuthQueueData[]> | void> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    const groupIdsStr = groupIds.join(',');
    let sensitiveData = false;
    this.userConfig = this.tokenService.getUserConfig();
    if (this.userConfig && this.userConfig.canViewSensitiveData != undefined) {
      sensitiveData = this.userConfig.canViewSensitiveData;
    }
    let queryParams = new HttpParams();
    queryParams = queryParams.append("GroupIDs", groupIdsStr);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("canViewSensitiveData", sensitiveData);

    let formAuthIntakeWorkQueueByGroupUrl = this.formServiceBaseUrl + "Dashboard/GetAuthIntakeWorkQueueByGroup";
    return this.http.get<ApiResponse<AuthQueueData[]>>(formAuthIntakeWorkQueueByGroupUrl, { headers: headers, params: queryParams }).pipe(
      map((data) => {
        console.log("AuthIntakeWorkQueueByGroup");
        if (data.succeeded == true) {
          this._authWorkQueueByGroup = this.populateAuthList(data.data!);
          this.authWorkQueueList.next(this._authWorkQueueByGroup);
          var newCount = 0;
          const distinctThings = data.data?.filter(
            (thing, i, arr) => arr.findIndex(t => t.authDetailId === thing.authDetailId && t.showAsNew) === i
          );
          newCount = distinctThings?.length ?? 0;
          this.NewTeamAuths.next(newCount);
        }
      }
      ));
  }

  populateAuthList(data: AuthQueueData[]) {
    let authList: AuthQueueData[] = data;
    authList.forEach(element => {
      element.dueDateCompare = new Date(element.dueDate).toISOString();
      element.checked = false;
    });
    authList = [...authList];

    //Store the proc code desc for tooltip
    let procCodeDescList: AuthQueueData[];
    procCodeDescList = JSON.parse(JSON.stringify(authList.filter(
      (auth, i, arr) => arr.findIndex(t => t.procCodeId === auth.procCodeId) === i
    ))) as typeof authList;

    //Combine multiple proc codes into one column.
    let procCodeIdList: any[] = [];
    procCodeIdList = [...Array.from(new Set(authList.map(d => d.authDetailId)))].map(authDetailId => {
      return {
        authDetailId, //#10487-Added the second filter after the map below to make sure we get a distinct list. This was duplicating the proc codes everytime the tab was selected.
        procCodeId: data.filter(d => d.authDetailId == authDetailId).map(d => d.procCodeId).filter((value, index, self) => {
          return self.indexOf(value) == index
        })
      }
    })

    //Assign combined proc codes to the owner list
    authList.forEach(a => {
      a.procCodeId = procCodeIdList.filter((n: AuthQueueData) => n.authDetailId == a.authDetailId)?.[0].procCodeId.toString();
    })
    //Eliminate duplicates
    authList = authList.reduce((accumalator: AuthQueueData[], current: AuthQueueData) => {
      if (
        !accumalator.some(
          (item) => item.authDetailId === current.authDetailId
        )
      ) {
        accumalator.push(current);
      }
      return accumalator;
    }, []);

    authList.sort((a, b) => this.queueService.sortAuths(a, b));
    return authList;
  }

  getPagedAuthWorkQueueByGroup(skipRows: number, takeRows: number) {
    if (this.authWorkQueueList.value) {
      let queueList = this.authWorkQueueList.value;
      let totalRows = skipRows + takeRows;
      if (skipRows > queueList.length) {
        return null;
      }
      if (totalRows > queueList.length) {
        takeRows = queueList.length - skipRows;
      }
      return queueList.slice(skipRows, takeRows + skipRows);
    } else {
      return null;
    }
  }

  getAuthIntakeWorkQueueByOwner(userName: string): Observable<ApiResponse<AuthQueueData[]> | void> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let sensitiveData = false;
    this.userConfig = this.tokenService.getUserConfig();
    if (this.userConfig && this.userConfig.canViewSensitiveData != undefined) {
      sensitiveData = this.userConfig.canViewSensitiveData;
    }

    let queryParams = new HttpParams();
    queryParams = queryParams.append("Owner", userName);
    queryParams = queryParams.append("canViewSensitiveData", sensitiveData);
    let formgetAuthIntakeWorkQueueByOwnerUrl = this.formServiceBaseUrl + "Dashboard/GetAuthIntakeWorkQueueByOwner";
    return this.http.get<ApiResponse<AuthQueueData[]>>(formgetAuthIntakeWorkQueueByOwnerUrl, { headers: headers, params: queryParams }).pipe(
      map((data) => {
        console.log("GetAuthIntakeWorkQueueByOwner");
        if (data.succeeded == true) {
          this._authWorkQueueByOwner = this.populateAuthList(data.data!);
          this.authWorkQueueListByOwner.next(this._authWorkQueueByOwner);
          var newCount = 0;
          const distinctThings = data.data?.filter(
            (thing, i, arr) => arr.findIndex(t => t.authDetailId === thing.authDetailId && t.showAsNew) === i
          );
          newCount = distinctThings?.length ?? 0;
          this.NewAuths.next(newCount);
        }
      }
      ));
  }

  async updateAuthQueues(params: AuthQueueUpdate) {
    let saveUrl = this.formServiceBaseUrl + "Forms/UpdateAuthQueues";
    this.http.put<ApiResponse<number>>(saveUrl, params).subscribe(
      data => {
        if (data.succeeded == true) {
          this.notificationService.success("Task updated successfully!");
          this.queueUpdated.next(true);
        }
        else {
          this.notificationService.error("Error updating task!");
          this.queueUpdated.next(false);
        }
      }
    );
  }

  async updateAuthsOwner(params: AuthQueueUpdate) {
    let saveUrl = this.formServiceBaseUrl + "Forms/UpdateAuthsOwner";
    this.http.put<ApiResponse<number>>(saveUrl, params).subscribe(
      data => {
        if (data.succeeded == true) {
          this.notificationService.success("Authorizations updated successfully!");
          this.queueUpdated.next(true);
        }
        else {
          this.notificationService.error("Error updating task!");
          this.queueUpdated.next(false);
        }
      }
    );
  }

  updateAuthStatus(authIntakeId: number, authNumber: string, authStatus: number, authStatusReasonId: number): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let updateStatusEndpoint = this.formServiceBaseUrl + "Forms/UpdateAuthStatus";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeId", authIntakeId);
    queryParams = queryParams.append("authNumber", authNumber);
    queryParams = queryParams.append("authStatus", authStatus);
    queryParams = queryParams.append("authStatusReasonId", authStatusReasonId);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);

    this.http.post(updateStatusEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  // setAuthFormUserAndDates(authForm: AuthIntakeForm) {
  //   if (authForm.authIntakeHeader.createdBy == undefined) {
  //     authForm.authIntakeHeader.createdBy = this.user.profile.preferred_username;
  //     authForm.authIntakeHeader.createdDate = new Date().toISOString();
  //     authForm.authIntakeHeader.updatedBy = this.user.profile.preferred_username;
  //     authForm.authIntakeHeader.updatedDate = new Date().toISOString();
  //   }
  // }

  autoSaveAuthForm(): void {
    let saveUrl = this.formServiceBaseUrl + "Forms/AutoSaveAuthIntakeAsync";
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };

    let formVal!: AuthIntakeForm;
    this.AuthForm.subscribe(val => formVal = val);

    if (formVal.authIntakeHeader.mvdId !== undefined && (formVal.authIntakeHeader.authIntakeId == 0 || formVal.authIntakeHeader.authIntakeId == undefined)) {
      if (formVal.authIntakeDetail[0].currentStatus <= 0 || formVal.authIntakeDetail[0].currentStatus == undefined) {
        formVal.authIntakeDetail[0].currentStatus = 10070; // Default the status to 'Draft';
      }
      if (formVal.authIntakeDetail[0].authOwner == undefined &&
        formVal.authIntakeDetail[0].authGroupId == undefined) {
        formVal.authIntakeDetail[0].authOwner = this.user.profile.preferred_username;
      }
      this.http.post<AuthIntakeForm>(saveUrl, JSON.stringify(formVal), options).subscribe(
        data => console.log("DraftKey:", JSON.stringify(data))
      );
    }
  }

  tabChanged(index: number) {
    this.SelectedTab.next(index);
  }

  getStatusReasonMap(): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let formListUrl = this.formServiceBaseUrl + "Forms/GetAuthFormStatusReasonMap";
    this.http.get<ApiResponse<AuthStatusReasonMap[]>>(formListUrl, options).subscribe(
      data => {
        console.log("AuthStatusReasonMap");
        if (data.succeeded == true) {
          this.AuthStatusReasonMap.next(data.data!);
        }
      }
    );
  }

  getReviewOutcomeReasonMap(): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let formListUrl = this.formServiceBaseUrl + "Forms/GetAuthReviewOutcomeReasonMap";
    this.http.get<ApiResponse<AuthReviewOutcomeReasonMap[]>>(formListUrl, options).subscribe(
      data => {
        if (data.succeeded == true) {
          this.AuthReviewOutcomeReasonMap.next(data.data!);
        }
      }
    );
  }
  //#20715 - No longer need an explicit duplicate check. The logic for this is now part of the SaveAuthIntakeForm procedure.
  // duplicateCheck(): Observable<DupCheckResult> {
  //   let headers = new HttpHeaders({ 'x-api-version': '1.0' });
  //   let options = { headers: headers };
  //   let dupEndpoint = this.formServiceBaseUrl + "Forms/DuplicateAuthCheck";

  //   return this.http.post<ApiResponse<DupCheckResult>>(dupEndpoint, JSON.stringify(this._authForm), options).pipe(
  //     map((response: ApiResponse<DupCheckResult>)=>{
  //       if (response.succeeded){
  //         return response.data!;
  //       }
  //       return response.data!;
  //     })
  //   );
  // }

  deleteDraft(draftId: string[]) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let deleteEndpoint = this.formServiceBaseUrl + "Forms/DeleteDraft";

    this.http.post(deleteEndpoint, JSON.stringify(draftId), options).subscribe(r => { });
  }

  deleteAuth(authIntakeId: number, authDetailNumber: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let deleteEndpoint = this.formServiceBaseUrl + "Forms/DeleteAuth";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeId", authIntakeId.toString());
    queryParams = queryParams.append("authDetailNumber", authDetailNumber);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    //let data = JSON.stringify({ authIntakeId: authIntakeId, authDetailNumber: authDetailNumber, userName: this.user.profile.preferred_username })
    this.http.post(deleteEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  public getAuthNumber() {
    if (this._authForm == undefined) {
      return '0';
    } else {
      return this._authForm.authIntakeHeader.authNumber;
    }
  }

  getIntakeSupervisorAuthsFull(userList: string, queueList: string, skipRows: number = 0, takeRows: number = 1000, filter = ''): Observable<ApiResponse<AuthIntakeSupervisorAuthsData>> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let queryParams = new HttpParams();
    queryParams = queryParams.append("userNameList", userList);
    queryParams = queryParams.append("queueList", queueList);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("skipRows", 0);
    queryParams = queryParams.append("takeRows", -1);
    queryParams = queryParams.append("filter", filter);
    // return this.http.post<BenefitCheckRuleResult>(runTempWorkflowUrl, JSON.stringify(umRuleCheckInput), options)
    let httpurl = this.config.formServiceBaseUrl + "Forms/AuthIntakeAsyncSupervisorAuths";
    return this.http.get<ApiResponse<AuthIntakeSupervisorAuthsData>>(httpurl, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<AuthIntakeSupervisorAuthsData>) => {
        return response;
      }));
  }

  getAuthIntakeSupervisorAuths(userList: string, queueList: string, skipRows: number = 0, takeRows: number = 1000, filter = ''): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    if (filter === '{}' || filter === 'null') filter = '';

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userNameList", userList);
    queryParams = queryParams.append("queueList", queueList);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("skipRows", skipRows);
    queryParams = queryParams.append("takeRows", takeRows);
    queryParams = queryParams.append("filter", filter);
    let url = this.formServiceBaseUrl + "Forms/AuthIntakeAsyncSupervisorAuths";
    this.http.get<ApiResponse<AuthIntakeSupervisorAuthsData>>(url, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this.authIntakeSupervisorList.next(data.data!);
        }
      }
    );
  }

  getApprovedUnitsHistory(authNumber: string): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("authNumber", authNumber);
    let url = this.formServiceBaseUrl + "Forms/GetApprovedUnitsHistory";
    this.http.get<ApiResponse<ApprovedUnitsHistory[]>>(url, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this.approvalHistorySubj.next(data.data!);
        }
      }
    );
  }

  attachFax(authNumber: string, authDetailNumber: string, docStorageId: string, docName: string, attachType: number, attachUrl: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let attachEndpoint = this.formServiceBaseUrl + "Fax/AttachFax";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("authNumber", authNumber);
    queryParams = queryParams.append("authDetailNumber", authDetailNumber);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("docStorageId", docStorageId);
    queryParams = queryParams.append("docName", docName);
    queryParams = queryParams.append("attachType", attachType);
    queryParams = queryParams.append("attachUrl", attachUrl);

    this.http.put(attachEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  lockFax(internalDocumentId: string): Observable<any | undefined> {
    if (internalDocumentId == undefined) return new Observable<undefined>;

    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let endpoint = this.formServiceBaseUrl + "Fax/LockFax";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("internalDocumentId", internalDocumentId);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);

    return this.http.put<ApiResponse<string>>(endpoint, null, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<string>) => {
        if (response.succeeded && response.data) {
          return response.data;
        }
        return null;
      })
    );
  }

  unlockFax(internalDocumentId: string): void {
    if (internalDocumentId == undefined) return;

    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let endpoint = this.formServiceBaseUrl + "Fax/UnLockFax";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("internalDocumentId", internalDocumentId);

    this.http.put(endpoint, null, { headers: headers, params: queryParams }).subscribe(r => {
      this.faxUnlocked.next(true);
    });
  }

  getPreviosLos(authForm: AuthIntakeForm): void {
    if (authForm == undefined) return;
    if (!authForm.authIntakeHeader.authIntakeId) {
      this.PreviosLos.next(0);
      return;
    }
    let formsUrl = this.formServiceBaseUrl + "Forms/GetPreviousLos";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeId", authForm.authIntakeHeader.authIntakeId);
    queryParams = queryParams.append("authDetailNumber", authForm.authIntakeDetail[0].authDetailNumber);

    this.http.get<ApiResponse<number>>(formsUrl, { params: queryParams }).subscribe(d => {
      if (d && d.succeeded && d.data) {
        this.PreviosLos.next(d.data);
      }
    })
  }

  getIsSensitiveData(): boolean {
    return this._authForm.authIntakeHeader.sensitiveData;
  }

  isAuthConcurrentEligible(authDetailNumber: string, authNumber: number) {
    let formsUrl = this.formServiceBaseUrl + "Forms/AuthConcurrentEligibleAsync";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authDetailNumber", authDetailNumber);
    queryParams = queryParams.append("authNumber", authNumber);

    return this.http.get<ApiResponse<boolean>>(formsUrl, { params: queryParams });
  }

  getAuthDetails(authDetailNumber: string, authNumber: number) {
    let formsUrl = this.formServiceBaseUrl + "Forms/GetAuthDetailAsync";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authDetailNumber", authDetailNumber);
    queryParams = queryParams.append("authNumber", authNumber);

    return this.http.get<ApiResponse<number>>(formsUrl, { params: queryParams });
  }

  getAuthNote(noteId: number, eventDate: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let queryParams = new HttpParams();
    queryParams = queryParams.append("noteId", noteId);
    queryParams = queryParams.append("eventDate", eventDate);
    let options = { headers: headers, params: queryParams };
    let formListUrl = this.formServiceBaseUrl + "Forms/GetAuthNoteAsync";
    this.http.get<ApiResponse<AuthIntakeNote>>(formListUrl, options).subscribe(
      data => {
        if (data.succeeded == true) {
          this.AuthNote.next(data.data!);
        }
      }
    );
  }

  getRecentEvents(userName: string, eventType?: string): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", userName);
    if (eventType) {
      queryParams = queryParams.append("eventType", eventType);
    }

    let formListUrl = this.formServiceBaseUrl + "Forms/GetRecentEvents";
    this.http.get<ApiResponse<AuthIntakeRecentEvent[]>>(formListUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this._recentEvents = data.data!;
          this.RecentEventList.next(this._recentEvents);
        }
      }
    );
  }

  AddRecentEvent(authDetailId: number, eventType: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let userName = this.user.profile.preferred_username;
    let addRecentEventEndpoint = this.formServiceBaseUrl + "Forms/AddRecentEvent";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", userName);
    queryParams = queryParams.append("authDetailID", authDetailId);
    queryParams = queryParams.append("eventType", eventType);

    this.http.post(addRecentEventEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => {
      this.getRecentEvents(userName);
    });
  }

  validateResubmitAuth(memberId: string, authDetailNumber: string, authNumber: string): Observable<number | null> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let validateEndpoint = this.formServiceBaseUrl + "Forms/ValidateResubmitAuth";

    let queryParams = new HttpParams();

    queryParams = queryParams.append("memberId", memberId);
    queryParams = queryParams.append("authDetailNumber", authDetailNumber);
    queryParams = queryParams.append("authNumber", authNumber);

    return this.http.post<ApiResponse<number>>(validateEndpoint, null, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<number>) => {
        if (response.succeeded && response.data) {
          return response.data;
        }
        return null;
      })
    );
  }

  logResubmission(authDetailNumber: string, authNumber: string, overrideEdit: boolean, overrideSSC: boolean) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let validateEndpoint = this.formServiceBaseUrl + "Forms/LogAuthResubmission";

    let queryParams = new HttpParams();

    queryParams = queryParams.append("authDetailNumber", authDetailNumber);
    queryParams = queryParams.append("authNumber", authNumber);
    queryParams = queryParams.append("isOverrideEdit", overrideEdit);
    queryParams = queryParams.append("isOverrideSSC", overrideSSC);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);

    this.http.post(validateEndpoint, null, { headers: headers, params: queryParams }).subscribe();
  }

  getInterqualXml(authReviewDetailID: number) {
    let formsUrl = this.formServiceBaseUrl + "Forms/GetInterqualXml";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authReviewDetailID", authReviewDetailID);

    return this.http.get<ApiResponse<string>>(formsUrl, { params: queryParams });
  }

  private getLineItemStatuses(): AuthLineItem[] {
    var lineItemList: AuthLineItem[] = [];
    this._authForm.authIntakeReview.forEach(review => {
      if (!review.obsolete && review.reviewOutcome) {
        var authLineItem: AuthLineItem = {
          lineItemType: 'los',
          lineItem: null,
          lineItemStatusId: review.reviewOutcome ?? null,
          lineItemReasonId: review.outcomeReason ?? null
        }
        lineItemList.push(authLineItem);
      }
    })
    this._authForm.authIntakeStatus.forEach(status => {
      if (!status.obsolete) {
        let procCode = this._authForm.authIntakeProcCodes.find(p => p.authIntakeProcCodeID == status.authIntakeProcCodeId);
        var authLineItem: AuthLineItem = {
          lineItemType: procCode?.procType ?? 'P',
          lineItem: procCode?.procCodeId ?? null,
          lineItemStatusId: status.status!,
          lineItemReasonId: status.statusReason!
        }
        lineItemList.push(authLineItem);
      }
    })
    return lineItemList;
  }

  getFinalAuthStatus(): Observable<AuthFinalStatus | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let statusEndpoint = this.formServiceBaseUrl + "Forms/GetFinalAuthStatus";
    let options = { headers: headers };

    return this.http.post<ApiResponse<AuthFinalStatus>>(statusEndpoint, JSON.stringify(this.getLineItemStatuses()), options).pipe(
      map(r => {
        if (r.succeeded) {
          return r.data;
        }
        return undefined;
      }));
  }

  getConcurrentAuth(): boolean {
    return this.ConcurrentAuth.value;
  }
}
