import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { alertOptions } from "../../assets/constants";
import { Alert } from "../models/signalR/alert";
import { AppSettings } from "../shared/appsettings";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
  providedIn: 'root'
})
  
export class NotificationService {
  user: any;
  public AlertData = new BehaviorSubject<Alert[]>([]);
  public AlertDataCache: Alert[] = [];

  constructor(private tokenStorage: TokenStorageService, private appSettings: AppSettings, private http: HttpClient) { }

  public getAlerts() {
    this.user = this.tokenStorage.getUser();
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("productId", this.appSettings.productId);
    let alertSvcUrl = this.appSettings.alertSvcBaseUrl + "Alerts/UserAlerts";
    this.http.get<Alert[]>(alertSvcUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        console.log("GetAlerts");
        if (data && data.length > 0) {
          data.forEach(d => {
            if (d.attributes.length > 0) {
              let name = d.attributes.find(a => a.name.toLowerCase() == 'member name');
              let authId = d.attributes.find(a => a.name.toLowerCase() == 'auth number');
              let authIntakeId = d.attributes.find(a => a.name.toLowerCase() == 'auth intake id');
              let memberId = d.attributes.find(a => a.name.toLowerCase() == 'member id');
              if (name) {
                d.memberName = name.value;
              }
              if (authId) {
                d.authId = authId.value;
              }
              if (authIntakeId) {
                d.authIntakeId = +authIntakeId.value;
              }
              if (memberId) {
                d.memberId = memberId.value;
              }
            }
          })
          this.AlertDataCache = data;
          this.AlertData.next(data);
        }
      }
    );
  }

  public updateAlert(alerts: number[], option: alertOptions) {
    this.user = this.tokenStorage.getUser();
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("alertId", alerts.join(','));
    queryParams = queryParams.append("option", option.toString());
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    let alertSvcUrl = this.appSettings.alertSvcBaseUrl + "Alerts/UpdateAlert";
    this.http.post(alertSvcUrl, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  public newAlertReceived(alert: Alert) {
    let alerts = this.AlertData.value;
    var existing = alerts.findIndex((a) => {
      return a.alertId === alert.alertId;
    });
    if (existing > -1) {
      alerts.splice(existing, 1);
    }
    alerts.push(alert);
    this.AlertData.next(alerts);
  }
}
