import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { InboundDocument } from "@CommonLib/models/storage/inboundDocument";
import { LookupService } from '../../services/lookup.service';
import { LookupValue } from '../../models/Lookups/lookupValue';
import { FaxQueueItem } from '../../models/fax/faxQueueItem';
import { ConfigService } from '../../services/config-service.service';
import { Queue } from '../../models/configuration/queue';
import { DATE_WITH_SECONDS_AMPM, MYQUEUEFAXES, TEAMQUEUEFAXES } from '../../../assets/constants';
import { MatDialog } from '@angular/material/dialog';
import { FilterService } from '../../services/filter-service.service';
import { ConfirmDialogComponent } from '../../shared/components/dialog/dialog-confirm/dialog-confirm.component';
import { Subscription, async } from 'rxjs';
import { DocumentService } from '@CommonLib/services/docStorage.service';
import { AppSettings } from '../../shared/appsettings';
import { TokenStorageService } from '../../services/token-storage.service';
import { FormService } from '../../services/form-service.service';
import { SignalrService } from '../../services/signalr.service';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';
import { MatMenuTrigger } from '@angular/material/menu';
import { User } from '../../models/user';
import { FaxHelperService } from '../../services/fax-helper.service';

@Component({
  selector: 'app-my-queue-faxes',
  templateUrl: './my-queue-faxes.component.html',
  styleUrls: ['./my-queue-faxes.component.scss']
})
@AutoUnsubscribe
export class MyQueueFaxesComponent {
  @ViewChild('filterFaxDialogTemplate') filterFaxTemplate: TemplateRef<any> | undefined;
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger!: MatMenuTrigger;
  dateRangeFaxTeamQueue: UntypedFormGroup;
  faxes!: InboundDocument[];
  subs!: Subscription;
  splittingFax = false;
  lookupData!: LookupValue[];
  statusColor = '';
  faxesCount = 0;
  unfilteredFaxes!: InboundDocument[];
  queues!: FaxQueueItem[];
  selectedFax: string[] = [];
  faxQueues!: Queue[];
  queueSub!: Subscription;
  // queueCounts!: {
  //   [queueId: string]: number;
  // }
  dateFormat = DATE_WITH_SECONDS_AMPM;
  userFaxQueues!: Queue[];
  filterBadgeData = { text: '', count: 0 }
  faxLocked = false;
  isDisplayContextMenu: boolean=true;
  menuTopLeftPosition =  {x: 0, y: 0}; 
  userConfig: any;
  users: User[] = [];

  constructor(private dialog: MatDialog, private documentService: DocumentService, private lookupService: LookupService, private configService: ConfigService,
    private filterService: FilterService, private confirmDialog: ConfirmDialogComponent, private appSettings: AppSettings, private tokenStorage: TokenStorageService, 
    private signalR: SignalrService, private formService: FormService, private faxHelper: FaxHelperService) {

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.dateRangeFaxTeamQueue = new UntypedFormGroup({
      faxStartDate: new UntypedFormControl(),
      faxEndDate: new UntypedFormControl(),
      faxQueue: new UntypedFormControl(),
    });
    this.userConfig = this.tokenStorage.getUserConfig();
  }

  ngOnInit(): void {
    this.queueSub = this.configService.userFaxQueues.subscribe(q => {
      if (q) {
        this.userFaxQueues = q;
      }
    })
    this.subs = this.documentService.faxList.subscribe(f => {
      if (f && f.length > 0) {
        this.faxes = f.filter(f => f.assignedToUser == this.userConfig.userName);

        this.unfilteredFaxes = [...this.faxes];
        if (this.lookupData) 
          this.applyFilter();

        if (this.lookupData) {
          this.populateFaxes();
        }
      }
    })
    this.subs.add(this.configService.userList.subscribe(users => {
      if (users && users.length > 0){
        this.users = users;
      }
    }))
    this.subs.add(this.lookupService.LookupData.subscribe(l => {
      if (l && l.length > 0) {
        this.lookupData = l;
        this.populateFaxes();
      }
    }))
    this.subs.add(this.filterService.badgeDataBs.subscribe(bd => {
      if (bd.filter_type == MYQUEUEFAXES) {
        this.filterBadgeData = bd;
      }
    }));
    this.subs.add(this.documentService.DocClosed.subscribe(r => {
      if (r && r == true) {
        this.documentService.getFaxList(this.appSettings.storageServiceBaseUrl);
      }
    }));
    this.subs.add(this.documentService.faxStatusUpdated.subscribe(s => {
      if (s){
        this.selectedFax = [];
        this.documentService.getFaxList(this.appSettings.storageServiceBaseUrl);
      }
    }))
    this.subs.add(this.formService.faxUnlocked.subscribe(u => {
      if (u){
        this.selectedFax = [];
        this.documentService.getFaxList(this.appSettings.storageServiceBaseUrl);
      }
    }))
    this.signalR.lockedFaxId.subscribe(id => {
      if (id){
        this.queues.map(item => {
          if(item.internalId == id.faxId){
            item.locked = true;
            item.lockedByUserName = this.getLockUserName(id.displayName);
            item.lockedBy = id.displayName;
            item.lockDate = id.lockDate;
          }
          if (item.split){
            item.split.map(sp => {
              if (sp.internalId == id.faxId){
                sp.locked = true;
                sp.lockedByUserName = this.getLockUserName(id.displayName);
                sp.lockedBy = id.displayName;
                sp.lockDate = id.lockDate;
              }
            })
          }
        })
      }
    })
    this.signalR.unlockedFaxId.subscribe(id => {
      if (id){
        this.queues.map(item => {
          if(item.internalId == id){
            item.locked = false;
            item.lockedBy = '';
            item.lockedByUserName = '';
            item.lockDate = '';
          }
          if (item.split){
            item.split.map(sub => {
              if (sub.internalId == id){
                sub.locked = false;
                sub.lockedBy = '';
                sub.lockedByUserName = '';
                item.lockDate = '';
              }
            })
          }
        })
      }
    })
  }

  populateFaxes(){
    this.queues = this.faxHelper.populateFaxQueue(this.faxes);
    this.faxesCount = this.queues.length;
  }

  getLockUserName(displayName: string){
    if (this.users){
      var user = this.users.find(u => u.firstName + ' ' + u.lastName == displayName);
      if (user){
        return user.userName;
      }
    }
    return displayName;
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  //Refresh fax queue icon 
  refreshTitleText = 'Refresh fax queue';
  refreshQueue() {
    this.documentService.getFaxList(this.appSettings.storageServiceBaseUrl);
  }
  removeFaxEvent(event: any){
    if (event && event.action == 'add'){
      this.selectedFax.push(event.pdfId);
    } else if (event && event.action == 'remove'){
      const i = this.selectedFax.indexOf(event.pdfId);
      if (i !== -1) {
        this.selectedFax.splice(i, 1);
      }
    }
  }
  /**
   * @brief Updated method to use the Storage service from Common project.
   *  Added new reference to TokenStorageService also so we can get the user name.
   */
  removeCompletedFax() {
    const dialogRef = this.confirmDialog.open({
      title: 'Delete Permanently',
      message: `Are you sure you want to permanently delete this fax?`,
      acceptButtonText: 'Continue',
      declineButtonText: 'Cancel'
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
      let valueId = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'processed' && l.lookupSetID == 145)?.lookupValueID

      if (this.selectedFax.length > 0) {
        this.selectedFax.forEach(async s => {
          if (!valueId) {
            valueId = 10327; //Default Processed value
          }
          var user = this.tokenStorage.getUser();
          //No need to update P8 as this is just a removal from view.
          this.documentService.updateFaxStatus(s, valueId, -1, null, '',0,false,this.appSettings.storageServiceBaseUrl, user.profile.preferred_username, true);
        })
      }
      this.selectedFax = [];
      //this.refreshQueue();
      dialogRef.close();
    });
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30em',
      data: <IDialogConfig>{
        title: 'Filter Faxes',
        dialogContent: this.filterFaxTemplate,
        acceptButtonTitle1: 'Apply',
        declineButtonTitle: 'Clear Filters'
      }
    });

    const group = this.filterService.getSessionFilter(MYQUEUEFAXES);
    this.dateRangeFaxTeamQueue = this.filterService.getSavedGroup(group, this.dateRangeFaxTeamQueue);

    dialogRef.componentInstance.clickAcceptButton1.subscribe(r => {
      this.filterService.saveAsSessionFilter(this.dateRangeFaxTeamQueue, MYQUEUEFAXES);
      this.applyFilter();
      dialogRef.close();
    })

    dialogRef.componentInstance.clickDeclineButton.subscribe(r => {
      this.faxes = this.unfilteredFaxes;
      this.filterBadgeData = { text: '', count: 0 };
      this.dateRangeFaxTeamQueue.reset();
      this.populateFaxes();
      dialogRef.close();
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
    });
  }

  applyFilter() {
    let group = this.filterService.getSessionFilter(MYQUEUEFAXES);
    if (group && this.lookupData) {
      this.dateRangeFaxTeamQueue = this.filterService.getSavedGroup(group, this.dateRangeFaxTeamQueue);
      const startDate = this.dateRangeFaxTeamQueue.get('faxStartDate')?.value;
      const endDate = this.dateRangeFaxTeamQueue.get('faxEndDate')?.value;
      const filterQueue = this.dateRangeFaxTeamQueue.controls['faxQueue'].value;
      let filterCount = 0;
      if (startDate && endDate) {
        const parents = this.unfilteredFaxes.filter(f => new Date(f.createdDate) >= new Date(startDate) && new Date(f.createdDate) <= new Date(endDate) && f.internalDocumentId == f.parentDocumentId);
        const filtered = this.unfilteredFaxes.filter(a => parents.some(b => b.internalDocumentId === a.parentDocumentId) && a.internalDocumentId !== a.parentDocumentId);
        this.faxes = [...parents, ...filtered];
        filterCount++;
      }
      if (filterQueue && startDate && endDate) {
        const parents = this.faxes.filter(f => f.assignedToQueueId == filterQueue.toString() && f.internalDocumentId == f.parentDocumentId);
        const filtered = this.faxes.filter(a => parents.some(b => b.internalDocumentId === a.parentDocumentId) && a.internalDocumentId !== a.parentDocumentId);
        this.faxes = [...parents, ...filtered];
        filterCount++;
      } else if (filterQueue) {
        const parents = this.unfilteredFaxes.filter(f => f.assignedToQueueId == filterQueue.toString() && f.internalDocumentId == f.parentDocumentId);
        const filtered = this.unfilteredFaxes.filter(a => parents.some(b => b.internalDocumentId === a.parentDocumentId) && a.internalDocumentId !== a.parentDocumentId);
        this.faxes = [...parents, ...filtered];
        filterCount++;
      }
      this.populateFaxes();
      this.filterService.setBadgeData(group!, MYQUEUEFAXES);
    }
  }

  unlockFax(item: FaxQueueItem){
    if (item){
      this.formService.unlockFax(item.internalId);
      this.signalR.broadcastMessage(`unlocked:${item.internalId}`);
      this.signalR.clientMessage(`Fax ${item.name} has been unlocked by Supervisor`, item.lockedByUserName);
    }
  }
}
