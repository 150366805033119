import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private isLoggedIn: boolean;
  private observer$: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.observer$ = this.authService.loginChanged;
    this.observer$.subscribe((flag) => {
      this.isLoggedIn = flag;
    });
  }

  canActivate(): boolean {
    if (this.isLoggedIn) {
      return true;
    }
    else {
      this.authService.login();
      return false;
    }
  }
}
