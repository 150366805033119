import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormService } from '../../services/form-service.service';
import { QueueService } from '../../services/queue.service';

export interface ISnapshotButtonConfig {
  id: number;
  icon: string;
  count?: number;
  option?: string;
}

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit, OnDestroy {
  ISnapshotButtonConfig: any;

  constructor(private formService: FormService, private queueService: QueueService) { }

  //Snapshot filter button data
  filterButtons: ISnapshotButtonConfig[] = [];
  
  active: any = '';

  filterMyQueue() {
    //alert('Filter my queue - ' + this.active);
    this.queueService.filterAuthOwnerQueue(this.active);
  }

  dashboardSub!: Subscription;

  ngOnInit(): void {
    this.dashboardSub = this.formService.DashboardCounts.subscribe(d => {
      if (d.myAssignments !== undefined) {
        this.filterButtons = [];
        let snap: ISnapshotButtonConfig = {
          id: 0,
          icon: 'event',
          count: d.myAssignments,
          option: 'Authorizations Assigned To Me'
        }
        this.filterButtons.push(snap);
        let snap2: ISnapshotButtonConfig = {
          id: 1,
          icon: 'warning',
          count: d.overdueAuths,
          option: 'Overdue Authorizations'
        }
        this.filterButtons.push(snap2);
        let snap3: ISnapshotButtonConfig = {
          id: 2,
          icon: 'live_help',
          count: d.awaitingInfo,
          option: 'Authorizations Awaiting Information'
        }
        this.filterButtons.push(snap3);
        let snap4: ISnapshotButtonConfig = {
          id: 3,
          icon: 'task_alt',
          count: d.taskCount,
          option: 'Tasks Due Today'
        }
        this.filterButtons.push(snap4);
        let snap5: ISnapshotButtonConfig = {
          id: 4,
          icon: 'rate_review',
          count: d.physicianReview,
          option: 'Authorizations in Physician Review'
        }
        this.filterButtons.push(snap5);
        let snap6: ISnapshotButtonConfig = {
          id: 5,
          icon: 'schedule',
          count: d.dueToday,
          option: 'Authorizations Due Today'
        }
        this.filterButtons.push(snap6);
      }
    })
  }

  ngOnDestroy() {
    if (this.dashboardSub !== undefined) {
      this.dashboardSub.unsubscribe();
    }
  }
}


