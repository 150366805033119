import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { FormService } from '../services/form-service.service';
import { TokenStorageService } from '../services/token-storage.service';
import { LookupService } from '../services/lookup.service';
import { RefreshService } from '../services/refresh.service';
import { ConfigService } from '../services/config-service.service';
import { User } from '../models/user';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TaskService } from '../services/task-service.service';
import { HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AlertService } from '../_alert';
import { AppSettings } from '../shared/appsettings';
import { alertOptions, AuthFormTabs, CHARTHISTORY, DATE_WITH_SECONDS_AMPM, DOCTYPE_AVATAR, TASKSTATUS_SUCCESSFUL, TASKSTATUS_UNSUCCESSFUL } from '../../assets/constants';
import { Queue } from '../models/configuration/queue';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { SignalrService } from '../services/signalr.service';
import { AutoUnsubscribe } from '../shared/decorators/auto-unsubscribe.decorator';
import { NotificationService } from '../services/notification.service';
import { Alert } from '../models/signalR/alert';
import { Router } from '@angular/router';
import { ValidationService } from '../services/validation.service';
import { DialogService } from '../shared/guards/dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { DocumentService } from '@CommonLib/services/docStorage.service';
import { FilterService } from '../services/filter-service.service';
import { MemberService } from '@CommonLib/services/member-service.service';

@AutoUnsubscribe

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('viewAlertDialogTemplate') viewAlertTemplate: TemplateRef<any> | undefined;

  toggle: boolean = true;
  user: any;
  userName: string = "";
  userRole: string = "";
  today = this.dateHack(new Date().toString());
  queues!: Queue[];
  users!: User[];
  userFullName: string = "";
  newAuthCount: BehaviorSubject<number> = new BehaviorSubject(0);
  newTeamAuthCount: BehaviorSubject<number> = new BehaviorSubject(0);
  newAuthCountMsg: string = 'New Authorizations';
  newTaskCount: BehaviorSubject<number> = new BehaviorSubject(0);
  newTeamTaskCount: BehaviorSubject<number> = new BehaviorSubject(0);
  newTaskCountMsg: string = 'New Tasks';
  formsSub!: Subscription;
  tasksSub!: Subscription;
  configSub!: Subscription;
  lookupSub!: Subscription;
  successId!: number[];
  unsuccessfulId!: number[];
  avatarImg!: File;
  avatar!: SafeUrl;
  bellIcon: boolean=false;
  unreadAlertCount: number = 0;
  checkedAlerts: number[] = [];
  isAlertChecked: boolean = false;
  alerts: Alert[] = [];
  avatarId!: string;
  dateWithSeconds: string = DATE_WITH_SECONDS_AMPM;
  selectedTeamTab!: number;
  selectedMyTab!: number;
  teamTabs: any[] = [
    { value: 0, viewValue: 'Authorizations', displayValue: 'Authorizations'},
    { value: 1, viewValue: 'Faxes', displayValue: 'Faxes'},
    { value: 2, viewValue: 'Task', displayValue: 'Task'}
  ];
  myTabs: any[] = [
    { value: 0, viewValue: 'MyAuthorizations', displayValue:'Authorizations'},
    { value: 2, viewValue: 'MyTask', displayValue: 'Task'}
  ];
  userData!: User|undefined;
  isManager: boolean = false;

  constructor(private formService: FormService, private lookupService: LookupService, private tokenService: TokenStorageService,
    private refreshService: RefreshService, private configService: ConfigService, private taskService: TaskService,
    private documentService: DocumentService, private notifications: NotificationService, private filterService: FilterService,
    private sanitizer: DomSanitizer, private alertService: AlertService, private appConfig: AppSettings, private dialog: MatDialog, 
    private signalRService: SignalrService, private router: Router, private memberService: MemberService,
    private validationService: ValidationService, private dialogService: DialogService, private authService: AuthService) {
    this.refreshService.initializeTimers();
    if (this.appConfig.features && this.appConfig.features['bellIcon']) {
      this.bellIcon = JSON.parse(this.appConfig.features['bellIcon']);
    }
    
  }

  toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  dateHack(data: string) {
    let parts = data.split(' ');
    let newDate = '';
    parts.forEach(p => {
      if (!p.includes('GMT')) {
        newDate += p + ' ';
      }
    })
    return newDate;
  }

  setDefaultTab() {
    !this.selectedTeamTab ? this.selectedTeamTab = 0 : this.selectedTeamTab;
    !this.selectedMyTab ? this.selectedMyTab = 0 : this.selectedMyTab;
  }

  ngOnInit(): void {
    this.formService.tabChanged(AuthFormTabs.ELIGIBILITY);
    if (!this.authService.isLoggedIn()){
      return;
    }
    this.user = this.tokenService.getUser();
    this.userName = this.user.profile.preferred_username;
    this.formService.resetAuthForm();
    this.validationService.resetTabStatus();
    this.memberService.resetDemographics();
    this.memberService.resetEligibility();
    this.memberService.setMvdid('');
    this.filterService.resetSessionFilter(CHARTHISTORY);
    this.userData = this.tokenService.getUserConfig();
    if (!this.userData?.userId){
      this.configService.getActiveUsersByUserName([this.userName]);
    } else {
      this.populateUserInfo();
    }

    if (this.bellIcon) {
        this.notifications.getAlerts();
    }
    this.configService.getUserPreferences(this.user.profile.preferred_username)

    this.lookupSub = this.lookupSub = this.lookupService.LookupData.subscribe(data => {
      this.successId = data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_SUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
      this.unsuccessfulId = data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_UNSUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
    })

    this.tasksSub = this.taskService.teamTaskList.subscribe(data => {
      if (data !== undefined) {
        let newCount = data.filter(d => d.showAsNew == true && !(this.successId.includes(d.statusId) || this.unsuccessfulId.includes(d.statusId))).length ?? 0;
        this.newTeamTaskCount.next(newCount);
      }
    })

    this.tasksSub.add(this.taskService.userTaskList.subscribe(t => {
      if (t !== undefined) {
        let newCount = t.filter(d => d.showAsNew == true && !(this.successId.includes(d.statusId) || this.unsuccessfulId.includes(d.statusId))).length ?? 0;
        this.newTaskCount.next(newCount);
      }
    }))

    this.formService.getUmDashboardCounts();
    this.formsSub = this.formService.NewAuths?.subscribe(data => {
      if (data !== undefined) {
        this.newAuthCount.next(data);
      }
    });

    this.formsSub.add(this.formService.NewTeamAuths.subscribe(data => {
      if (data !== undefined) {
        this.newTeamAuthCount.next(data);
      }
    }));

    this.configSub = this.configService.userListById.subscribe(data => {
      if (data.length > 0) {
        console.log('Got User List');
        this.users = data;
        this.populateUserInfo();
      }
    });
        
    let currentUserName = this.tokenService.getUser().profile.preferred_username;
    this.configService.getUserAccessList(currentUserName);

    this.configSub.add(this.configService.accessList.subscribe(data => {
      if (data.length > 0) {
        this.tokenService.saveUserRbac(data);
      }
    }));

    this.configService.getAllFaxQueues();
    this.configService.getUserGroups(this.userName);

    setInterval(() => {
      this.today = this.dateHack(new Date().toString());
    }, 1000);

    this.documentService.avatarId.subscribe(a => {
      if (a && a.blob) {
        if (a.blob.name && a.blob.name.includes('/')) {
          this.avatarId = a.blob.name.split('/')[1];
        } else {
          this.avatarId = a.blob.name!;
        }
        if (this.userData){
          this.userData.avatar = this.avatarId;
          this.configService.updateUser(this.userData);
        }
        
        this.getAvatar();
      }
    })

    this.notifications.AlertData.subscribe(d => {
      if (d && this.bellIcon) {
        this.alerts = d.sort((a,b)=>(a.createdDate > b.createdDate ? -1 : 1));
        this.unreadAlertCount = this.alerts.filter(element => element.readDate == '' || element.readDate == null).length;
      }
    })

    this.configService.UserPreferences.subscribe(c => {
      if (c && c.length > 0) {
          const teamTab = c.find(a => a.attributeType == 'defaultteamqueuetab');
          if (teamTab) {
            if (teamTab.vdtAttributeName.toLowerCase() == "task") {
              this.selectedTeamTab = 2;
            } else if (teamTab.vdtAttributeName.toLowerCase() == "faxes") {
              this.selectedTeamTab = 1;
            } else {
              this.selectedTeamTab = 0
            }
          } else {
            this.selectedTeamTab = 0
          }
          const myTab = c.find(a => a.attributeType == 'defaultmyqueuetab');
          if (myTab) {
            if (myTab.vdtAttributeName.toLowerCase() == "mytask") {
              this.selectedMyTab = 2;
            } else {
              this.selectedMyTab = 0
            }
          } else {
            this.selectedMyTab = 0
          }
      }
    })
  }

  populateUserInfo(){
    if (!this.userData?.userId){
      this.userData = this.users.find(n => n.userName.toLowerCase() == this.user.profile.preferred_username.toLowerCase());
    }
    this.userFullName = this.userData?.firstName + ' ' + this.userData?.lastName;
    this.userRole = this.userData?.role!;
    if (this.userData) {
      console.log(this.userData.lastName);
      this.avatarId = this.userData.avatar;
      if (this.avatarId) {
        this.getAvatar();
      }
      this.tokenService.saveUserConfig(this.userData);
    } else {
      console.log('user not found ' + this.user.profile.preferred_username);
      console.log('No User Data');
    }
    if (this.userRole && this.userRole.toLowerCase() == 'um manager'){
      this.isManager = true;
    }
  }

  async removeAvatar(){
    let discard = await this.dialogService.confirmAsync('Remove current Avatar image?');

      if (discard && this.userData) {
        this.userData.avatar = '';
        this.avatar = '';
        localStorage.removeItem(this.user.profile.preferred_username);
        this.configService.updateUser(this.userData);
      }
  }

  teamDefaultTabChanged(event: any) {
    this.selectedTeamTab = event.value;
    const tab = this.teamTabs.find(t => t.value == event.value);
    if (tab) {
      this.configService.updateUserPreferences(tab.viewValue, 'defaultteamqueuetab');
    }
  }

  myDefaultTabChanged(event: any) {
    this.selectedMyTab = event.value;
    const tab = this.myTabs.find(t => t.value == event.value);
    if (tab) {
      this.configService.updateUserPreferences(tab.viewValue, 'defaultmyqueuetab');
    }
  }

  uploadImage(files: FileList | null) {
    if (files !== null) {
      this.avatarImg = files[0];
      const fsize = files[0].size;
      const maxMb = Math.round((this.appConfig.maxAvatarFileSize / 1024));
      if (Math.round((fsize / 1024)) > this.appConfig.maxAvatarFileSize) {
        this.alertService.error(`File size is too large. Select a file smaller than ${maxMb}Mb.`);
        return;
      } 
      var formData = new FormData();
      //var fileExtension = this.avatarImg.name.split('.').pop();
      var user = this.tokenService.getUser();
      //Rename the file to current userName
      formData.append('file', this.avatarImg);
      this.documentService.uploadAvatar(formData, this.avatarImg.name,this.appConfig.storageServiceBaseUrl,this.appConfig.customerId,this.appConfig.productId,this.user.preferred_username,DOCTYPE_AVATAR);
    }
  }

  getAvatar() {
    var dataUri = localStorage.getItem(this.user.profile.preferred_username);
    if (dataUri) {
      var img = this.dataURItoBlob(dataUri)
      if (img) {
        let objectUrl = URL.createObjectURL(img);
        this.avatar = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        return;
      }
    }
    
    if (this.avatarId.includes('/')) {
      this.avatarId = this.avatarId.split('/')[1];
    }

    this.documentService.getAvatar(this.avatarId,this.appConfig.storageServiceBaseUrl,this.appConfig.productId).subscribe((data) => {
      switch (data.type) {
        case HttpEventType.Response:
          if (data.body && data.body.type) {
            let objectUrl = URL.createObjectURL(data.body);
            this.avatar = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
            const reader = new FileReader();

            reader.onload = (event) => {
              if (event && event.target && event.target.result) {
                localStorage.setItem(this.user.profile.preferred_username, event.target.result.toString());
              }
            }

            reader.readAsDataURL(data.body);
          }
          break;
        case undefined: {

          }
      }
    }, error => {
        console.log(error);
    })
  }

  // *** Alerts ***
  openAlertDialog() {
    let title = `${this.unreadAlertCount} Unread Alert(s)`;

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '40em',
      data: <IDialogConfig>{
        title: title,
        dialogContent: this.viewAlertTemplate,
        acceptButtonTitle2: 'Check Now',
        declineButtonTitle: 'Close',
        displayClose: true
      },
      autoFocus: false
    });

    dialogRef.componentInstance.clickAcceptButton2.subscribe(r => {
      this.notifications.getAlerts();
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      // delete it
    });
  }

  getCheckboxes() {
    this.checkedAlerts = this.alerts.filter(data => data.checked === true).map(data => data.alertId);
    this.isAlertChecked = false;

    if (this.checkedAlerts.length > 0) {
      this.isAlertChecked = true;
    }   
  }

  removeAlerts() {
    // remove all selected (acknowledged) alerts
    this.checkedAlerts = this.alerts.filter(data => data.checked === true).map(data => data.alertId);
    //this.notifications.updateAlert(this.checkedAlerts, alertOptions.DEL);
    this.alerts = this.alerts.filter(a => !this.checkedAlerts.includes(a.alertId));
    this.unreadAlertCount = this.alerts.filter(element => element.readDate == '' || element.readDate == null).length;
    this.checkedAlerts = [];
    this.isAlertChecked = false;
  }

  setAsUnread() {
    // set all selected (acknowledged) alerts as unread
    this.checkedAlerts = this.alerts.filter(data => data.checked === true).map(data => data.alertId);
    //this.notifications.updateAlert(this.checkedAlerts, alertOptions.NEW);
    this.alerts.forEach(a => {
      if (this.checkedAlerts.includes(a.alertId)){
        a.readDate = '';
        a.checked = false;
      }
    });
    
    this.unreadAlertCount = this.alerts.filter(element => element.readDate == '' || element.readDate == null).length;
    this.checkedAlerts = [];
    this.isAlertChecked = false;
  }

  openAuthHistory(mvdid: string, alertId: number) {
    let alerts: number[] = [];
    alerts.push(alertId);
    this.acknowledgeAlert(alertId);
    this.notifications.updateAlert(alerts, alertOptions.ACK);
    this.router.navigate(["/member/authorizations/".concat(mvdid)], { skipLocationChange: true });
    this.dialog.closeAll();
  }

  openAuth(authIntakeId: number, alertId: number, authId: string) {
    let alerts: number[] = [];
    alerts.push(alertId);
    this.acknowledgeAlert(alertId);
    this.unreadAlertCount = this.alerts.filter(element => element.readDate == '' || element.readDate == null).length;
    this.notifications.updateAlert(alerts, alertOptions.ACK);
    const authDetailNumber = authId.split('-')[0]
    this.router.navigate([`/member/authorization-intake/${authIntakeId}/${authDetailNumber}`], { skipLocationChange: true });
    this.dialog.closeAll();
  }

  acknowledgeAlert(alertId: number){
    let alert = this.alerts.find(a => a.alertId = alertId);
    if (alert){
      alert.readDate = new Date().toISOString();
      alert.acknowledgedDate = new Date().toISOString();
    }
  }
  // *** end of Alerts ***

  dataURItoBlob(dataURI:any) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
  }

  ngAfterViewInit() {
    if (this.bellIcon) {
      this.signalRService.startConnection();
      this.signalRService.addNotificationListener();
      this.signalRService.addBroadcastMessageListener();
      this.signalRService.addAlertListener();
    }
  }

  //  = [
  //  {
  //    mvdid: '19256744W03',
  //    memberName: 'Allie Pennigton',
  //    authId: '001-2341199',
  //    alertId: 4,
  //    alertText: 'Failed Fax: Number Busy',
  //    createdDate: '04/19/2023 10:21:03 AM',
  //    checked: false,
  //    alertTypeId: 1,
  //    recipientUsername: 'admin',
  //    productId: 5,
  //    appIdentifier: '123456',
  //    alertName: 'FailedFax',
  //    acknowledgedDate: '',
  //    readDate: '04/19/2023 10:21:03 AM',
  //    effectiveDate: '',
  //    archived: false,
  //    authIntakeId: 1456
  //  },
  //  {
  //    mvdid: '60000661101',
  //    memberName: 'Shea Garmin',
  //    authId: '001-1451166',
  //    alertId: 5,
  //    alertText: 'Member\'s policy will term on 04/25/2023',
  //    createdDate: '04/19/2023 9:23:44 AM',
  //    checked: false,
  //    alertTypeId: 1,
  //    recipientUsername: 'admin',
  //    productId: 5,
  //    appIdentifier: '123456',
  //    alertName: 'FailedFax',
  //    acknowledgedDate: '',
  //    readDate: '',
  //    effectiveDate: '',
  //    archived: false,
  //    authIntakeId: 1456
  //  },
  //  {
  //    mvdid: '06158973W00',
  //    memberName: 'Gerald Flemming',
  //    authId: '002-0071134',
  //    alertId: 2,
  //    alertText: 'Failed Fax: Incorrect Number',
  //    createdDate: '04/18/2023 11:20:02 AM',
  //    checked: false,
  //    alertTypeId: 1,
  //    recipientUsername: 'admin',
  //    productId: 5,
  //    appIdentifier: '123456',
  //    alertName: 'FailedFax',
  //    acknowledgedDate: '',
  //    readDate: '',
  //    effectiveDate: '',
  //    archived: false,
  //    authIntakeId: 1456
  //  },
  //  {
  //    mvdid: '19256744W03',
  //    memberName: 'Samuel Tadlock',
  //    authId: '001-4521199',
  //    alertId: 1,
  //    alertText: 'Failed Fax: Number Busy',
  //    createdDate: '04/18/2023 9:45:15 AM',
  //    checked: false,
  //    alertTypeId: 1,
  //    recipientUsername: 'admin',
  //    productId: 5,
  //    appIdentifier: '123456',
  //    alertName: 'FailedFax',
  //    acknowledgedDate: '',
  //    readDate: '',
  //    effectiveDate: '',
  //    archived: false,
  //    authIntakeId: 1456
  //  }
  //];
}

//export interface Alert {
//  memberId: string;
//  memberName: string;
//  authId: string;
//  alertId: string;
//  alertText: string;
//  alertCreatedDateTime: string;
//  checked: boolean;
//}
