import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { AppSettings } from "../shared/appsettings";
import { ApiResponse } from "../models/api/apiResponse";
import { TokenStorageService } from "./token-storage.service";
import { AGLogin } from "../models/ssoLogin/agLogin";

@Injectable({
  providedIn: 'root'
})
export class AGService {
  identityBaseUrl: string = '';
  private user: any;
  private userConfig: any;
  public SsoSaml = new BehaviorSubject<string>('');
  public LoginUrl = new Subject<AGLogin>();

  constructor(private http: HttpClient, private config: AppSettings, private tokenService: TokenStorageService) {
    this.identityBaseUrl = this.config.samlEndpoint;
    this.user = this.tokenService.getUser();
    
  }

  loginAG(): void {
    this.userConfig = this.tokenService.getUserConfig();
    let queryParams = new HttpParams();
    if (!this.user.profile){
      this.user = this.tokenService.getUser();
    }
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("firstName", this.userConfig.firstName);
    queryParams = queryParams.append("lastName", this.userConfig.lastName);
    let identityUrl = this.identityBaseUrl + "Auth/LoginAG";
    this.http.get<ApiResponse<AGLogin>>(identityUrl, { params: queryParams }).subscribe(
      data => {
        console.log("LoginAG");
         if (data.succeeded == true) {
            this.LoginUrl.next(data.data!);         
        }
      }
    );
  }

}

