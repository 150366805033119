import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@CommonLib/components/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthSearchComponent } from './auth-search.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    A11yModule
  ],
  declarations: [
    AuthSearchComponent
  ],
  exports: [
    AuthSearchComponent
  ]
})
export class AuthSearchModule { }
