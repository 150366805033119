import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable, tap, finalize, of, retry, throwError } from 'rxjs';
import { AlertService } from '../_alert';
import { HttpCacheService } from './http-cache.service';
import { LoaderService } from './loader.service';
import { TokenStorageService } from './token-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  private totalRequests = 0;
  private isRefreshing = false;
  constructor(private injector: Injector, private loadingService: LoaderService, private cacheService: HttpCacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const authService = this.injector.get(TokenStorageService);
    let token = authService.getToken();
    const jwtHelper = new JwtHelperService();
    if (token && jwtHelper.isTokenExpired(token)){
      console.log('Token expired. Refreshing...');
      const tokenRefresh = this.injector.get(AuthService);
      token = tokenRefresh.refreshToken();
    }
    let headers: HttpHeaders = req.headers;
    if (token !== null) {
      if ((req.headers.get('Content-Type') == '' || req.headers.get('Content-Type') == undefined) && !req.url.toLowerCase().includes('storage')) {
        headers = req.headers.append('Content-Type', 'application/json');
      }
      if (req.url.toLowerCase().includes('initiate')) {
        headers = headers.delete('Content-Type');

        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
      } else if (req.url.toLowerCase().includes('logininterqual')) {
        //Leave header as is.
        headers = headers.delete('Content-Type');
      } else if (req.url.toLowerCase().includes('loginag')) {
        //Leave header as is.
        headers = headers.delete('Content-Type');
      } else {
        headers = headers
          .append('Authorization', 'Bearer ' + token)
          .append('Access-Control-Allow-Origin', '*')
          .append('Cache-Control', 'max-age=0');
      }
    } else {
      headers = headers.append('Access-Control-Allow-Origin', '*');
    }
    authReq = req.clone({ headers });
    this.totalRequests++;
    if (!authReq.url.toLowerCase().includes('splitdocument') && !authReq.url.toLowerCase().includes('storage/download') && !authReq.url.toLowerCase().includes('dashboard')
        && !authReq.url.toLowerCase().includes('gettaskbyowner') && !authReq.url.toLowerCase().includes('gettaskbygroupids') && !authReq.url.toLowerCase().includes('getinboundfaxeslist')
        && !authReq.url.toLowerCase().includes('useralerts')) {
      this.loadingService.setLoading(true);
    }
    return next.handle(authReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (req.url.includes('Storage')) {
            console.log(`Adding item to cache: ${req.url}`);
            this.cacheService.put(req.url, event);
          }
        }
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests <= 0) {
          this.loadingService.setLoading(false);
        }
      }),
      catchError((response: HttpErrorResponse) => {
        const alertService = this.injector.get(AlertService);
        let errorMessage = '';
        if (
          response.error instanceof HttpErrorResponse &&
          !req.url.includes('auth/signin') &&
          response.error.status === 401
        ) {
          return this.handle401Error(req, next);
        } else if (response.error?.Message != undefined && response.error?.Message.includes('Timeout Expired')){
          return throwError(() => new Error(response.error.Message));
        } else if (response.error?.Message != undefined && response.error?.Message != null) {
          errorMessage = `Error Status: ${response.status}\nMessage: ${response.error.Message}`;
        }
        else if (response.error instanceof Array) {
          response.error.forEach(err => {
            errorMessage += err.name + " : " + err.message + "<br/>";
          });
        } else if (response.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${response.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Status: ${response.status}\nMessage: ${response.message}`;
        }
        if (!response.message.includes('containerName=avatar')) {
          alertService.error(errorMessage);
        }
        //notificationService.error(errorMessage);
        return of(errorMessage);
      })
    ) as Observable<HttpEvent<any>>;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log('Handling 401 error');
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const tokenRefresh = this.injector.get(AuthService);
      var token = tokenRefresh.refreshToken();
      if (token) {
        this.isRefreshing = false;

        return next.handle(request);
      }
    }

    return next.handle(request);
  }
}
