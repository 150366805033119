import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { MemberAuthData } from '../../auth-search/MemberAuthData';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpEventType } from '@angular/common/http';
import { DocSplitInfo } from '../../../../models/storage/docSplitInfo';
import { LookupService } from '../../../../services/lookup.service';
import { LookupValue } from '../../../../models/Lookups/lookupValue';
import { FormService } from '../../../../services/form-service.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NewAuthAttachment } from '../../../../models/storage/newAuthAttachment';
import { ConfirmDialogComponent } from '../dialog-confirm/dialog-confirm.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from "../../../guards/dialog.service";
import { DocumentService } from '@CommonLib/services/docStorage.service';
import { AppSettings } from '../../../appsettings';
import { TokenStorageService } from 'projects/um/src/app/services/token-storage.service';
import { SignalrService } from 'projects/um/src/app/services/signalr.service';
import { User } from 'projects/um/src/app/models/user';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe.decorator';
import Swal from 'sweetalert2';
//@AutoUnsubscribe
@Component({
  selector: 'app-dialog-fax',
  templateUrl: './dialog-fax.component.html',
  styleUrls: ['./dialog-fax.component.scss']
})
/**
 * @brief 1/25/2024 Bhupinder Changed DocumentService reference to Common porject.
 */
export class DialogFaxComponent implements OnInit, OnDestroy {
  @ViewChild('processSplitFaxDialogTemplate') processSplitFaxTemplate: TemplateRef<any> | undefined;
  @ViewChild('viewSplitFaxDialogTemplate') viewSplitFaxTemplate: TemplateRef<any> | undefined;
  @ViewChild('fromPage', { static: true }) fromPage!: ElementRef;
  selectedAuth!: string;
  selectDocUrl!: string;
  lookupData!: LookupValue[];
  sub!: Subscription;
  mvdid!: string;
  statusId!: number;
  pdfPageCount!: number;
  showAdd = true;
  pdfSrc!: SafeResourceUrl;
  i: any;
  showLoading = false;
  selectedPdfId!: string;
  selectedPdfName!: string;
  childFax = false;
  selectedPdfItem!: any;
  memberIdSelected = false;
  authIdSelected = false;
  concAuthElig = new BehaviorSubject(false);
  selectedItem: any;
  newDocName!: string;
  validPages = true;

  processSplitFaxForm = new UntypedFormGroup({
    renameFax: new UntypedFormControl('')
  })

  viewId!: number;
  viewReceived!: string;
  viewWorkQueue!: string;
  viewSentFrom?: string;
  viewStatus!: string;
  viewAuthNumber?: string;
  viewDisplayName!: string;
  viewp8DocName?: string;
  displayName?: string;
  p8DocName?: string;
  internalDocumentId?: string;
  authDetailId!: number;
  user: any;
  userData!: User;
  faxQueue!: number;
  faxOwner!: string;
  showRenameBtn: boolean = false;
  showSuccessAlert: boolean = false;

  pdfs = [
    { page: "1",
      From: 1,
      To: 1,
      Title: "" }
  ];

  splitPdfs = [
    { pages: "1,2", description: "Lab Results for Auth# 0001-2265889" }
  ];

  removeSplit(i: any) {
    if (i) {
      const indexOfObject = this.pdfs.findIndex((object) => {
        return object.From === i.From;
      });
      if (indexOfObject !== -1) {
        this.pdfs.splice(indexOfObject, 1);
      }
      this.showAdd = true;
    }
  };

  onLoad() {
    setTimeout(() => { this.showLoading = false; }, 3000);  
  }

  constructor(public dialog: MatDialog, private _sanitizer: DomSanitizer, private documentService: DocumentService, private lookupService: LookupService,
    private formService: FormService, private router: Router, private confirmDialog: ConfirmDialogComponent, private dialogService: DialogService,
    private appSettings: AppSettings, private tokenSvc: TokenStorageService, private signalR: SignalrService) {
      this.user = this.tokenSvc.getUser();
      this.userData = this.tokenSvc.getUserConfig();
  }

  ngOnInit(): void {
    this.sub = this.lookupService.LookupData.subscribe(d => {
      if (d) {
        this.lookupData = d;
      }
    })
    this.memberIdSelected = false;
    this.authIdSelected = false;
    this.pdfs = [];
    this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  
  onFaxNameChange(): void {
    setTimeout( () => {
      this.showRenameBtn = true;
    }, 500);
  }

  handleRename(): void {
    this.documentService.renameFax(this.selectedPdfId,this.processSplitFaxForm.controls['renameFax'].value, this.user.profile.preferred_username, 
      this.appSettings.storageServiceBaseUrl).subscribe(() => {
        this.showSuccessAlert = true;
        setTimeout( () => {
          this.showSuccessAlert = false;
        }, 2000);
      })
  }

  getDocument(attachmentStorageId: string) {
    const reader = new FileReader();
    if (attachmentStorageId) {
      this.showLoading = true;
      this.documentService.getDocument(attachmentStorageId,this.appSettings.storageServiceBaseUrl,this.appSettings.productId).subscribe(data => {
        switch (data.type) {
          case HttpEventType.Response:
            if (data.body && data.body.type) {
              this.selectDocUrl = data.url!;
              let objectUrl = window.URL.createObjectURL(data.body);
              if (data.body) {
                reader.readAsBinaryString(data.body);
                reader.onloadend = () => {
                  let count = reader.result?.toString().match(/\/Type[\s]*\/Page[^s]/g)?.length;
                  if (count) {
                    this.pdfPageCount = count;
                    this.updateDocStatus(attachmentStorageId, 'inprogress',null, this.selectedPdfName, 0, false, false);
                  } else {
                    this.pdfPageCount = -1;
                  }
                }
              }
              this.pdfSrc = this._sanitizer.bypassSecurityTrustResourceUrl(`assets/web/viewer.html?file=${objectUrl}`);
              
              this.showLoading = false;
            }
            break;
          case undefined: {
            //this.showLoading = false;
          }
        }
      }, error => {
        this.showLoading = false;
        console.log(error);
      })
    }
  }

  addMorePages() {
    let x = 1;
    this.pdfs.forEach(p => {
      if (x < +p.To) {
        x = +p.To;
      }
    })
    if (x < this.pdfPageCount || this.pdfPageCount == -1) {
      this.pdfs.push({ page: x.toString(), From: +x + 1, To: +x + 1, Title: `Pages ${+x + 1}-${+x + 1}` });
    }
    if (x == this.pdfPageCount) {
      this.showAdd = false;
    }
  }

  dialogRef!: MatDialogRef<DialogComponent, any>;
  openProcessSplitFax(item: any) {
    var userData = this.tokenSvc.getUserConfig();
    var userFullName = this.userData?.firstName + ' ' + this.userData?.lastName;

    this.formService.lockFax(this.getFaxId(item)).subscribe(l => {
      if (l){
        if (Number.isNaN(Number(l)) && userFullName.toLowerCase() !== l.toLowerCase()) {
          Swal.fire({
            title: 'Fax locked',
            text: `This fax is currently being edited by ${l}. Please try again later.`,
            icon: 'warning', //possible values - error/success/info/question
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#5890a3',
            cancelButtonColor: '#d22329',
            confirmButtonText: 'Ok'
          }).then((result) => {
            return;
          });
        } else {
          this.openFax(item);
        }
      }
    }); 
  }

  openFax(item: any){
    if (item.assignedToQueueId && item.assignedToQueueId > 0){
      this.faxQueue = item.assignedToQueueId;
      this.faxOwner = '';
    } else {
      this.faxQueue = 0;
      this.faxOwner = item.assignedToUser;
    }
    if (item.name) {
      this.selectedPdfName = item.name;
    } else {
      this.selectedPdfName = item.displayName;
    }
    this.processSplitFaxForm.controls['renameFax'].setValue(item.name);
    if (item.internalId) {
      this.selectedPdfId = item.internalId;
    } else {
      this.selectedPdfId = item.internalDocumentId;
    }
    this.childFax = !item.isParent;
    this.selectedPdfItem = item;

    this.getDocument(this.selectedPdfId);
    var title = `Process Fax - ${this.selectedPdfName}`
    if (item.p8DocName) {
      title = `Process Fax - ${this.selectedPdfName} - ${item.p8DocName}`;
    }

    var userName = '';
    if (this.userData){
      userName = this.userData?.firstName + ' ' + this.userData?.lastName;
    } else {
      userName = this.user.profile.preferred_username;
    }
    this.signalR.broadcastMessage(`locked:${this.getFaxId(item)}:${userName}`);

    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '80vw',
      data: <IDialogConfig>{
        title: title,
        dialogContent: this.processSplitFaxTemplate,
        acceptButtonTitle2: 'Delete This Fax',
        declineButtonTitle: 'Close'
      },
      autoFocus: false,
      disableClose: true
    });

    this.dialogRef.componentInstance.clickAcceptButton2.subscribe(result => {
      var user = this.tokenSvc.getUser();
      Swal.fire({
        title: 'Remove Fax?',
        text: `Are you sure you want to remove ${item.name} fax?`,
        icon: 'warning', //possible values - error/success/info/question
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#5890a3',
        cancelButtonColor: '#d22329',
        confirmButtonText: 'Remove'
      }).then((result) => {
        if (result.value) {
          if (!this.pdfPageCount) {
            this.pdfPageCount = -1;
          }
          if (item.internalId) {
            this.documentService.updateFaxStatus(item.internalId, -1, this.pdfPageCount, null, this.selectedPdfName, 0, false, this.appSettings.storageServiceBaseUrl,user.profile.preferred_username, false);
            this.formService.unlockFax(item.internalId);
            this.signalR.broadcastMessage(`unlocked:${item.internalId}`)
          } else {
            this.documentService.updateFaxStatus(item.internalDocumentId, -1, this.pdfPageCount, null, this.selectedPdfName, 0, false,this.appSettings.storageServiceBaseUrl,user.profile.preferred_username, false);
            this.formService.unlockFax(item.internalDocumentId);
            this.signalR.broadcastMessage(`unlocked:${item.internalDocumentId}`)
          }
          this.dialogRef.close();
          this.showRenameBtn = false;
          this.showSuccessAlert = false;
        }
      });
    })

    this.dialogRef.componentInstance.clickDeclineButton.subscribe(e => {
      this.pdfs = [];
      this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
      this.formService.unlockFax(this.getFaxId(item));
      this.signalR.broadcastMessage(`unlocked:${this.getFaxId(item)}`)
      this.dialogRef.close();
      this.showRenameBtn = false;
      this.showSuccessAlert = false;
    });
  }

  openViewSplitFax(item: any) {
    this.viewId = item.internalDocumentId;
    this.viewAuthNumber = item.authNumber;
    this.viewReceived = item.createdDate;
    this.viewSentFrom = item.sentFrom  
    this.viewStatus = item.status
    this.viewWorkQueue = item.workQueue  
    this.viewDisplayName = item.displayName;      
    this.viewp8DocName = item.p8DocName; 
    
    this.childFax = !item.isParent;    
    // this.selectedPdfItem = item;
   
    this.getDocument(item.internalDocumentId);
    this.dialogRef = this.dialog.open(DialogComponent, {      
      width: '75vw',
      data: <IDialogConfig>{        
        title: `View Fax - ${ item.displayName } - ${ item.p8DocName }`,
        dialogContent: this.processSplitFaxTemplate,
        acceptButtonTitle2: 'Delete This Fax',
        declineButtonTitle: 'Cancel'
      },
      autoFocus: false,
      disableClose: true
    });
    var user = this.tokenSvc.getUser();
    this.dialogRef.componentInstance.clickAcceptButton2.subscribe(result => {
      const confirmRef = this.confirmDialog.open({
        title: 'Remove Fax?',
        message: `Are you sure you want to remove ${item.name} fax?`,
        acceptButtonText: 'OK',
        declineButtonText: 'Cancel'
      });
      confirmRef.componentInstance.clickAcceptButton1.subscribe(r => {
        if (!this.pdfPageCount) {
          this.pdfPageCount = -1;
        }
        if (item.internalId) {
          this.documentService.updateFaxStatus(item.internalId, -1, this.pdfPageCount, null, this.viewDisplayName, 0, false,this.appSettings.storageServiceBaseUrl,user.profile.preferred_username, false);
          this.formService.unlockFax(item.internalId);
          this.signalR.broadcastMessage(`unlocked:${item.internalId}`)
        } else {
          this.documentService.updateFaxStatus(item.internalDocumentId, -1, this.pdfPageCount, null, this.viewDisplayName, 0, false,this.appSettings.storageServiceBaseUrl,user.profile.preferred_username, false);
          this.formService.unlockFax(item.internalDocumentId);
          this.signalR.broadcastMessage(`unlocked:${item.internalDocumentId}`)
        }
        confirmRef.close();
        //this.documentService.closeDoc(true);
        this.dialogRef.close();
      })
    })

    this.dialogRef.componentInstance.clickDeclineButton.subscribe(e => {
      this.pdfs = [];
      this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
      this.dialogRef.close();
    });
  }

  async authSelected(event: any) {
    if (event) {
      this.memberIdSelected = false;
      this.authIdSelected = true;
      this.selectedAuth = event.authNumber;
      this.selectedItem = event;
      await this.GetAuthDetails();
      this.authDetailId = this.authDetailId;
      await this.IsConcAuthEligible();
    }
  }

  memberSelected(event: any) {
    if (event) {
      this.memberIdSelected = true;
      this.authIdSelected = false;
      this.mvdid = event;
    }
  }

  async IsConcAuthEligible() {
    const authDetailNumber = this.selectedAuth.split('-')[0];
    const authNumber = this.selectedAuth.split('-')[1];
    const result = await this.formService.isAuthConcurrentEligible(authDetailNumber, +authNumber);
    this.sub.add(result.subscribe(r => {
      if (r && r.data !== undefined) {
        this.concAuthElig.next(r.data);
      }
    }));
  }

  async GetAuthDetails() {
    const authDetailNumber = this.selectedAuth.split('-')[0];
    const authNumber = this.selectedAuth.split('-')[1];
    const result = await this.formService.getAuthDetails(authDetailNumber, +authNumber);
    this.sub.add(result.subscribe(r => {
      if (r && r.data !== undefined) {
        this.authDetailId = r.data;
      }
    }));
  }

  splitFax() {
    this.validSplitPages();
    if (this.validPages) {
      let splitInfo: DocSplitInfo[] = [];
      this.pdfs.forEach(pdf => {
        let docInfo = new DocSplitInfo();
        docInfo.newDocumentName = pdf.Title;
        if (pdf.From == pdf.To) {
          docInfo.pageNumbers = [];
          docInfo.pageNumbers.push(+pdf.From);
        } else {
          docInfo.pageNumbers = [];
          docInfo.pageNumbers.push(+pdf.From);
          let from = +pdf.From;
          for (var x = from + 1; x <= +pdf.To; x++) {
            docInfo.pageNumbers.push(x);
          }
        }
        splitInfo.push(docInfo);
      })
      let valueId = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'inprogress')?.lookupValueID
      if (valueId) {
        this.statusId = valueId;
        //Set the parent doc status to Document Split
        this.updateDocStatus(this.selectedPdfId, 'split', null, this.selectedPdfName, 0, false, false);
      }
      var user = this.tokenSvc.getUser();
      if (splitInfo.length > 0 && this.statusId) {
        //Set the child doc status to In Progress
        this.documentService.splitFax(this.selectedPdfId, splitInfo, this.faxQueue, this.faxOwner, this.statusId,this.appSettings.storageServiceBaseUrl,this.appSettings.customerId,this.appSettings.productId,user.profile.preferred_username);
      }
      this.pdfs = [];
      this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
      this.formService.unlockFax(this.selectedPdfId);
      this.signalR.broadcastMessage(`unlocked:${this.selectedPdfId}`)
      
      this.dialogRef.close();
    } 
  }

  validSplitPages() {
    if (!this.pdfPageCount || this.pdfPageCount <= 0) {
      this.validPages = true;
      return;
    }
    this.pdfs.forEach(p => {
      if (p.From <= p.To && p.To <= this.pdfPageCount && p.From <= this.pdfPageCount) {
        this.validPages = true;
      } else {
        this.validPages = false;
        return;
      }
    })
    if (!this.validPages) {
      Swal.fire({
        title: 'ERROR',
        text: `Starting and ending page numbers must be within the total page count.`,
        icon: 'error', //possible values - error/success/info/question
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#5890a3',
        cancelButtonColor: '#d22329',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {

        }
      });
    }
  }

  createNewAuth() {
    var docName = this.selectedPdfName;
    if (this.processSplitFaxForm.controls['renameFax'].value && this.processSplitFaxForm.controls['renameFax'].value.trim() !== this.selectedPdfName.trim()) {
      docName = this.processSplitFaxForm.controls['renameFax'].value.trim();
    }
    if (this.mvdid) {
      let attachType = this.lookupData.find(l => l.lookupSetID == 121 && l.lookupStandardValue.toLowerCase() == 'faxattachment')?.lookupValueID;
      if (attachType) {
        let newAttachment = new NewAuthAttachment();
        newAttachment.docStorageId = this.selectedPdfId;
        newAttachment.docDisplayName = docName;
        newAttachment.docType = attachType;
        newAttachment.docUrl = this.selectDocUrl;
        this.formService.NewAuthAttachment = newAttachment;
      }
      this.updateDocStatus(this.selectedPdfId, 'processed', null, docName, 0, false, false);
      this.formService.AttachmentDate = this.selectedPdfItem.date;
      this.router.navigate(["/member/authorization-intake/".concat(this.mvdid, "/new")], { skipLocationChange: true });
    }
    this.pdfs = [];
    this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
    this.formService.unlockFax(this.selectedPdfId);
    this.signalR.broadcastMessage(`unlocked:${this.selectedPdfId}`)
    //this.documentService.closeDoc(true);
    this.dialogRef.close();
  }

  createConcurrentAuth() {
    var docName = this.selectedPdfName;
    if (this.processSplitFaxForm.controls['renameFax'].value.trim() !== this.selectedPdfName.trim()) {
      docName = this.processSplitFaxForm.controls['renameFax'].value.trim();
    }
    if (this.selectedAuth) {
      let attachType = this.lookupData.find(l => l.lookupSetID == 121 && l.lookupStandardValue.toLowerCase() == 'faxattachment')?.lookupValueID;
      if (attachType) {
        let newAttachment = new NewAuthAttachment();
        newAttachment.docStorageId = this.selectedPdfId;
        newAttachment.docDisplayName = docName;
        newAttachment.docType = attachType;
        newAttachment.docUrl = this.selectDocUrl;
        this.formService.NewAuthAttachment = newAttachment;
      }
      this.updateDocStatus(this.selectedPdfId, 'processed', null, docName, 0, false, false);
      this.formService.AttachmentDate = this.selectedPdfItem.date;
      this.router.navigate([`/member/authorization-intake/${this.selectedItem.authIntakeID}/0000`], { skipLocationChange: true });
    }
    this.pdfs = [];
    this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
    this.formService.unlockFax(this.selectedPdfId);
    this.signalR.broadcastMessage(`unlocked:${this.selectedPdfId}`)
    //this.documentService.closeDoc(true);
    this.dialogRef.close();
  }

  attachToAuth() {
    var docName = this.selectedPdfName;
    if (this.processSplitFaxForm.controls['renameFax'].value.trim() !== this.selectedPdfName.trim()) {
      docName = this.processSplitFaxForm.controls['renameFax'].value.trim();
    }
    let attachType = this.lookupData.find(l => l.lookupSetID == 121 && l.lookupStandardValue.toLowerCase() == 'faxattachment')?.lookupValueID;
    if (attachType) {
      this.formService.attachFax(this.selectedAuth.split('-')[1], this.selectedAuth.split('-')[0], this.selectedPdfId, docName, attachType, this.selectDocUrl);
      this.updateDocStatus(this.selectedPdfId, 'processed', this.selectedAuth, docName, this.authDetailId, true, false);
    }
    this.pdfs = [];
    this.pdfs.push({ page: "1", From: 1, To: 2, Title: 'Pages 1-2' });
    this.formService.unlockFax(this.selectedPdfId);
    this.signalR.broadcastMessage(`unlocked:${this.selectedPdfId}`)
    //this.documentService.closeDoc(true);
    this.dialogRef.close();
  }

  updateDocStatus(docId: string, status: string, authNumber: string|null, newDocName: string, authDetailID: number, updateP8: boolean, triggerRefresh: boolean) {
    let valueId = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == status && l.lookupSetID == 145)?.lookupValueID

    if (!valueId) {
      valueId = this.statusId;
    }
    if (!this.pdfPageCount)
    {
      this.pdfPageCount = 1;
    }
    var user = this.tokenSvc.getUser();
    this.documentService.updateFaxStatus(docId, valueId, this.pdfPageCount, authNumber, newDocName, authDetailID, updateP8,this.appSettings.storageServiceBaseUrl,user.profile.preferred_username, triggerRefresh);
  }

  getFaxId(item: any) {
    if (item.internalId !== undefined) return item.internalId;
    if (item.internalDocumentId !== undefined) return item.internalDocumentId;
    return undefined;
  }

  faxReferred(event: string | number){
    let queueId: number = 0;
    let owner: string = '';
    if (isNaN(Number(event))){
      owner = event.toString();
    } else {
      queueId = +event;
    }
    var user = this.tokenSvc.getUser();
    this.documentService.referFax(this.selectedPdfId, queueId, owner, user.profile.preferred_username, this.appSettings.storageServiceBaseUrl);
    this.formService.unlockFax(this.selectedPdfId);
    this.dialogRef.close();
  }
  authSearchResults: MemberAuthData[] = [];
}
