<section class="snapshot-container">
  <div data-layout="row wrap" class="snapshot-item">
    <button data-layout="column" class="snapshot-button" *ngFor="let filterButton of filterButtons; let i = index;" [ngClass]="active === i ? 'selected' : ''" (click)="active = i; filterMyQueue()">
      <div data-layout="row" data-layout-align="start center" data-layout-gap="0.5em">
        <mat-icon class="material-icons-outlined icon" aria-hidden="true" [ngClass]="filterButton.option=='Overdue Authorizations' ? 'warning' : ''">{{ filterButton.icon }}</mat-icon>
        <div class="count" [ngClass]="filterButton.option=='Overdue Authorizations' ? 'warning' : ''">{{ filterButton.count }}</div>
      </div>
      <div class="text" [ngClass]="filterButton.option=='Overdue Authorizations' ? 'warning' : ''">{{ filterButton.option }}</div>
    </button>
  </div>
</section>
