// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  currentEnvironment: 'Production',
  formServiceBaseUrl: "https://app-formssvc-dev.azurewebsites.net/api/",
  lookupServiceBaseUrl: "https://app-lookupsvc-dev.azurewebsites.net/api/",
  memberServiceBaseUrl: "https://app-membersvc-dev.azurewebsites.net/api/",
  autoSaveIntervalSeconds: 30,
  clientRoot: "https://localhost:44379",
  idpAuthority: "https://app-identitysvc-dev.azurewebsites.net/",
  clientId: "mvc-client",
  redirectUri: "https://localhost:44379/signin-callback",
  postLogoutRedirectUri: "https://localhost:44379/signout-callback",
  customerId: 16,
  productId: 5,
  closedAndApproved: 10161,
  closedAndDenied: 10162,
  closedAndPartiallyDenied: 10163,
  appInsightsConfig: {
    connectionString: ''
  },
  appVersion: require('../../../../../AffiniteSweets/package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
