import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    public DueDateResetEvent = new EventEmitter();
    public TimeZoneEvent = new EventEmitter();
    public RequestTypeChangedEvent = new EventEmitter();
    public ProcedureAddedEvent = new EventEmitter();
    public AuthSavedEvent = new EventEmitter();
    public AuthClosedEvent = new EventEmitter();
    public SaveButtonStateEvent = new EventEmitter<boolean>();

    public DueDateReset(){
        this.DueDateResetEvent.emit();
    }

    public TimeZone(){
        this.TimeZoneEvent.emit();
    }

    public RequestTypeChanged(){
        this.RequestTypeChangedEvent.emit();
    }

    public ProcedureAdded(){
        this.ProcedureAddedEvent.emit();
    }

    public AuthSaved(){
        this.AuthSavedEvent.emit();
    }

    public AuthClosed(){
        this.AuthClosedEvent.emit();
    }

    SaveButtonState(isEnabled: boolean){
        this.SaveButtonStateEvent.emit(isEnabled);
    }
}