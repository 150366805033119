<!-- dialog content: Filter Authorizations -->
<ng-template #filterAuthDialogTemplate>
  <form [formGroup]="authFilterGroup">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Due Date Range</mat-label>
      <mat-date-range-input [formGroup]="authFilterGroup"
                            [rangePicker]="dateRangeAuthTeamQueuePicker"
                            cdkFocusInitial>
        <input matStartDate placeholder="Start date" formControlName="dueDateStart">
        <input matEndDate placeholder="End date" formControlName="dueDateEnd">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangeAuthTeamQueuePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangeAuthTeamQueuePicker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Type</mat-label>
      <mat-select formControlName="authTypeId"
                  (openedChange)="filterAuthType.value=''">
        <input matInput placeholder="Search" #filterAuthType> 
        <div *ngFor="let a of authType">
          <mat-option [value]="a.lookupValueID" *ngIf="a.lookupStandardLabel.toLowerCase().includes((filterAuthType.value).toLowerCase())">{{ a.lookupStandardLabel }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Priority</mat-label>
      <mat-select formControlName="urgencyID"
                  (openedChange)="filterAuthPriority.value=''">
        <input matInput placeholder="Search" #filterAuthPriority>
        <div *ngFor="let u of urgency">
          <mat-option [value]="u.lookupValueID" *ngIf="u.lookupStandardLabel.toLowerCase().includes((filterAuthPriority.value).toLowerCase())">{{ u.lookupStandardLabel }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select formControlName="authStatusID"
                  (openedChange)="filterAuthStatus.value=''">
        <input matInput placeholder="Search" #filterAuthStatus>
        <div *ngFor="let s of authStatus">
          <mat-option [value]="s.lookupValueID" *ngIf="s.lookupStandardLabel.toLowerCase().includes((filterAuthStatus.value).toLowerCase())">{{ s.lookupStandardLabel }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Provider</mat-label>
      <mat-select formControlName="srvcProvider"
                  (openedChange)="filterAuthProvider.value=''">
        <input matInput placeholder="Search" #filterAuthProvider>
        <div *ngFor="let P of providerList">
          <mat-option [value]="P" *ngIf="P.toLowerCase().includes((filterAuthProvider.value).toLowerCase())">{{ P }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>LOB</mat-label>
      <mat-select formControlName="lob"
                  (openedChange)="filterAuthLob.value=''">
        <input matInput placeholder="Search" #filterAuthLob>
        <div *ngFor="let l of lobList">
          <mat-option [value]="l" *ngIf="l.toLowerCase().includes((filterAuthLob.value).toLowerCase())">{{ l }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill" *ngIf="filterType !== 'my-queue-auths'">
      <mat-label>Work Queue</mat-label>
      <mat-select formControlName="authGroupId"
                  (openedChange)="filterAuthWorkQ.value=''">
        <input matInput placeholder="Search" #filterAuthWorkQ>
        <div *ngFor="let q of queueList">
          <mat-option [value]="q.queueId" *ngIf="q.queueName.toLowerCase().includes((filterAuthWorkQ.value).toLowerCase())">{{ q.queueName }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>CM Org Region</mat-label>
      <mat-select formControlName="cmOrgRegion"
                  (openedChange)="filterOrgRegion.value=''">
        <input matInput placeholder="Search" #filterOrgRegion>
        <div *ngFor="let o of orgRegionList">
          <mat-option [value]="o" *ngIf="o.toLowerCase().includes((filterOrgRegion.value).toLowerCase())">{{ o }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Request Type</mat-label>
      <mat-select formControlName="requestType"
                  (openedChange)="filterReqType.value=''">
        <input matInput placeholder="Search" #filterReqType>
        <div *ngFor="let r of reqTypeList">
          <mat-option [value]="r" *ngIf="r.toLowerCase().includes((filterReqType.value).toLowerCase())">{{ r }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>State</mat-label>
      <mat-select formControlName="srvcState"
                  (openedChange)="filterState.value=''">
        <input matInput placeholder="Search" #filterState>
        <div *ngFor="let s of stateList">
          <mat-option [value]="s" *ngIf="s.toLowerCase().includes((filterState.value).toLowerCase())">{{ s }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Place of Service</mat-label>
      <mat-select formControlName="placeofService"
                  (openedChange)="filterPlace.value=''">
        <input matInput placeholder="Search" #filterPlace>
        <div *ngFor="let s of placeofServiceList">
          <mat-option [value]="s" *ngIf="s.toLowerCase().includes((filterPlace.value).toLowerCase())">{{ s }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill" *ngIf="isManager && filterType == 'my-queue-auths'">
      <mat-label>My Direct Reports</mat-label>
      <mat-select formControlName="authOwner"
                  (openedChange)="filterDirectReports.value=''"
                  multiple>
        <input matInput placeholder="Search" #filterDirectReports>
        <div *ngFor="let s of directReports | async">
          <mat-option [value]="s.userName" *ngIf="s.userName.toLowerCase().includes((filterDirectReports.value).toLowerCase())">{{ s.firstName + ' ' + s.lastName }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>
    <hr />

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Member ID</mat-label>
      <input matInput value="" formControlName="memberId">
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Authorization #</mat-label>
      <input matInput value="" formControlName="authNumber">
    </mat-form-field>

  </form>
</ng-template>