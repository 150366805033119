export class InterqualSettings {
  interqualSettingId!: number;
  environment!: string;
  samlEndpoint!: string;
  samlFacilityId!: string;
  sendPHI!: boolean;
  sendServiceInfo!: boolean;
  createdDate!: string;
  updatedDate!: string;
  createdBy!: string;
  updatedBy!: string;
}
