import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { AppSettings } from "../shared/appsettings";
import { TokenStorageService } from "./token-storage.service";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class InterqualService {
  identityBaseUrl: string = '';
  private user: any;
  private userConfig: any;
  public SsoSaml = new BehaviorSubject<string>('');
  public LoginUrl = new Subject<string>();

  constructor(private http: HttpClient, private config: AppSettings, private tokenService: TokenStorageService) {
    this.identityBaseUrl = this.config.samlEndpoint;
    this.user = this.tokenService.getUser();
  }

  getSsoSaml(): void {
    this.userConfig = this.tokenService.getUserConfig();
    let queryParams = new HttpParams();
    queryParams = queryParams.append("partnerName", "Interqual");
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("firstName", this.userConfig.firstName);
    queryParams = queryParams.append("lastName", this.userConfig.lastName);
    let identityUrl = this.identityBaseUrl + "Auth/Initiate";
    this.http.get(identityUrl, { params: queryParams, responseType: 'text' }).subscribe(
      data => {
        console.log("GetSsoSaml");
        if (data) {
          this.SsoSaml.next(data);
        }
      }
    );
  }

  loginInterqual(): void {
    this.userConfig = this.tokenService.getUserConfig();
    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("firstName", this.userConfig.firstName);
    queryParams = queryParams.append("lastName", this.userConfig.lastName);
    let identityUrl = this.identityBaseUrl + "Auth/LoginInterqual";
    this.http.get(identityUrl, { params: queryParams, responseType: 'text' }).subscribe(
      data => {
        console.log("LoginInterqual");
        if (data) {
          this.LoginUrl.next(data);
        }
      }
    );
  }
}
