import { Component, OnInit } from '@angular/core';
import { MemberAuthData } from '../../shared/components/auth-search/MemberAuthData';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent implements OnInit {

  constructor() { }

  viewAllAuth() {
    alert('Take me to Authorization History of this member')
  }

  viewAuth() {
    alert('Take me to this auth')
  }

  authSearchResults: MemberAuthData[] = [
    {
      memberName: 'Smith, Andrew',
      memberId: '001-2044768',
      dob: '10/22/1985',
      address1: '85447 Green Clover Ln',
      address2: 'Unit 3',
      city: 'Riverside',
      state: 'CA',
      zip: '91522',
      auth: [
        {
          authNumber: '001-8191522',
          authStatus: 'New',
          authType: 'Inpatient',
          startDate: '10/22/2021',
          provider: 'Julie Wong, MD',
          diagnosis: 'M51 Thoracic, thoracolumbar, and lumbosacral intervertebral disc disorders',
          procedure: '63001 Under Posterior Extradural Laminotomy or Laminectomy for Exploration/ Decompression of Neural Elements or Excision of Herniated Intervertebral Disks Procedures'
        },
        {
          authNumber: '002-8191522',
          authStatus: 'New',
          authType: 'Inpatient',
          startDate: '10/22/2021',
          provider: 'Julie Wong, MD',
          diagnosis: 'M96 Intraoperative and postprocedural complications and disorders of musculoskeletal system, not elsewhere classified',
          procedure: '62380 Under Endoscopic Decompression of Neural Elements and/or Excision of Herniated Intervertebral Discs'
        },
        {
          authNumber: '002-8191522',
          authStatus: 'New',
          authType: 'Inpatient',
          startDate: '10/22/2021',
          provider: 'Julie Wong, MD',
          diagnosis: 'M96 Intraoperative and postprocedural complications and disorders of musculoskeletal system, not elsewhere classified',
          procedure: '62380 Under Endoscopic Decompression of Neural Elements and/or Excision of Herniated Intervertebral Discs'
        }
      ] as any,
      mvdid: '',
      lobName: 'Health Advantage'
    },

  ];

  ngOnInit(): void {
  }

}
