<div class="queue-utilities" data-layout="row" data-layout-align="space-between center">
  <mat-checkbox title="Select or Deselect All" aria-label="Select or Deselect All" [(ngModel)]="checked" (click)="selectOrDeselectAll()"></mat-checkbox>
  <!--Refer button becomes visible when any of the checkboxes are selected. Otherwise hidden.-->
  <button mat-flat-button color="vdt-primary" (click)="dialogAuth.openReferAuthDialog(false, healthPlanEmployeeAuths)" [disabled]="selectedAuths.length == 0">Refer: {{ selectedAuths.length }}</button>
  <div data-layout="row" data-layout-align="space-between center">
    <span class="count">{{ countMessage }}</span>
    <app-refresh (iconClick)="refreshQueue()" [title]="refreshTitleText"></app-refresh>
  </div>
  <button mat-icon-button title="Filter queue" (click)="openFilterDialog()" matTooltipClass="multiline-tooltip"   matTooltip="{{filterBadgeData.text}}">
    <mat-icon matBadge={{filterBadgeData.count}}
              matBadgeColor="warn"
              matBadgeSize="small"
              [matBadgeHidden]="filterBadgeData.count < 1"
              class="material-icons-outlined icon-dark"
              aria-hidden="false"
              aria-label="Filter queue"
              >
      filter_list
    </mat-icon>
  </button>
  <span class="cdk-visually-hidden">
    Notification icon with overlaid badge showing the number 2
  </span>
</div>

<cdk-virtual-scroll-viewport name="teamAuths" itemSize="259.484" style="height: calc(100vh - 250px)"> <!-- style="height: calc(100vh - 250px)"> -->
  <div *cdkVirtualFor="let queue of allAuthsListByGroup">
    <div *ngIf="!queue.hidden">
      <app-auth-queue [queue]="queue"
                      [isSelectable]="true"
                      [toolTipList]="procCodeDescList"
                      [timezone]="timezone"
                      (authAction)="authSelectedToggle($event)">
      </app-auth-queue>
    </div>
  </div>
</cdk-virtual-scroll-viewport>

<app-dialog-auth #dialogAuth
                 (referDialogEvent)="referDialogEvent($event)">
</app-dialog-auth>

<app-auth-filter [openDialog]="openDialogEvent"
                 [auths]="unfilteredAuthListByGroup"
                 (dialogEvent)="dialogEvent()"
                 (displayCountEvent)="displayCountUpdate($event)"
                 [filterType]="filterType">
</app-auth-filter>