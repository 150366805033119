import { Injectable } from "@angular/core";
import { LookupService } from "./lookup.service";
import { LookupValue } from "../models/Lookups/lookupValue";
import { InboundDocument } from "../models/storage/inboundDocument";
import { FaxQueueItem } from "../models/fax/faxQueueItem";
import { ConfigService } from "./config-service.service";
import { Queue } from "../models/configuration/queue";
import { AutoUnsubscribe } from "../shared/decorators/auto-unsubscribe.decorator";

@AutoUnsubscribe

@Injectable({
    providedIn: 'root'
})

export class FaxHelperService {
    lookupData!: LookupValue[];
    queues!: FaxQueueItem[];
    faxes!: InboundDocument[];
    queueCounts!: {
        [queueId: string]: number;
    }

    constructor(private lookupSvc: LookupService){
        this.lookupSvc.LookupData.subscribe(l => {
            if (l && l.length > 0) {
                this.lookupData = l;
            }
        })
    }

    populateFaxQueue(faxList: InboundDocument[]) : FaxQueueItem[] {
        if (!faxList) {
          return [];
        }
        this.faxes = faxList;
        this.queues = [];
        //Get all parent faxes and then attach the split documents are children to the list so we can display correctly.
        let statusId = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'processed' && l.lookupSetID == 145)?.lookupValueID
        let parents = this.faxes.filter(f => f.internalDocumentId == f.parentDocumentId && f.statusId !== statusId);
        parents.forEach(p => {
          let q = {
            name: p.displayName,
            date: p.createdDate,
            split: this.getChildren(p.internalDocumentId),
            internalId: p.internalDocumentId,
            status: p.docStatus,
            color: this.getColor(p),
            isParent: p.internalDocumentId == p.parentDocumentId,
            p8DocName: p.p8DocName,
            assignedToQueueId: p.assignedToQueueId ?? 0,
            assignedToUser: p.assignedToUser,
            locked: p.locked,
            lockedBy: p.lockedBy,
            lockedByUserName: p.lockedByUserName,
            lockDate: p.lockDate
          }
          this.queues.push(q);
        })
        //We need to account for a split fax that gets assigned to a user while the parent is still assigned to a queue.
        let orphans = this.faxes.filter(f => f.internalDocumentId !== f.parentDocumentId && f.statusId !== statusId);
        
        orphans.forEach(p => {
          var parents = this.queues.find(q => q.internalId == p.internalDocumentId);
          //Check to see if the record is already included as a child in one of the parents
          var childs:FaxQueueItem | undefined = undefined;
          for (var parent of this.queues) {
            childs =  parent.split.find(q => q.internalId == p.internalDocumentId)
            if (childs){
              break;
            }
          }
          if (!childs && !parents){
            let q = {
              name: p.displayName,
              date: p.createdDate,
              split: this.getChildren(p.internalDocumentId),
              internalId: p.internalDocumentId,
              status: p.docStatus,
              color: this.getColor(p),
              isParent: p.internalDocumentId == p.parentDocumentId,
              p8DocName: p.p8DocName,
              assignedToQueueId: p.assignedToQueueId ?? 0,
              assignedToUser: p.assignedToUser,
              locked: p.locked,
              lockedBy: p.lockedBy,
              lockedByUserName: p.lockedByUserName,
              lockDate: p.lockDate
            }
            this.queues.push(q);
          }
        })
    
        this.queues = this.queues.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        return this.queues;
    }
    
    getChildren(parentId: string): any[] {
        let children: any[] = [];
        let childs = this.faxes.filter(f => f.parentDocumentId == parentId && f.parentDocumentId !== f.internalDocumentId);
        {
            childs.forEach(c => {
            let child = {
                name: c.displayName,
                date: c.createdDate,
                internalId: c.internalDocumentId,
                status: c.docStatus,
                color: this.getColor(c),
                isParent: false,
                assignedToQueueId: c.assignedToQueueId,
                locked: c.locked,
                lockedBy: c.lockedBy,
                lockedByUserName: c.lockedByUserName,
                lockDate: c.lockDate
            }
            children.push(child);
            })
        }
        return children;
    }
    
    getColor(doc: InboundDocument) {
        let createdDate = '';
        if (doc.internalDocumentId !== doc.parentDocumentId) {
          const parent = this.faxes.find(p => p.internalDocumentId == doc.parentDocumentId);
          if (parent) {
            createdDate = parent.createdDate;
          }
        } else {
          createdDate = doc.createdDate;
        }
        const age = Math.floor((new Date().getTime() - new Date(createdDate).getTime()) / (1000 * 3600));
        if (age > 24 && (!doc.docStatus || doc.docStatus.toLowerCase() !== 'processed')) {
          return 'text-warning';
        }
        if (!doc.docStatus) {
          return '';
        }
        switch (doc.docStatus.toLowerCase()) {
          case 'processed':
            return 'text-info';
          default:
            return '';
        }
    }

    populateFaxQueueCounts(faxQueues: Queue[]):Queue[] {
        this.queueCounts = {};
        if (this.queues) {
          this.queues.forEach(q => {
            if (q.assignedToQueueId && this.queueCounts[q.assignedToQueueId]) {
              this.queueCounts[q.assignedToQueueId] += 1;
            } else if (q.assignedToQueueId) {
              this.queueCounts[q.assignedToQueueId] = 1;
            }
            if (q.split && q.split.length > 0) {
              q.split.forEach(s => {
                if (s.assignedToQueueId && this.queueCounts[s.assignedToQueueId]) {
                  this.queueCounts[s.assignedToQueueId] += 1;
                } else if (s.assignedToQueueId) {
                  this.queueCounts[s.assignedToQueueId] = 1;
                }
              })
            }
          })
        }
        faxQueues.forEach(q => {
          if (this.queueCounts[q.queueId]) {
            if (q.queueName.includes('(')) {
              q.queueName = q.queueName.split('(')[0].trim();
            }
            q.queueName = q.queueName + ' (' + this.queueCounts[q.queueId] + ')';
          }
        })
        return faxQueues;
    }
}