export class BlobMetaData {
    file!: FormData;
    productId!: number;
    customerId!: number;
    docType!: string;
    docSource!: string;
    docName!: string;
    displayName!: string;
    tags!: {};
    parentDocName!: string;
    userName!: string;
    storageAccount!: string;
    authIntakeId!: number;
    authDetailId!: number;
    updateP8!: boolean;
  }
  