import { Injectable, OnDestroy } from '@angular/core';
import { LookupValue } from '../models/Lookups/lookupValue';
import { LookupService } from './lookup.service';
import { AuthIntakeForm } from '../models/authForm/authIntakeForm';
import { AuthStatusReasonMap } from '../models/authView/authStatusReasonMap';
import { Subscription } from 'rxjs';
import { FormService } from './form-service.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessRulesService implements OnDestroy {
  lookupData!: LookupValue[];
  subscriptions!: Subscription;

  constructor(private lookupService: LookupService) {
    this.subscriptions = this.lookupService.LookupData.subscribe(l => {
      if (l && l.length > 0) {
        this.lookupData = l;
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  getLookupValueIds(lookupValue: string): LookupValue[] {
    return this.lookupData.filter(l => l.lookupStandardValue.toLowerCase() == lookupValue.toLowerCase());
  }

  // determineAuthStatus(authForm: AuthIntakeForm, statusMap: AuthStatusReasonMap[]): AuthIntakeForm {
  //   let status = this.getAuthStatus(authForm);
  //   let closed = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'closed')?.lookupValueID;
  //   let approved = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'approved' && l.lookupSetID == 128)?.lookupValueID;
  //   let deniedStatus = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'denied' && l.lookupSetID == 128)?.lookupValueID;
  //   let partiallydenied = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'partiallydenied' && l.lookupSetID == 128)?.lookupValueID;
  //   let voidStatus = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'void')?.lookupValueID;

  //   if (status.lineStatus == 'Approved') {
  //     authForm.authIntakeDetail[0].currentStatus = closed??10073;
  //     authForm.authIntakeDetail[0].currentStatusReasonId = approved??10098; //Approved
  //   } else if (status.lineStatus == 'Denied') {
  //     authForm.authIntakeDetail[0].currentStatus = closed??10073;
  //     authForm.authIntakeDetail[0].currentStatusReasonId = deniedStatus??10102; //Denied
  //   } else if (status.lineStatus == 'Partially Denied') {
  //     authForm.authIntakeDetail[0].currentStatus = closed??10073;
  //     authForm.authIntakeDetail[0].currentStatusReasonId = partiallydenied??10103; //Partially Denied
  //   } else if (status.lineStatus == 'Voided') {
  //     authForm.authIntakeDetail[0].currentStatus = voidStatus??10075;
  //     authForm.authIntakeDetail[0].currentStatusReasonId = this.getVoidReason(status.reviewOutcomeReason, statusMap);
  //   }
  //   return authForm;
  // }

  getVoidReason(reviewOutcomeReason: number, statusMap: AuthStatusReasonMap[]): number{
    const reason = this.lookupData.find(l => l.lookupValueID == reviewOutcomeReason);
    var lookupVal = '';
    
    var returnValue: number = reviewOutcomeReason;
    if (reason){
      if (reason.lookupStandardValue == 'AuthEntryError'){
        lookupVal = 'DataEntryError';
      } else if (reason.lookupStandardValue == 'AuthReferredtoOutsideVendor'){
        lookupVal = 'ReferredtoOutsideVendor';
      } else {
        lookupVal = reason.lookupStandardValue;
      }
      const lookups = this.lookupData.filter(l => l.lookupStandardValue == lookupVal);
      for(let lookup of lookups){
        const match = statusMap.find(s => s.authIntakeStatusReasonId == lookup.lookupValueID);
        if (match){
          returnValue = match.authIntakeStatusReasonId;
          break;
        }
      }
    }
    
    return returnValue;
  }

  getAuthStatus(authForm: AuthIntakeForm){
    let status: string[] = [];
    let procStatus: string[] = [];
    const denied = this.getLookupValueIds('denied').map(l => l.lookupValueID);
    const partiallyDenied = this.getLookupValueIds('partiallydenied').map(l => l.lookupValueID);
    const voided = this.getLookupValueIds('voided').map(l => l.lookupValueID);
    const withdrawn = this.getLookupValueIds('withdrawn').map(l => l.lookupValueID);
    const multipleReasons = this.getLookupValueIds('multiplereasons').map(l => l.lookupValueID);
    let approvedOutcomeIds = this.lookupData.filter(o => o.lookupStandardValue.toLowerCase() == 'approved' && o.lookupSetID == 122).map(v => v.lookupValueID);
    let reviewStatus = '';
    let lineStatus = '';
    let reviewOutcomeReason = 0;

    if (authForm.authIntakeReview !== undefined) {
      authForm.authIntakeReview.forEach(r => {
        if (r.reviewOutcome) {
          if (approvedOutcomeIds.includes(r.reviewOutcome) && r.obsolete == false) { //Aproved
            status.push('Approved');
          } else if (denied.includes(r.reviewOutcome) && r.obsolete == false) { //Denied
            status.push('Denied');
          } else if (partiallyDenied.includes(r.reviewOutcome) && r.obsolete == false) { //Partially Denied
            status.push('Partially Denied');
          } else if (voided && voided.includes(r.reviewOutcome) && r.obsolete == false) {
            status.push('Voided');
          } else if (withdrawn && withdrawn.includes(r.reviewOutcome) && r.obsolete == false) {
            status.push('Withdrawn');
          }
          reviewOutcomeReason = r.outcomeReason??0;
        }
      })
    }
    //Review status (Inpatient stay status) should always be just one line item so one value.
    if (status && status.length > 0){
      reviewStatus = status[0];
    }

    if (authForm.authIntakeStatus !== undefined) {
      authForm.authIntakeStatus.forEach(s => {
        if (s.status && approvedOutcomeIds.includes(s.status) && s.obsolete == false) { //Approved
          procStatus.push('Approved')
        } else if (s.status && denied.includes(s.status) && s.obsolete == false) { //Denied
          procStatus.push('Denied')
        } else if (s.status && partiallyDenied.includes(s.status) && s.obsolete == false) { //Partially Denied
          procStatus.push('Partially Denied');
        } else if (s.status && voided && voided.includes(s.status)) {
          procStatus.push('Voided');
        } else if (s.status && withdrawn && withdrawn.includes(s.status)) {
          procStatus.push('Withdrawn');
        }
        if (s.statusReason && (reviewStatus && s.statusReason !== reviewOutcomeReason)) {
          reviewOutcomeReason = multipleReasons[0];
        } else if (s.statusReason) {
          reviewOutcomeReason = s.statusReason;
        }
      })
    }
    reviewOutcomeReason = this.validateReason(reviewOutcomeReason);
    /**
     * Line Item status logic
     * 1. Only one line item
     *    a. Return line item status and reason as the auth status
     * 2. Multiple line items
     *    a. Only decisioned line items to be considered
     *      i. Ignore anything other than Approved/Denied/Partially Denied
     *      ii. Reason has been determined above
     */
    if (procStatus.length > 0){
      const decisionedLines = procStatus.filter(s => s.includes('Approved') || s.includes('Denied') || s.includes('Partially Denied'));
      if (decisionedLines && decisionedLines.length > 0) {
        if (!decisionedLines.includes('Denied') && !decisionedLines.includes('Partially Denied')) {
          lineStatus = 'Approved';
        } else if (!decisionedLines.includes('Approved') && !decisionedLines.includes('Partially Denied')) {
          lineStatus = 'Denied';
        } else {
          lineStatus = 'Partially Denied';
        }
      } else { //No line item with a valid decision. Set the status to Void/Withdrawn based on the line item status
        if (procStatus.includes('Voided')) {
          lineStatus = 'Voided';
        } else if (procStatus.includes('Withdrawn')) {
          lineStatus = 'Withdrawn';
        }
      }
    }

    if (lineStatus && reviewStatus){
      if (lineStatus == reviewStatus){
        return {lineStatus: lineStatus,reviewOutcomeReason};
      } else if (lineStatus == 'Voided' || lineStatus == 'Withdrawn') {
        return {lineStatus: reviewStatus,reviewOutcomeReason};
      }
      return {lineStatus:'Partially Denied',reviewOutcomeReason};
    } else if (lineStatus){
      return {lineStatus: lineStatus,reviewOutcomeReason};
    } else {
      return {lineStatus: reviewStatus,reviewOutcomeReason}
    }
  }

  validateReason(outcomeReason: number): number{
    const reasonVal = this.lookupData.find(l => l.lookupValueID == outcomeReason)?.lookupStandardValue;
    const reasons = this.lookupData.filter(l => l.lookupStandardValue == reasonVal);
    if (reasons && reasons.length == 1){
      //Only one lookup value. Return this. 
      return outcomeReason;
    } else {
      //Return the value for the AuthorizationStatusReason
      //We are only using 'Clinical Review Outcome' set values, but considering auths are also being created by 278, including both sets.
      let statusReason = reasons.find(r => r.lookupSetID == 122 || r.lookupSetID == 128)?.lookupValueID;
      if (statusReason){
        return statusReason;
      } else if (reasons && reasons.length > 0) {
        return reasons[0].lookupValueID;
      }
      return outcomeReason;
    }
  }

  isAuthClosed(authForm: AuthIntakeForm): boolean {
    let closedIDs = this.lookupData.filter(l => l.lookupStandardValue.toLowerCase() == 'closed' || l.lookupStandardValue.toLowerCase() == 'void' ||
          l.lookupStandardValue.toLowerCase() == 'withdrawn')?.map(l => l.lookupValueID);
    if (closedIDs && closedIDs.length > 0){
      if (closedIDs.includes(authForm.authIntakeDetail[0].currentStatus)){
        return true;
      }
    }
    return false;
  }
}
