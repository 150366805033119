import { Component, OnInit, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LookupService } from '../../services/lookup.service';
import { Task } from "../../models/task/task";
import { QueueService } from '../../services/queue.service';
import { ConfigService } from '../../services/config-service.service';
import { TaskService } from '../../services/task-service.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { queueTypes, ReferTaskTypes, TASKSTATUS_SUCCESSFUL, TASKSTATUS_UNSUCCESSFUL, TEAMQUEUETASKS } from '../../../assets/constants';
import { FilterService } from '../../services/filter-service.service';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe

export class TaskIdData
{
    taskId!: number
}

export class TaskQueueUpdate {
  taskIdList!:TaskIdData[];
  owner!:string | null;
  groupId!: number | null;
  currentUser!: string;
}

@Component({
  selector: 'app-team-queue-tasks',
  templateUrl: './team-queue-tasks.component.html',
  styleUrls: ['./team-queue-tasks.component.scss']
})
export class TeamQueueTasksComponent implements OnInit {
  @ViewChild('filterTaskDialogTemplate') filterTaskTemplate: TemplateRef<any> | undefined;

  selectedTasks: Task[] = [];
  refreshTitleText = 'Refresh task queue';
  teamTasks!: Task[];
  teamTasksFiltered!: Task[];
  userGroupIds!: number[];
  userName!: string;
  totalTasks = 0;
  successId!: number[];
  unsuccessfulId!: number[];
  sub!: Subscription;
  allTasksList = new BehaviorSubject<Task[]>([]);
  filterType = TEAMQUEUETASKS;

  //Select or deselect all checkboxes
  allSelected: boolean = false;
  selectOrDeselectAll() {
    var value = this.allSelected ? true : false;
    if (value) {
      this.selectedTasks = [];
      this.statusFilter();
      this.teamTasks.forEach(element => {
        element.checked = value;
        this.taskSelectedToggle(element);
      });
    }
    else {
      this.teamTasks.forEach(element => {element.checked = false});
      this.selectedTasks = [];
    }
  }

  filterBadgeData = { text: '', count: 0 }
  openDialogEvent = new EventEmitter<boolean>();

  constructor(private queueService: QueueService, private configService: ConfigService, private taskService: TaskService, 
    private tokenService: TokenStorageService, private lookupService: LookupService, private filterService: FilterService  ) { }
  
  openFilterDialog() {
    this.openDialogEvent.emit(true);
  }

  referDialogEvent(event: any) {
    const taskList = this.selectedTasks.map(t => {
      let element: TaskIdData = {taskId: t.taskId};
      return element;
    });
    let updateParams = new TaskQueueUpdate();
    switch (event.referType) {
      case ReferTaskTypes.MY_ASSIGNMENT:
        this.selectedTasks.forEach(t => {
          const index = this.teamTasks.findIndex(x => x.taskId == t.taskId);
          this.teamTasks.splice(index,1);
        });
        this.queueService.transferItems(queueTypes.MEMBER_TASK_QUEUE, this.selectedTasks);
        updateParams = { taskIdList: taskList, owner: this.userName, groupId: null, currentUser: this.userName }
        break;
      case ReferTaskTypes.TEAM_MEMBER:
        updateParams = { taskIdList: taskList, owner: event.teamMemberId, groupId: null, currentUser: this.userName }
        break;
      case ReferTaskTypes.WORK_QUEUE:
        updateParams = { taskIdList: taskList, owner: null, groupId: event.workQueueTypeId, currentUser: this.userName }
        break;
    }
    this.taskService.updateTaskQueues(updateParams);
    this.resetCheckedTasks(updateParams);
  }

  ngOnInit(): void {
    this.sub = this.lookupService.LookupData.subscribe(data => {
      this.successId = data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_SUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
      this.unsuccessfulId = data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_UNSUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
      if (this.teamTasks) {
        this.statusFilter();
        this.totalTasks = this.teamTasks.filter(t => t.hidden == false).length;
      }
    })
    this.sub.add(this.taskService.teamTaskList.subscribe(data => {
      if (data && data.length > 0) {
        this.teamTasks = data;
        this.sortQueue();    
        this.teamTasks = this.filterService.applySessionFilter(this.teamTasks, TEAMQUEUETASKS);
        this.statusFilter();
        this.allTasksList.next(this.teamTasks);
        this.totalTasks = this.teamTasks.filter(t => t.hidden == false).length;
      }
    }));
    this.sub.add(this.configService.userGroupList.subscribe(data => {
      if (data.length > 0) {
        this.userGroupIds = data.map(d => d.queueId);
          this.taskService.getTaskListByGroupID(this.userGroupIds);
      }
    }));
    this.sub.add(this.taskService.taskUpdated.subscribe(data => {
      if (data) {
        this.taskService.getTaskListByGroupID(this.userGroupIds);
      }
    }));
    this.sub.add(this.filterService.badgeDataBs.subscribe(bd => {
      if (bd.filter_type == TEAMQUEUETASKS) {
        this.filterBadgeData = bd;
      }
    }));
    if (this.successId && this.unsuccessfulId && this.teamTasks) {
      this.statusFilter();
      this.totalTasks = this.teamTasks.length;
    }
    this.userName = this.tokenService.getUser().profile.preferred_username;
  }

  statusFilter() {
    this.teamTasks = this.teamTasks.filter(t=> (!this.successId.includes(t.statusId) || this.unsuccessfulId.includes(t.statusId)));
  }

  taskSelectedToggle(e: any) {
    if (e.checked && e.taskId != undefined && this.teamTasks.findIndex(t => t.taskId == e.taskId) != undefined) {
      this.selectedTasks.push(this.teamTasks.find(t => t.taskId == e.taskId)!);
    }
    else {
      const selectedIndex = this.selectedTasks.findIndex(t => t.taskId == e.taskId);
      this.selectedTasks.splice(selectedIndex,1);
    }
  }

  sortQueue() {
    this.teamTasks.sort((a,b) => this.queueService.sortTasks(a, b));
  }

  refreshQueue() {
    this.taskService.getTaskListByGroupID(this.userGroupIds);
  }
  
  resetCheckedTasks(list: TaskQueueUpdate):void {
    list.taskIdList.map(t => t.taskId).map(t => {
      const task = this.teamTasks.find(tt => tt.taskId === t);
      if (task != undefined)  task.checked = false;
    });
    this.selectedTasks = [];
    this.allSelected = false;
  }

  dialogEvent(tasks: Task[]) {
    this.teamTasks = tasks;
    this.statusFilter();
    this.allTasksList.next(this.teamTasks);
  }

  displayCountEvent(count:number) {
    this.totalTasks = count;
  }
}
