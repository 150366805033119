import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "../shared/appsettings";
import { Observable, map } from "rxjs";
import { ApiResponse } from "../models/api/apiResponse";

@Injectable({
    providedIn: 'root'
  })
  export class EDIService {
    constructor(private config: AppSettings, private http: HttpClient){

    }

    callEdi278(memberID: string, authNumber: string, overrideEdit: boolean, ssc: boolean): Observable<any> {
        let y = 0;
        if (overrideEdit && ssc) y = 3;
        if (overrideEdit && !ssc) y = 1;
        if (!overrideEdit && ssc) y = 2;

        let headers = new HttpHeaders({ 'x-api-version': '1.0' });
        let options = { headers: headers };
        let ediServiceBaseUrl = this.config.ediClientURL278;
        let authResubmitUrl = ediServiceBaseUrl + `MemberID=${memberID}&AuthID=${authNumber}&overrideEdit=${y}`;
        return this.http.get<ApiResponse<string>>(authResubmitUrl, options).pipe(
            map((response)=>{
              if (response.succeeded && response.message){
                let info = response.message.split('~');
                if (info[info.length-1].toLowerCase().trim() == 'fine response'){
                  return 'Success';
                } else {
                  return info[info.length-1];
                }
              } else {
                return response.message;
              }
            })
        );
    }
  }