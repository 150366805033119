export class AuthIntakeReview {
  authReviewId!: number;
  authDetailId!: number;
  reviewTypeId!: number;
  reviewType!: string;
  reviewOutcome!: number;
  outcomeReason!: number|null;
  rationale!: string;
  isSubmitted!: boolean;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete: boolean = false;
  physicianRationale!: string | null;
  initialDecisionDate!: string | null;
  approvedStartDate!: string | null;
  approvedEndDate!: string | null;
  physicianReviewRequested!: boolean;
  physicianReviewSubmitted!: boolean;
}
