import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { BlobResponseDto } from "@CommonLib/models/storage/blobResponseDto";
import { BlobMetaData } from "@CommonLib/models/storage/blobMetaData";
import { DocSplitInfo } from "@CommonLib/models/storage/docSplitInfo";
import { DocSplitData } from "@CommonLib/models/storage/docSplitData";
import { InboundDocument } from "@CommonLib/models/storage/inboundDocument";
import { ApiResponse } from "@CommonLib/models/apiResponse";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  avatar!: Observable<Blob>;
  public storageDocumentId = new BehaviorSubject<BlobResponseDto>({} as BlobResponseDto);
  public interqualAttachDocumentId = new BehaviorSubject<BlobResponseDto>({} as BlobResponseDto);
  public storageFileDeleted = new BehaviorSubject(false);
  public faxList = new BehaviorSubject<InboundDocument[]>([]);
  public SplittingFax = new BehaviorSubject(false);
  public DocClosed = new BehaviorSubject(false);
  public avatarId = new BehaviorSubject<BlobResponseDto>({} as BlobResponseDto);
  public faxStatusUpdated = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
  }

  getAvatar(fileName: string, storageApiBaseUrl: string, productId:number): Observable<HttpEvent<Blob>> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let storageUrl = storageApiBaseUrl + "Storage/Download";
    return this.http.request<Blob>(new HttpRequest(
      'GET',
      `${storageUrl}?filename=${fileName}&productId=${productId}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob',
        headers: headers
      }));
  }

  uploadAvatar(img: FormData, originalFileName: string, storageApiBaseUrl: string, customerId: number, productId: number, userName: string, docTypeName: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    var md: BlobMetaData = new BlobMetaData();

    md.customerId = customerId;
    md.productId = productId;
    md.userName = userName;
    md.displayName = `${userName} avatar`;
    md.docName = originalFileName;
    md.docSource = 'um';
    md.docType = docTypeName;
    md.parentDocName = '';
    md.storageAccount = '';
    md.updateP8 = false;
    let tags = new Map<string, string>();
    tags.set('type', 'avatar');
    const convMap: any = {};
    tags.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    md.tags = convMap;
    img.append('metaData', JSON.stringify(md));

    let storageUrl = storageApiBaseUrl + "Storage/Upload";
    // DON'T SET THE Content-Type to multipart/form-data, You'll get the Missing content-type boundary error
    let options = { headers: headers };

    this.http.post<ApiResponse<BlobResponseDto>>(storageUrl, img, options).subscribe(r => {
      if (r.succeeded && r.data) {
        this.avatarId.next(r.data);
      } else {
        throw new Error(r.message);
      }
    });
  }

  uploadDocument(file: FormData, originalFileName: string, displayName: string, parentDocument: string, docType: string | undefined, updateP8: boolean, 
              authDetailId: number, docTypeName: string, storageApiBaseUrl: string, customerId: number, productId: number, userName: string, docSource: string,
            isInterqual: boolean) : Observable<string | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    var md: BlobMetaData = new BlobMetaData();

    md.customerId = customerId;
    md.productId = productId;
    md.userName = userName;
    md.displayName = displayName;
    md.docName = originalFileName;
    md.docSource = docSource;
    md.docType = docTypeName;
    md.parentDocName = parentDocument;
    md.storageAccount = '';
    let tags = new Map<string, string>();
    tags.set('type', docType ?? docTypeName);
    const convMap: any = {};
    tags.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    md.tags = convMap;
    md.updateP8 = updateP8;
    md.authDetailId = authDetailId;
    file.append('metaData', JSON.stringify(md));

    let storageUrl = storageApiBaseUrl + "Storage/Upload";
    // DON'T SET THE Content-Type to multipart/form-data, You'll get the Missing content-type boundary error
    let options = { headers: headers };

    return this.http.post<ApiResponse<BlobResponseDto>>(storageUrl, file, options).pipe(
      map((data)=>{
        console.log("uploadDocument");
        if (data.succeeded && data.data) {
          //This is a hack. Currently when the attachment is added as Interqual review the storageDocumentId behavior subject triggers the AuthForm save from the Attachment component
          //causing the Interqual save to fail due to duplicate entries. Creating two separate behavior subjects to handle this upload.
          if (!isInterqual){
            this.storageDocumentId.next(data.data);
          } else {
            this.interqualAttachDocumentId.next(data.data);
          }
          if (data.data.blob.name) {
            var guid = data.data.blob.name.split('/').pop();
            if (guid) {
              return guid;
            }
            return undefined;
          } else {
            return undefined;
          }
        } else {
          throw new Error(data.message);
        }
      })
  );
  }

  resetStorageDocumentId(){
    this.storageDocumentId.next({} as BlobResponseDto);
  }

  getDocument(fileName: string, storageApiBaseUrl: string, productId: number): Observable<HttpEvent<Blob>> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let storageUrl = storageApiBaseUrl + "Storage/Download";
    return this.http.request<Blob>(new HttpRequest(
      'GET',
      `${storageUrl}?filename=${fileName.toLowerCase()}&productId=${productId}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob',
        headers: headers
      }));
  }

  deleteDocument(documentGuid: string, authDetailId: number, updateP8: boolean, storageApiBaseUrl: string, productId: number) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let storageUrl = storageApiBaseUrl + `Storage/Delete?documentGuid=${documentGuid}&productId=${productId}&authDetailId=${authDetailId}&updateP8=${updateP8}`;

    let options = {
      headers: headers, responseType: 'text' as 'text' };
    this.http.delete(storageUrl, options).subscribe(r => {
      this.storageFileDeleted.next(true);
    });
  }

  getFaxList(storageApiBaseUrl: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let storageUrl = storageApiBaseUrl + "Fax/GetInboundFaxesList";
    this.http.get<ApiResponse<InboundDocument[]>>(storageUrl, { headers: headers }).subscribe(
      data => {
        console.log("GetFaxList");
        if (data.succeeded == true && data.data!) {
          this.faxList.next(data.data);
        }
      }
    );
  }

  splitFax(internalDocumentId: string, splitInfo: DocSplitInfo[], assignedQueue: number, assignedUser: string, newStatusId: number, storageApiBaseUrl: string,
      customerId: number, productId: number, userName: string) {
    this.SplittingFax.next(true);
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let splitEndpoint = storageApiBaseUrl + "Fax/SplitDocument";
    let docSplitData = new DocSplitData();
    docSplitData.internalDocumentId = internalDocumentId;
    docSplitData.splitInfo = splitInfo;
    docSplitData.customerId = customerId;
    docSplitData.productId = productId;
    docSplitData.userName = userName;
    docSplitData.assignedQueue = assignedQueue;
    docSplitData.assignedUser = assignedUser;
    docSplitData.newStatusId = newStatusId;

    this.http.post<ApiResponse<string>>(splitEndpoint, JSON.stringify(docSplitData), options).subscribe(r => {
      if (r && r.succeeded) {
        this.SplittingFax.next(false);
      }
    });
  }

  updateFaxStatus(internalDocumentId: string, statusId: number, pageCount: number, authNumber: string|null, newDocName: string, authDetailID: number, updateP8: boolean,
    storageApiBaseUrl: string, userName: string, triggerRefresh: boolean) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let updateEndpoint = storageApiBaseUrl + "Fax/UpdateDocumentStatus";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("internalDocumentId", internalDocumentId);
    queryParams = queryParams.append("statusId", statusId);
    queryParams = queryParams.append("pageCount", pageCount);
    queryParams = queryParams.append("userName", userName);
    queryParams = queryParams.append("authDetailId", authDetailID);
    queryParams = queryParams.append("updateP8", updateP8);
    if (newDocName) {
      queryParams = queryParams.append("newDocName", newDocName);
    }
    if (authNumber) {
      queryParams = queryParams.append("authNumber", authNumber);
    }

    this.http.post(updateEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { 
      if (triggerRefresh){
        this.faxStatusUpdated.next(true);
      } else {
        this.faxStatusUpdated.next(false);
      }
    });
  }

  closeDoc(closed: boolean) {
    this.DocClosed.next(closed);
  }

  referFax(internalDocumentId: string, queueId: number, newOwner: string, userName: string, storageApiBaseUrl: string){
      let headers = new HttpHeaders({ 'x-api-version': '1.0' });
  
      let updateEndpoint = storageApiBaseUrl + "Fax/ReferFax";
  
      let queryParams = new HttpParams();
      queryParams = queryParams.append("internalDocumentId", internalDocumentId);
      queryParams = queryParams.append("queueId", queueId);
      queryParams = queryParams.append("newOwnerName", newOwner);
      queryParams = queryParams.append("userName", userName);
  
      this.http.post(updateEndpoint, null, { headers: headers, params: queryParams }).subscribe(r => { 
        this.faxStatusUpdated.next(true);
      });
  }

  renameFax(internalDocumentId: string, newFaxName: string, userName: string, storageApiBaseUrl: string){
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let updateEndpoint = storageApiBaseUrl + "Fax/RenameFax";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("internalDocumentId", internalDocumentId);
    queryParams = queryParams.append("newFaxName", newFaxName);
    queryParams = queryParams.append("userName", userName);

    return this.http.post(updateEndpoint, null, { headers: headers, params: queryParams }).pipe(
      map(()=>{
        this.faxStatusUpdated.next(true);
      })
    );
}
}


