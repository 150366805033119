<!-- dialog content: Process or Split Faxes -->
<ng-template #processSplitFaxDialogTemplate>
  <form [formGroup]="processSplitFaxForm">
    <div class="fax-dialog-container two-cols">
      <div class="doc-viewer-container">
        <div class="center-loader">
          <!-- <mat-spinner diameter="40" *ngIf="showLoading"></mat-spinner> -->
        </div>
        <iframe [src]='pdfSrc' frameborder="0" allowfullscreen *ngIf="pdfSrc"></iframe>
      </div>
      <div class="fax-action-container">
        <mat-button-toggle-group class="compact stretch mb-15" value="process" #toggleBtn="matButtonToggleGroup">
          <mat-button-toggle value="process">Process</mat-button-toggle>
          <mat-button-toggle value="split" *ngIf="!childFax">Split</mat-button-toggle>
          <mat-button-toggle value="refer">Refer</mat-button-toggle>
        </mat-button-toggle-group>

        <div class="toggled-content" style="min-height: 63vh">
          <div *ngIf="toggleBtn.value == 'process'">

            <!--Rename fax: available for parent fax only-->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Rename Fax</mat-label>
              <input matInput [value]="selectedPdfName" formControlName="renameFax" (input)="onFaxNameChange()">
              <button matSuffix mat-flat-button color="vdt-primary" *ngIf="showRenameBtn" 
                      (click)="handleRename()">Rename</button>
            </mat-form-field>

            <div class="alert alert-success" role="alert" *ngIf="showSuccessAlert">
              <span>Fax renamed</span>
            </div>

            <app-auth-search [item]="authSearchResults"
                             [isAuthSelectable]="true"
                             [isMemberSelectable]="true"
                             (authSelection)="authSelected($event)"
                             (memberSelection)="memberSelected($event)">
            </app-auth-search>
          </div>
          <div *ngIf="toggleBtn.value == 'split'">
            <div *ngFor="let pdf of pdfs; let i = index">
              <form>
                <div data-layout="row" data-layout-align="space-between center" data-layout-gap="2em">
                  <span class="text-md">{{ i+1 }}.</span>
                  <ng-container [ngTemplateOutlet]="splitFaxTemplate" [ngTemplateOutletContext]="{ pdf: pdf }">
                  </ng-container>
                </div>
              </form>
            </div>
            <div class="push-right">
              <button mat-flat-button type="button" color="vdt-secondary" class="with-icon" (click)="addMorePages()" *ngIf="showAdd">
                <mat-icon class="material-icons-outlined icon prefix" aria-hidden="false" aria-label="Add">add</mat-icon>
                Add more
              </button>
            </div>
          </div>
          <div *ngIf="toggleBtn.value == 'refer'">
            <app-refer-fax (referFaxEvent)="faxReferred($event)"></app-refer-fax>
          </div>
        </div>

        <div class="button-container" *ngIf="toggleBtn.value == 'process'">
          <div class="text-primary-dark bold">Attach to: </div>
          <div data-layout="row nowrap" data-layout-align="space-between center" data-layout-gap="1em">
            <button data-flex="auto" mat-flat-button type="button" color="vdt-primary" (click)="attachToAuth()" [disabled]="!authIdSelected">
              Existing Auth
            </button>
            <button data-flex="auto" mat-flat-button type="button" color="vdt-primary" (click)="createNewAuth()" [disabled]="!memberIdSelected">
              New Auth
            </button>
            <button data-flex="auto" mat-flat-button type="button" color="vdt-primary" (click)="createConcurrentAuth()" [disabled]="!concAuthElig.value">
              New Concurrent Auth
            </button>
          </div>
        </div>
        <div class="button-container push-right" *ngIf="toggleBtn.value == 'split'">
          <button mat-flat-button type="button" color="vdt-primary" (click)="splitFax()" [disabled]="!validPages">Split Selected Pages</button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<!--Split fax pages html block-->
<ng-template #splitFaxTemplate let-pdf="pdf">
  <mat-form-field appearance="fill" class="xs-width">
    <mat-label>From</mat-label>
      <input matInput placeholder="" #fromPage [(ngModel)]="pdf.From" value="pdf.From" [ngModelOptions]="{standalone: true}" (change)="validSplitPages()">
  </mat-form-field>
  <mat-form-field appearance="fill" class="xs-width">
    <mat-label>To</mat-label>
    <input matInput placeholder="" #toPage [(ngModel)]="pdf.To" value="pdf.To" [ngModelOptions]="{standalone: true}" (change)="validSplitPages()">
  </mat-form-field>
  <mat-form-field appearance="fill" data-flex="auto">
    <mat-label>Add a Title</mat-label>
    <input matInput placeholder="" [(ngModel)]="pdf.Title" value="pdf.Title" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  <button mat-icon-button color="primary" title="Remove" aria-label="Remove" (click)="removeSplit(pdf)">
    <mat-icon class="material-icons-outlined icon">delete</mat-icon>
  </button>
</ng-template>

<!-- dialog content: View Split Faxes -->
<ng-template #viewSplitFaxDialogTemplate>
  <div class="fax-dialog-container">
    <div class="doc-viewer-container">
      <ng-container [ngTemplateOutlet]="splitFaxReadOnlyTemplate" [ngTemplateOutletContext]="{ splitPdf: splitPdfs }">
      </ng-container>
      <div class="center-loader">
        <!-- <mat-spinner diameter="40" *ngIf="showLoading"></mat-spinner> -->
      </div>
      <iframe [src]='pdfSrc' frameborder="0" allowfullscreen *ngIf="pdfSrc"></iframe>
    </div>
    <div>
    </div>
  </div>
</ng-template>

<ng-template #splitFaxReadOnlyTemplate let-pdf="splitPdf">
  <div data-layout="row" data-layout-align="start center" data-layout-gap="2em" class="mb-10" *ngFor="let splitPdf of splitPdfs">
    <mat-form-field appearance="fill" class="no-hint">
      <mat-label>Pages</mat-label>
      <input matInput placeholder="" [value]="splitPdf.pages" disabled>
    </mat-form-field>
    <mat-form-field appearance="fill" data-flex="auto" class="no-hint">
      <mat-label>Description</mat-label>
      <input matInput placeholder="" [value]="splitPdf.description" disabled>
    </mat-form-field>
  </div>
</ng-template>
