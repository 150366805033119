<button mat-icon-button class="close-button" title="Close" [mat-dialog-close] *ngIf="data.displayClose">
  <mat-icon class="material-icons-outlined icon close-icon" aria-hidden="false" aria-label="Close">close</mat-icon>
</button>
<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content *ngIf="data.dialogContentString == undefined">
  <ng-container [ngTemplateOutlet]="data.dialogContent"> </ng-container>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.dialogContentString! != undefined">
  <ng-container><span style="white-space:pre-line">{{data.dialogContentString}}</span></ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="data.declineButtonTitle" mat-stroked-button color="vdt-basic" (click)="onDecline()" mat-dialog-close>
    {{ data.declineButtonTitle }}
  </button>
  <button *ngIf="data.acceptButtonTitle2" mat-flat-button color="vdt-basic" (click)="onAccept2()">
    {{ data.acceptButtonTitle2 }}
  </button>
  <button *ngIf="data.acceptButtonTitle1" mat-flat-button color="vdt-primary" (click)="onAccept1()" cdkFocusInitial>
    {{ data.acceptButtonTitle1 }}
  </button>
</mat-dialog-actions>