import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ConfirmDialogComponent } from "../components/dialog/dialog-confirm/dialog-confirm.component";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor (private confirmDialog: ConfirmDialogComponent){}

  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Are you sure?');

    return of(confirmation);
  };

  async confirmAsync(message?: string, title?: string):Promise<boolean> {
    const dialogRef = this.confirmDialog.open({
      title: title!, 
      message: message!, 
      acceptButtonText: 'Yes', 
      declineButtonText: 'No'
    });

    this.confirmDialog.buttonAction.subscribe(result => {
      dialogRef.close(result);
    });

    return await dialogRef.afterClosed().toPromise();
  }

  async confirmAsyncOk(message?: string, title?: string): Promise<boolean> {
    const dialogRef = this.confirmDialog.open1Btn({
      title: title!,
      message: message!,
      acceptButtonText: 'OK',
      declineButtonText: ''
    });

    this.confirmDialog.buttonAction.subscribe(result => {
      dialogRef.close(result);
    });

    return await dialogRef.afterClosed().toPromise();
  }

  async confirmAutoCloseAsync(autoCloseSeconds: number, message?: string, title?: string): Promise<boolean> {
    const dialogRef = this.confirmDialog.open({
      title: title!,
      message: message!,
      acceptButtonText: 'Yes',
      declineButtonText: 'No'
    });

    this.confirmDialog.buttonAction.subscribe(result => {
      dialogRef.close(result);
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close(false);
      }, autoCloseSeconds*1000)
    })
    return await dialogRef.afterClosed().toPromise();
  }
}
