import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionalMatSelectDirective } from './optional-mat-select.directive';
import { ControlBehaviorDirective } from './control-behavior.directive';
import { DisableFormDirective } from './disable-form.directive';
import { DisableControlDirective } from './disable-control.directive';

@NgModule({
  declarations: [
    OptionalMatSelectDirective,
    ControlBehaviorDirective,
    DisableFormDirective,
    DisableControlDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OptionalMatSelectDirective,
    ControlBehaviorDirective,
    DisableFormDirective,
    DisableControlDirective
  ]
})
export class DirectivesModule { }
