import { Component, Inject, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './dialog-confirm.component.html',
})

export class ConfirmDialogComponent {
  @ViewChild('confirmDialogTemplate') confirmDialogTemplate: TemplateRef<any> | undefined;
  @Output() buttonAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() buttonAction2: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogModel, public dialog: MatDialog) {

  }

  open(dialogData: IConfirmDialogModel){
    const dialogRef = this.dialog.open(DialogComponent, {
      width: dialogData.width || '25em',
      height: dialogData.height || 'auto',
      data: <IDialogConfig>{
        title: dialogData.title || 'Confirmation',
        dialogContentString: dialogData.message || 'Are you sure?',
        acceptButtonTitle1: dialogData.acceptButtonText || 'Yes',
        declineButtonTitle: dialogData.declineButtonText || 'No',
      },
      disableClose: true
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(e => this.buttonAction.emit(true));
    dialogRef.componentInstance.clickDeclineButton.subscribe(e => this.buttonAction.emit(false));

    return dialogRef;
  }

  open3Btn(dialogData: IConfirmDialogModel) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: dialogData.width || '25em',
      height: dialogData.height || 'auto',
      data: <IDialogConfig>{
        title: dialogData.title || 'Confirmation',
        dialogContentString: dialogData.message || 'Are you sure?',
        acceptButtonTitle1: dialogData.acceptButtonText || 'Yes',
        acceptButtonTitle2: dialogData.acceptButtonText2 || 'Maybe',
        declineButtonTitle: dialogData.declineButtonText || 'No',
      },
      disableClose: true
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(e => this.buttonAction2.emit(true));
    dialogRef.componentInstance.clickAcceptButton2.subscribe(e => this.buttonAction2.emit('override'));
    dialogRef.componentInstance.clickDeclineButton.subscribe(e => this.buttonAction2.emit(false));

    return dialogRef;
  }

  open1Btn(dialogData: IConfirmDialogModel) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: dialogData.width || '25em',
      height: dialogData.height || 'auto',
      data: <IDialogConfig>{
        title: dialogData.title || 'Confirmation',
        dialogContentString: dialogData.message || 'Are you sure?',
        acceptButtonTitle1: dialogData.acceptButtonText || 'Yes',
        displayClose: dialogData.displayClose
      },
      disableClose: true
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(e => this.buttonAction.emit(true));

    return dialogRef;
  }
}

export interface IConfirmDialogModel {
    title: string;
    message: string, 
    acceptButtonText: string;
    declineButtonText: string;
    width?: string | undefined;
  height?: string | undefined;
  acceptButtonText2?: string | undefined;
  displayClose?: boolean;
}
