import { Component, EventEmitter, Inject, Output, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDialogConfig {
  title?: string;
  acceptButtonTitle1?: string;
  acceptButtonTitle2?: string;
  declineButtonTitle?: string;
  dialogContent: TemplateRef<any>;
  dialogContentString?: string;
  displayClose?: boolean;
}

@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Output() clickAcceptButton1: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickAcceptButton2: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickDeclineButton: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogConfig) {
    data.acceptButtonTitle1 ?? 'Action 1';
    data.acceptButtonTitle2 ?? 'Action 2';
    data.title ?? 'Unnamed Dialog';
    data.displayClose ?? true;
  }

  /*dynamic dialog reference: https://georghoeller.dev/angular-material-dynamic-dialog-component/*/
  onDecline() {
    this.clickDeclineButton.emit();

  }

  onAccept1() {
    this.clickAcceptButton1.emit();
  }

  onAccept2() {
    this.clickAcceptButton2.emit();
  }
}
