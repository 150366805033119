import { DocSplitInfo } from "./docSplitInfo";

export class DocSplitData {
  internalDocumentId!: string;
  splitInfo!: DocSplitInfo[];
  customerId!: number;
  productId!: number;
  userName!: string;
  assignedQueue!: number;
  assignedUser!: string;
  newStatusId!: number;
}