export class AuthIntakeNote {
  type!: string;
  authNoteId!: number;
  authDetailId!: number;
  noteTypeId!: number;
  noteTypeText!: string;
  noteText!: string;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete: boolean = false;
  hidden: boolean = false;
  isEdited = false;
  isEditable = true;
  isDeletable = false;
  isReadable = true;
  copiedValue: boolean = false;
}
