import { Component, OnInit, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { FormService } from '../../services/form-service.service';
import { LookupService } from '../../services/lookup.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { AuthQueueData } from '../../shared/components/queue/AuthQueueData';
import { LookupValue } from '../../models/Lookups/lookupValue';
import { QueueService } from '../../services/queue.service';
import { MYQUEUEAUTHS, queueTypes } from '../../../assets/constants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';
import { FilterService } from '../../services/filter-service.service';
import { BadgeData } from '../../models/filter/BadgeData';
import { AppSettings } from '../../shared/appsettings';
import { EventService } from '@CommonLib/services/events.service';

@AutoUnsubscribe
@Component({
  selector: 'app-my-queue-auths',
  templateUrl: './my-queue-auths.component.html',
  styleUrls: ['./my-queue-auths.component.scss']
})
export class MyQueueAuthsComponent implements OnInit {
  @ViewChild('filterAuthDialogTemplate') filterAuthTemplate: TemplateRef<any> | undefined;

  user: any;
  authType!: LookupValue[];
  urgency!: LookupValue[];
  authStatus!: LookupValue[];
  authStatusReason!: LookupValue[];
  providerList!: string[];
  procCodeIdList: any;
  procCodeDescList!: AuthQueueData[];
  openDialogEvent = new EventEmitter<boolean>();
  allAuthsListByOwner = new BehaviorSubject<AuthQueueData[]>([]);
  authsListByOwner: AuthQueueData[] = [];
  unFilteredAuthsListByOwner: AuthQueueData[] = [];
  myList: AuthQueueData[] = [];
  queueDisplayCount: number = 0;
  sub!: Subscription;
  sub2!: Subscription;
  filterType = MYQUEUEAUTHS;

  filterBadgeData: BadgeData = {text: '', count: 0, filter_type: ''}
  snapshotFilter: number = -1;
  timezone = "";

  constructor(private lookupService: LookupService, private formService: FormService, private tokenService: TokenStorageService, 
    private queueService: QueueService, private filterService: FilterService, private appSetting: AppSettings, private eventService: EventService) {
    this.user = this.tokenService.getUser();
    this.timezone = this.appSetting.timeZone;
  }

  //Refresh auth queue icon
  refreshTitleText = 'Refresh auth queue';
  refreshQueue() {
    this.formService.getAuthIntakeWorkQueueByOwner(this.user.profile.preferred_username).subscribe();
    this.queueDisplayCount = this.authsListByOwner.filter(a => a.hidden === false).length;
    this.allAuthsListByOwner.next(this.authsListByOwner);
  }

  openFilterDialog() {
    this.openDialogEvent.emit(true);
  }

  //Work Queue Data
  ngOnInit(): void {
    this.sub = this.lookupService.LookupData.subscribe(data => {
      if (data && data.length > 0) {
        this.authType = data.filter(d => d.lookupSetID == 108);
        this.urgency = data.filter(d => d.lookupSetID == 109);
        this.authStatus = data.filter(d => d.lookupSetID == 116);
        this.authStatusReason = data.filter(d => d.lookupSetID == 128);

        this.sub2 = this.formService.AuthWorkQueueListByOwner.subscribe(data => {
          if (data.length > 0) {
            this.procCodeDescList = [];
            this.authsListByOwner = data;
            this.unFilteredAuthsListByOwner = data;
            
            this.authsListByOwner = this.filterService.applySessionFilter(this.authsListByOwner, MYQUEUEAUTHS, [this.authStatus, this.authStatusReason]);
            this.queueDisplayCount = this.authsListByOwner.filter(a => !a.hidden).length;

            //Store the proc code desc for tooltip
            this.procCodeDescList = JSON.parse(JSON.stringify(this.authsListByOwner.filter(
              (auth, i, arr) => arr.findIndex(t => t.procCodeId === auth.procCodeId) === i
            ))) as typeof this.authsListByOwner;
            
            if (!this.allAuthsListByOwner.closed) {
              this.allAuthsListByOwner.next(this.authsListByOwner);
            }
          }
        });

      }
    });

    this.sub.add(this.queueService.QueueItemsToAdd.subscribe(data => {
      if (data.queueId == queueTypes.MEMBER_AUTHORIZATION_QUEUE) {
        const itemsToAdd =  (data.items as unknown) as AuthQueueData[];
        itemsToAdd.forEach(i => this.authsListByOwner.push(i));
        this.queueDisplayCount = this.authsListByOwner.filter(a => !a.hidden).length;
        this.authsListByOwner.sort((a, b) => this.queueService.sortAuths(a, b));
        this.allAuthsListByOwner.next(this.authsListByOwner);
      }
    }));

    this.sub.add(this.filterService.badgeDataBs.subscribe(bd => {
      if (bd.filter_type == MYQUEUEAUTHS) {
        this.filterBadgeData = bd;
      }
    }));

    this.sub.add(this.eventService.TimeZoneEvent.subscribe(t => {
      this.timezone = this.appSetting.timeZone;
    }))
  }

  dialogEvent() {
    this.queueDisplayCount = this.authsListByOwner.filter(a => !a.hidden).length;
  }

  displayCountUpdate(count: number) {
    this.queueDisplayCount = count;
    this.authsListByOwner = [...this.unFilteredAuthsListByOwner.filter(a => !a.hidden)];
    this.allAuthsListByOwner.next(this.authsListByOwner);
  }
}
export class procToolTip {
  procCodeId!: string;
  procCodeTip!: string;
}
