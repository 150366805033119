<div class="queue-container" [ngClass]="{'urgent': queue.dueDate < UrgentDueDate, 'semi-urgent': queue.dueDate > UrgentDueDate && queue.dueDate < SemiUrgentDueDate, 'new': queue.showAsNew}" data-layout="row">
  <div class="select-queue" *ngIf="isSelectable">
    <mat-checkbox aria-label="Select or deselect auth number {{ queue.authNumber }}" [(ngModel)]="queue.checked" (change)="onChange($event)" [value]="queue.authNumber"></mat-checkbox>
  </div>
  <div class="queue-content full-width">
    <div class="data-list">
      <ul>
        <li class="mb-5">
          <span class="label cdk-visually-hidden">Member Name: </span>
          <span class="value"><span class="data-header">{{ queue.memberName }}</span></span>
        </li>
        <li>
          <span class="label">Member ID: </span>
          <span class="value"><a [routerLink]="['member/authorizations/'+ queue.mvdId]" [skipLocationChange]="true">{{ queue.memberId }}</a></span>
        </li>
        <li>
          <span class="label">Authorization #: </span>
          <span class="value">{{ queue.authNumber }}</span>
        </li>
        <li class="with-icon">
          <span class="label">Priority: </span>
          <span class="value" [ngClass]="{'text-danger': queue.urgency === 'Urgent'}">
            {{ queue.urgency }}
          </span>
          <mat-icon class="material-icons-outlined icon-danger icon-size-xs suffix" aria-hidden="false" title="Urgent Request" aria-label="Urgent Request" *ngIf="queue.urgency == 'Urgent'">error</mat-icon>
        </li>
        <li>
          <span class="label">LOB: </span>
          <span class="value">{{ queue.lob }}</span>
        </li>
        <li>
          <span class="label">Due: </span>
          <span class="value" [ngClass]="{'text-danger':queue.dueDate < UrgentDueDate,'text-warning': queue.dueDate > UrgentDueDate && queue.dueDate < SemiUrgentDueDate}">{{ queue.dueDate | date:dateFormat : timezone}}</span>
        </li>
        <li>
          <span class="label">Provider: </span>
          <span class="value">{{ queue.srvcProvider }}</span>
        </li>
        <li>
          <span class="label">Authorization Status: </span>
          <span class="value">{{ queue.status }}</span>
        </li>
        <li>
          <span class="label">Reason: </span>
          <span class="value">{{ queue.statusReason }}</span>
        </li>
        <li>
          <span class="label">Authorization Type: </span>
          <span class="value">{{ queue.authType }}</span>
        </li>
        <li>
          <span class="label">Primary Diagnosis: </span>
          <span class="value" matTooltipClass="multiline-tooltip" matTooltip={{queue.diagDesc}}>{{ queue.dxCodeID }}</span>
        </li>
        <li>
          <span class="label">Procedure: </span>
          <span class="value" *ngFor="let tip of queue.procCodeId?.split(','); let i=index;" matTooltipClass="multiline-tooltip" [matTooltip]="getToolTip(tip)">{{ tip==''?'N/A':tip }}{{i != queue.procCodeId.split(',').length-1?', ':''}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="action" *ngIf="isMyQueue">
    <button mat-icon-button title="View this auth" aria-label="View this auth" (click)="emitQueueAction(queue)">
      <mat-icon class="icon" aria-hidden="true">visibility</mat-icon>
    </button>
  </div>
</div>
