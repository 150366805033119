<div class="site-menu-compact">
  <mat-nav-list>
    <mat-list-item>
      <a mat-list-item routerLink="/" (click)="onSidenavClose()">
        <span>Home</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a mat-list-item routerLink="/reporting" [ngClass]="{'disabled': !reportingMenu }" (click)="onSidenavClose()">
        <span>Reporting</span>
      </a>
    </mat-list-item>
    <mat-list-item>
      <a mat-list-item routerLink="/fax-archive" [ngClass]="{'disabled': !faxArchiveMenu }" (click)="onSidenavClose()">
        <span>Fax Archive</span>
      </a>
    </mat-list-item>
    <mat-list-item (click)="showAdminSubmenu = !showAdminSubmenu">
      <span class="full-width has-submenu" *ngIf="isExpanded || isShowing">Admin</span>
      <mat-icon class="menu-button va-middle" [ngClass]="{'rotated' : showAdminSubmenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
    </mat-list-item>
    <div class="submenu" [ngClass]="{'expanded' : showAdminSubmenu}" *ngIf="isShowing || isExpanded">
      <a mat-list-item routerLink="/supervisor-tools" [skipLocationChange]="true" [controlBehavior]="'SupervisorTools'" (click)="onSidenavClose()">Supervisor Tools</a>
      <a mat-list-item routerLink="/configurations" [skipLocationChange]="true" [controlBehavior]="'Configuration'" (click)="onSidenavClose()" *ngIf="configurations">Configurations</a>
      <!--<a mat-list-item [routerLink]="" [controlBehavior]="'UnlockAuthMenu'" (click)="onSidenavClose(); openUnlockAuthDialog()">Unlock Auth</a>
      <a mat-list-item [routerLink]="" [controlBehavior]="'ImpersonateMenu'" (click)="onSidenavClose(); openImpersonateRoleDialog()">Impersonate Role</a>-->
    </div>
    <mat-list-item (click)="showLinksSubmenu = !showLinksSubmenu">
      <span class="full-width has-submenu" *ngIf="isExpanded || isShowing">Links</span>
      <mat-icon class="menu-button va-middle" [ngClass]="{'rotated' : showLinksSubmenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
    </mat-list-item>
    <div class="submenu" [ngClass]="{'expanded' : showLinksSubmenu}" *ngIf="isShowing || isExpanded">
      <a mat-list-item href="https://www.cms.gov/" title="Centers for Medicare & Medicaid Services" target="_blank">CMS.gov</a>
      <a mat-list-item href="https://www.helpfinder.org/" title="Find free or low cost Helping programs" target="_blank">HELPFINDER</a> 
      <a mat-list-item [routerLink]="" title="InterQual Guidelines" (click)="onSidenavClose(); openPortal()">InterQual&reg;</a>
      <a mat-list-item href="https://www.webmd.com/" target="_blank">WebMD&reg;</a>
      <a *ngIf="customerId==16" mat-list-item href="http://walmart/Coverage/Coverage_policy.asp" target="_blank">Walmart Coverage Policies</a>
      <a *ngIf="customerId==16" mat-list-item href="https://insideblueapps/coverage/BAA/Coveragepolicy.aspx?prefix=TYG" target="_blank">Tyson Coverage Policies</a>
      <a *ngIf="customerId==16" mat-list-item href="https://blueweb.bcbs.com/login/oktalogin.html?fromURI=https%3A%2f%2fbcbs-system-sp.okta.com%2fapp%2fbcbs-system-sp_siteminderfederationpoc_2%2fexksougizeCi7H61S696%2fsso%2fsaml%3FTYPE%3D33554433%26REALMOID%3D06-00047582-acbd-11dd-add8-213b0a8d0000%26GUID%3D%26SMAUTHREASON%3D0%26METHOD%3DGET%26SMAGENTNAME%3Dbw_sso_agent%26TARGET%3D%24SM%24https%253a%252f%252fbluewebportal%252ebcbs%252ecom%252f" target="_blank">FEP Policies</a>
    </div>
    <mat-list-item>
      <a mat-list-item routerLink="/signout-callback" (click)="onSidenavClose()">
        <span>Log Out</span>
      </a>
    </mat-list-item>
  </mat-nav-list>
</div>
