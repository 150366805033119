import { Injectable } from '@angular/core';

const TOKEN_KEY = 'case-token';
//The USER_KEY value will need to be updated once CM is fully functional. Currently the key is set to auth-user that is specific to UM.
//We need to name this as current user.
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }
 
  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
