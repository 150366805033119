import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(360deg)' })),
      transition('rotated => default', [style({ transform: 'rotate(-360deg)' }), animate('350ms ease-out')]),
      transition('default => rotated', animate('350ms ease-in'))
    ])
  ]
})
export class RefreshComponent implements OnInit {
  @Input() title: string = 'Refresh';
  @Output() iconClick = new EventEmitter();

  constructor() { }

  state: string = 'default';
  onClick() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
    this.iconClick.emit();
  }

  ngOnInit(): void {
  }

}
