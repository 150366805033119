import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthQueueData } from './AuthQueueData';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DATE_WITH_SECONDS_AMPM } from '../../../../assets/constants';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-auth-queue',
  templateUrl: './auth-queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class AuthQueueComponent implements OnInit {
  @Input() queue!: AuthQueueData;
  @Input() isSelectable: boolean = true;
  @Input() isMyQueue: boolean = false;
  @Input() toolTipList!: AuthQueueData[];
  @Input() timezone!: string;
  @Output() authAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() queueAction: EventEmitter<any> = new EventEmitter<any>();
  dateFormat = DATE_WITH_SECONDS_AMPM;

  constructor(private router: Router) { }

  SemiUrgentDueDate = moment().add(24, 'hours').format('YYYY-MM-DDTHH:mm:ss');
  UrgentDueDate = moment().format('YYYY-MM-DDTHH:mm:ss');

  onChange(ob: MatCheckboxChange) {
    this.queueAction.forEach(item => { });
    this.authAction.emit({ checked:ob.checked, authNumber: this.queue.authNumber });
  } 

  ngOnInit(): void {
  }

  emitQueueAction(element: any) {
    this.queueAction.emit(element);
    let AuthNumArr = element.authNumber.split("-");
    //alert('View this auth');
    //location.href = '/member/authorization-intake/' + element.authIntakeId + '/' + AuthNumArr[0] + '/';
    this.router.navigate([`/member/authorization-intake/${element.authIntakeId}/${AuthNumArr[0]}`], { skipLocationChange: true });
  }

  getToolTip(procCodeId: string) {
    if (procCodeId == '') {
      return 'N/A';
    }
    var tip = this.toolTipList?.filter((p: AuthQueueData) => p.procCodeId == procCodeId)?.[0]?.procDesc;
    if (tip == null) {
      return `${procCodeId}: N/A`;
    }
    return `${procCodeId}: ${tip}`;
  }
}
