export class MemberDemographic {
  caseManagerName!: string;
  icenumber!: string;
  email!: string;
  effectiveDate!: string;
  terminationDate!: string;
  pcp!: string;
  lob!: string;
  insMemberId!: string;
  firstName!: string;
  lastName!: string;
  dob!: string;
  gender!: string;
  address1!: string;
  address2!: string;
  city!: string;
  state!: string;
  postalCode!: string;
  homePhone!: string;
  cellPhone!: string;
  workPhone!: string;
  hierarchyKey!: string;
  inCaseManagement!: boolean;
}
