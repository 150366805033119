import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  getClientSideError(error: any) {
    return `Client side error: ${error}`;
  }

  getHttpError(error: Error | HttpErrorResponse) {
    return `Http side error: ${error.message}`;
  }
}
