import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})

export class DateHelper {
    public formatDateWithTimezone(date: string, timezome: string) : string {
        return moment(date).tz(timezome).toISOString();
    }
}