import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MemberAuthData } from './MemberAuthData';
import { FormService } from '../../../services/form-service.service';
import { MemberSearch } from '../../../models/member/memberSearch';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { TokenStorageService } from '../../../services/token-storage.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import { MatRadioChange } from '@angular/material/radio';
import { AuthIntakeListItem } from '../../../models/authView/authIntakeListItem';
import { CHARTHISTORY, DATE_MDY, DATE_WITH_SECONDS_AMPM } from 'projects/um/src/assets/constants';
import { AppSettings } from '../../appsettings';
import { FilterService } from '../../../services/filter-service.service';
import { MemberService } from '@CommonLib/services/member-service.service';

@AutoUnsubscribe
@Component({
  selector: 'app-auth-search',
  templateUrl: './auth-search.component.html',
  styleUrls: ['./auth-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthSearchComponent implements OnInit {
  @Input() item: MemberAuthData[] = [];
  @Input() isAuthSelectable: boolean = false;
  @Input() isMemberSelectable: boolean = false;
  @Input() isAuthHidden: boolean = false;

  @Output() clickAction1: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickAction2: EventEmitter<any> = new EventEmitter<any>();
  @Output() authSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() memberSelection: EventEmitter<any> = new EventEmitter<any>();
  timezone = '';
  constructor(private memberService: MemberService, private formService: FormService, private router: Router, private dialog: ConfirmDialogComponent,
    private tokenService: TokenStorageService, private appSettings: AppSettings, private filterService: FilterService) {
    this.timezone = this.appSettings.timeZone;
  }

  //Enable and disable search fields depending on radio selection
  searchByAuthNumber: boolean = true;
  searchByMemberId: boolean = false;
  searchByNameDob: boolean = false;

  authNumber!: number | undefined;
  memberId!: string;
  firstName!: string;
  lastName!: string;
  dob!: string;
  memberResult!: MemberSearch[];
  authForms!: AuthIntakeListItem[];
  sub!: Subscription;
  notFoundMsg = '';
  canViewSensitiveData: boolean = false;
  DateFormat = DATE_MDY;
  DateFormatSeconds = DATE_WITH_SECONDS_AMPM;

  formValid() : boolean {
    if (this.searchByNameDob) {
      return this.searchForm.valid;
    } else if (this.searchByAuthNumber) {
      return this.validAuthNumber();
    } else if (this.searchByMemberId) {
      if (!this.memberId || this.memberId.trim().length < 6) {
        return false;
      }
    }
    return true;
  }

  validAuthNumber(): boolean {
    if (this.authNumber == undefined) {
      return false;
    } else if (this.authNumber < 1000000) {
      return false;
    } else {
      return true;
    }

  }

  searchForm = new UntypedFormGroup({
    fName: new UntypedFormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]/)]),
    lName: new UntypedFormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]/)]),
    dobSearch: new UntypedFormControl('', [Validators.required]),
  })

  radioChange(event: MatRadioChange) {
    this.searchByAuthNumber = false;
    this.searchByMemberId = false;
    this.searchByNameDob = false;
    this.notFoundMsg = '';
    if (event.value == 'Auth Number') {
      this.item = [];
      this.memberId = '';
      this.firstName = '';
      this.lastName = '';
      this.dob = '';
      this.authNumber = undefined;
      this.searchByAuthNumber = true;
    }
    else if (event.value == 'Member ID') {
      this.item = [];
      this.firstName = '';
      this.lastName = '';
      this.dob = '';
      this.authNumber = undefined;
      this.searchByMemberId = true;
    }
    else {
      this.item = [];
      this.authNumber = undefined;
      this.memberId = '';
      this.searchByNameDob = true;
    }
  }

  ngOnInit(): void {
    this.item = [];
    this.sub = this.formService.SearchResult.subscribe(s => {
      if (s && s.length > 0) {
        this.item = s;
        if ((this.searchByMemberId && !this.memberId) || (this.searchByNameDob && !this.firstName) || (this.searchByAuthNumber && !this.authNumber)) {
          this.item = [];
          return;
        }

        this.item.forEach(r => {
          if (this.searchByAuthNumber && this.authNumber) {
            r.auth = r.auth?.filter(b => b.authNumber.includes(this.authNumber!.toString().trim() ?? ''));
          }
          r.auth?.forEach((a, index) => {
            if (a.authIntakeID == 0) {
              if (r.auth !== undefined) {
                r.auth.splice(index, 1);
              }
            }
          })
        })
        this.noResults = false;
      }
      else {
        this.notFoundMsg = 'No results found'
        this.noResults = true;
      }
    })
  }

  keyPressAlphaNumeric(event:any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  noResults: boolean = false;
  search() {
    const configUser = this.tokenService.getUserConfig();
    this.canViewSensitiveData = configUser.canViewSensitiveData;
    this.item = [];
    if (this.dob != '' && this.dob !== undefined) {
      this.dob = new Date(this.dob).toISOString();
    }
    let aNum = this.authNumber == undefined ? '' : this.authNumber.toString().trim();
    if (this.searchByNameDob) {
      if (!/[a-zA-Z]/.test(this.firstName) || (!/[a-zA-Z]/.test(this.lastName))) {
        const dialogRef = this.dialog.open({
          title: 'ERROR: Invalid values',
          message: `Invalid First/Last name values.`,
          acceptButtonText: 'Ok',
          declineButtonText: 'Cancel'
        });

        this.dialog.buttonAction.subscribe(result => {
          if (result) {

          }
          dialogRef.close();
        });
        return;
      } else {
        this.firstName = this.firstName.trim();
        this.lastName = this.lastName.trim();
      }
    } else if (this.searchByAuthNumber) {
      if (this.authNumber) {
        if (Number.isNaN(Number(this.authNumber))) {
          const dialogRef = this.dialog.open({
            title: 'ERROR: Invalid values',
            message: `Invalid Auth #. Must be a numeric value.`,
            acceptButtonText: 'Ok',
            declineButtonText: 'Cancel'
          });

          this.dialog.buttonAction.subscribe(result => {
            if (result) {

            }
            dialogRef.close();
          });
          return;
        }
      }
    }
    if (this.memberId) {
      this.memberId = this.memberId.trim();
    }
    this.formService.searchMemberAndAuth(this.firstName, this.lastName, this.dob, true, this.memberId, aNum);

    
  }

  authSelected($event: any) {
    if ($event) {
      //$event.value contains the auth number with prefix.
      this.authSelection.emit($event.value);
    }
  }

  memberSelected($event: any) {
    if ($event) {
      //$event.value contains the member id.
      this.memberSelection.emit($event.value);
    }
  }
  emitClickAction1(element: any) {
    this.formService.resetAuthForm();
    this.memberService.resetDemographics();
    this.memberService.resetEligibility();
    this.memberService.setMvdid('');
    this.filterService.resetSessionFilter(CHARTHISTORY);
    let url = "/member/authorizations/" + element.mvdid;
    this.router.navigate([url], {skipLocationChange:true});
  }

  emitClickAction2(element: any) {
    this.formService.resetAuthForm();
    this.memberService.resetDemographics();
    this.memberService.resetEligibility();
    this.memberService.setMvdid('');
    this.filterService.resetSessionFilter(CHARTHISTORY);
    let authDetailNumber = element.authNumber.split('-')[0];
    let url = "/member/authorization-intake/" + element.authIntakeID + "/" + authDetailNumber;
    this.router.navigate([url], { skipLocationChange: true });
  }

  getToolTip(procCodeId: string) {
  //  if (procCodeId == '') {
  //    return 'N/A';
  //  }
  //  var tip = this.toolTipList?.filter((p: AuthQueueData) => p.procCodeId == procCodeId)?.[0]?.procDesc;
  //  if (tip == null) {
      return `${procCodeId}: N/A`;
  //  }
  //  return `${procCodeId}: ${tip}`;
  }
}
