import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppSettings {
  configServiceBaseUrl!: string;
  formServiceBaseUrl!: string;
  autoSaveIntervalSeconds!: number;
  lookupServiceBaseUrl!: string;
  memberServiceBaseUrl!: string;
  ruleEngineSvcBaseUrl!: string;
  signalRSvcBaseUrl!: string;
  idpAuthority!: string;
  clientId!: string;
  clientRoot!: string;
  apiRoot!: string;
  redirectUri!: string;
  postLogoutRedirectUri!: string;
  customerId!: number;
  productId!: number;
  silentRedirectUri!: string;
  taskServiceBaseUrl!: string;
  reportServiceBaseUrl!: string;
  eligibilityServiceBaseUrl!: string;
  hiddenValues!: string;
  mcgInformedByBaseUrl!: string;
  storageServiceBaseUrl!: string;
  isUAT!: boolean;
  idleTimeoutSeconds!: number;
  maxAvatarFileSize!: number;
  samlEndpoint!: string;
  alertSvcBaseUrl!: string;
  environment!: string;
  maxQueueCount!: number;
  refreshInterval!: number;
  features!: {
    [featureName: string]: string;
  }
  submitStatuses!: string;
  powerBiSvcBaseUrl!: string;
  ediClientURL278!: string;
  timeZone!: string;
  constructor() { }
}
