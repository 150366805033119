import { Injectable } from '@angular/core';
import { TOKEN_KEY, USER_KEY, USER_CONFIG, USER_RBAC, MANAGERLIST_KEY, FILTERS_KEY } from '../../assets/constants';


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }
  signOut(): void {
    window.sessionStorage.clear();
  }
  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }
  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }
  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
  public saveUserConfig(userData: any): void {
    window.sessionStorage.removeItem(USER_CONFIG);
    window.sessionStorage.setItem(USER_CONFIG, JSON.stringify(userData));
  }
  public getUserConfig(): any {
    const user = window.sessionStorage.getItem(USER_CONFIG);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
  public saveUserRbac(rbacData: any): void {
    window.sessionStorage.removeItem(USER_RBAC);
    window.sessionStorage.setItem(USER_RBAC, JSON.stringify(rbacData));
  }

  public getUserRbac(): any {
    const rbac = window.sessionStorage.getItem(USER_RBAC);
    if (rbac) {
      return JSON.parse(rbac);
    }
    return {};
  }

  public saveManagerFilter(listData: string[]): void {
    if (listData.length == 0) listData = [];
    window.sessionStorage.removeItem(MANAGERLIST_KEY);
    window.sessionStorage.setItem(MANAGERLIST_KEY, JSON.stringify(listData));
  }
  public getManagerFilter(): any {
    const filter = window.sessionStorage.getItem(MANAGERLIST_KEY);
    if (filter && filter !== '{}') {
      return JSON.parse(filter);
    }
    return [];
  }

  public saveSessionFilter(filter_values: any, filter_type: string) {
    const stored_filters = window.sessionStorage.getItem(FILTERS_KEY);
    let filterMap = this.getSessionFilter(filter_type);
    if (filterMap == null) {
      filterMap = new Map<string, string>();
    }
    filterMap.set(filter_type, filter_values);

    window.sessionStorage.removeItem(FILTERS_KEY);
    window.sessionStorage.setItem(FILTERS_KEY, JSON.stringify(filterMap, this.replacer));
  }

  public getSessionFilter(filter_type: string): Map<string,string> {
    let stored_filters = window.sessionStorage.getItem(FILTERS_KEY);
    let filterObj = JSON.parse(stored_filters!, this.reviver);
    return filterObj;
  }

  public resetSessionFilter(filter_type: string) {
    this.saveSessionFilter('', filter_type);
  }

  private replacer(key: any, value: any[]) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  private reviver(key: any, value: any) {
    if(typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }
}
