export class AuthIntakeDetail {
  authDetailId!: number;
  authIntakeId!: number;
  authDetailNumber!: string;
  requestDate!: string;
  dueDate!: string;
  urgencyId!: number;
  currentStatus!: number;
  currentStatusReasonId!: number;
  rfiComplete!: boolean;
  admissionTypeId!: number | null;
  admissionType!: string | null;
  admitDate!: string | null;
  expectedDischargeDate!: string | null;
  requestedLos!: number | null;
  approvedLos!: number | null;
  aorOnFile!: boolean | null;
  aorDate!: string | null;
  requestorTypeId!: number;
  requestorLastName!: string;
  requestorFirstName!: string;
  requestorEmail!: string;
  requestorPhone!: string;
  requestorFax!: string;
  placeOfServiceId!: number | null;
  requestOriginId!: number;
  requestOriginOther!: string | null;
  requestTypeId!: number | null;
  extensionNeeded!: boolean;
  authGroupId! : number | null;
  authOwner!: string | null;
  lockedBy!: string;
  lockedDate!: string;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete!: boolean;
  expectedDispositionId!: number | null;
}
