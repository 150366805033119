import { Directive, HostListener, Renderer2 } from "@angular/core";
import { MatSelect, MatSelectChange } from "@angular/material/select";

@Directive({
  selector: 'mat-select'
})

// UnselectMatSelectDirective (a copy of this directive) is in the Common library that would eventually be used globally. 
// We will need to move all directives to the Common with the references to Models and Servics. 
// For now, UnselectMatSelectDirective is consumed in the Common only. 
export class OptionalMatSelectDirective {

  constructor(private matSelect: MatSelect, private renderer: Renderer2) { }

  @HostListener('openedChange', ['$event'])
  onOpenedChange(isOpened: boolean) {

    //if (!isOpened || this.matSelect.required) {
    //  return; //Closing panel, or select is required
    //}
    //Manually create a mat option DOM element
    let matOption = this.renderer.createElement("mat-option");
    this.renderer.setAttribute(matOption, 'class', 'mat-mdc-option');

    //Bind events to the new mat option
    this.renderer.listen(matOption, 'click', () => {
      this.matSelect.value = "";
      this.matSelect.selectionChange.emit(new MatSelectChange(this.matSelect, undefined));
      this.matSelect.close();
    });

    //Try to add the new mat option in first position of the list
    let panel = document.querySelector('.mat-mdc-select-panel');
    let matOptionText = this.renderer.createElement("span");
    this.renderer.setAttribute(matOptionText, 'class', 'mdc-list-item__primary-text');

    if (!panel) {
      //throw "Cannot find mat select panel";
      return;
    }
    this.renderer.insertBefore(panel, matOption, panel.firstChild);
    matOption.appendChild(matOptionText);
    matOptionText.innerHTML = 'Unselect';
  }
}
