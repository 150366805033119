import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Queue } from 'projects/um/src/app/models/configuration/queue';
import { ReferTaskTypes } from 'projects/um/src/assets/constants';
import { User } from "projects/um/src/app/models/user";
import { Observable, Subscription } from 'rxjs';
import { TokenStorageService } from 'projects/um/src/app/services/token-storage.service';
import { ConfigService } from 'projects/um/src/app/services/config-service.service';

@Component({
  selector: 'app-refer-fax',
  templateUrl: './refer-fax.component.html',
  styleUrls: ['./refer-fax.component.scss']
})
export class ReferFaxComponent implements OnInit, OnDestroy {
  userName!: string;
  teamMemberSearchCtrl = new UntypedFormControl(null, Validators.required);
  referType: number = ReferTaskTypes.MY_ASSIGNMENT;
  workQueueTypeId!: number;
  currentUserName!: string;
  workQueueTypes!: Queue[];
  userListData!: User[];
  sectionId: string = '';
  myAssignmentsSelected: boolean = true;
  workQueueSelected: boolean = false;
  teamMemberSelected: boolean = false;
  filteredOptions!: Observable<User[]>;
  referSelectedUserName!: string;
  userSub!: Subscription;
  groupSub!: Subscription;
  @Output() referFaxEvent: EventEmitter<string | number> = new EventEmitter<string | number>();

  constructor(private tokenService: TokenStorageService, private configService: ConfigService) {

  }

  ngOnInit(): void {
    this.currentUserName = this.tokenService.getUser().profile.preferred_username;
    this.userName = this.tokenService.getUser().profile.preferred_username;
    this.userSub = this.configService.userList.subscribe(data => {
      this.userListData = data;
      this.filteredOptions = this.teamMemberSearchCtrl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (value != undefined) {
            if (typeof value === 'string') return value;
            else return value.userName;
          }
        }),
        map(userName => userName ? this._filter(userName) : this.userListData.slice())
      );
    });
    this.groupSub = this.configService.faxQueues.subscribe(data => {
      if (data.length > 0) {
        this.workQueueTypes = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.groupSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }

  referFaxGroup = new UntypedFormGroup({
    workQueueCtrl: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
  })

  get workQueueCtrl() {
    return this.referFaxGroup.controls['workQueueCtrl'];
  }

  selectedTeamMember(event: any) {
    this.workQueueTypeId = 0;
    this.referSelectedUserName = event.option.value.userName;
  }

  referFax() { 
    if (this.workQueueTypeId > 0){
      this.referFaxEvent.emit(this.workQueueTypeId);
    } else {
      this.referFaxEvent.emit(this.referSelectedUserName);
    }
  }

  toggleActiveState(event: any) {
    this.myAssignmentsSelected = false;
    this.workQueueSelected = false;
    this.referFaxGroup.controls['workQueueCtrl'].disable();
    this.teamMemberSelected = false;
    this.sectionId = event.target.closest('section').id;
    if (this.sectionId == 'myAssignments') {
      this.referType = ReferTaskTypes.MY_ASSIGNMENT;
      this.myAssignmentsSelected = true;
      this.teamMemberSearchCtrl.reset();
      this.workQueueCtrl.reset();
    }
    if (this.sectionId == 'workQueue') {
      this.referType = ReferTaskTypes.WORK_QUEUE;
      this.workQueueSelected = true;
      this.referFaxGroup.controls['workQueueCtrl'].enable();
      this.teamMemberSearchCtrl.reset();
    }
    if (this.sectionId == 'teamMember') {
      this.referType = ReferTaskTypes.TEAM_MEMBER;
      this.teamMemberSelected = true;
      this.workQueueCtrl.reset();
    }
  };

  OnQueueSelectionChange(event: any) {
    this.referSelectedUserName = '';
    this.workQueueTypeId = event.value;
  }

  displayFn(user: User): string {
    return user && (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.userListData.filter(option => {
      return option.firstName.toLowerCase().includes(filterValue) ||
        option.lastName.toLowerCase().includes(filterValue);
    });
  }
}
