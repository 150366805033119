export class AuthIntakeHeader {
  authIntakeId!: number;
  authNumber!: string;
  authTypeId!: number;
  authOwner!: string;
  memberId!: string;
  mvdId!: string;
  eligibilityID!: number;
  reqNPI!: string | null;
  reqServiceLocationID!: string | null;
  srvcNPI!: string | null;
  srvcServiceLocationID!: string | null;
  treatmentTypeId!: number;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete: boolean = false;
  displayAuthNumber: string = 'N/A';
  sensitiveData!: boolean;
  reqFinalProviderHashId!: string | null;
  srvcFinalProviderHashId!: string | null;
  healthPlanEmployeeFlag!: boolean;
}
