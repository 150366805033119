import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BadgeData } from '../models/filter/BadgeData';
import { BehaviorSubject } from "rxjs";
import { DATE_MDY, TEAMQUEUEAUTHS, snapshotFilterTypes } from '../../assets/constants';
import { LookupValue } from '../models/Lookups/lookupValue';
import { formatDate } from '@angular/common';
import { FormService } from './form-service.service';
import { AuthQueueData } from '../shared/components/queue/AuthQueueData';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private authWorkQueue: AuthQueueData[]=[];
  constructor( private tokenService: TokenStorageService, private formService: FormService) { 
    this.formService.AuthWorkQueueList.subscribe(data => {
      if (data){
        this.authWorkQueue = data;
      }
    })
  }

  badgeDataBs = new BehaviorSubject<BadgeData>({text: '', count:0, filter_type: ''});
  
  getSessionFilter(filter_type: string) {
    let formFields = {};
    const filterMap = this.tokenService.getSessionFilter(filter_type) as Map<string,string>;
    let filterValues: {[k: string]: any} = {};
    if (filterMap) {
      filterValues = filterMap.get(filter_type) as Object;
      if (filterValues) {
        let keys = Object.keys(filterValues)
        if (keys.length == 0){
          return null;
        }
        for (let key of keys) {
          const value = filterValues[key];
          formFields[key as keyof Object] = value;
        }
      }
      return formFields;
    }
        return null;
  }

  applySessionFilter<Type>(list: any[], filter_type: string, lookupArr: LookupValue[][] = []) {
    if (list && list.length > 0) {
      list.map(a => {a.hidden = false});
      let group = this.getSessionFilter(filter_type);
        if (group) {
          if (filter_type == TEAMQUEUEAUTHS){
            list = [...this.authWorkQueue];
          }
          list.filter(n => this.filterList(n, group!, lookupArr))?.map(f => {f.hidden = true});
          this.setBadgeData(group!, filter_type);
        }
      return list;
    }
    return list;
  } 

  filterList<Type>(element: {[k: string]: any}, filterFields: {[k: string]: any}, lookupArr: LookupValue[][] = []): boolean {
    let result = true;
    const originalAuthNumber = element['authNumber'];    
    const originalDueDate = element['dueDate'];

    if (element['authNumber']) {
      element['authNumber'] = element['authNumber'].split('-')[1] ? element['authNumber'].split('-')[1] : element['authNumber'].split('-')[0];
    }

    for (const key of Object.keys(filterFields)) {
      if (key == 'reqDateStart' || key == 'reqDateEnd' || key == 'dueDateStart' ||  key == 'dueDateEnd' || key == 'snapshotFilter'
      || key == 'receivedDateStart' || key == 'receivedDateEnd' || key == 'createdDateStart' || key == 'createdDateEnd' || key == 'authOwner'
      || key == 'owner') continue;
      result = result && (filterFields[key] == null || element[key] == filterFields[key]);
    }

    if (filterFields['dueDateStart'] && filterFields['dueDateEnd']) {
      result = result && (
        moment(element['dueDate']) >= moment(filterFields['dueDateStart'])) && (moment(element['dueDate']) < moment(filterFields['dueDateEnd']).add(1,'days')
        );
    }
    
    if (filterFields['reqDateStart'] && filterFields['reqDateEnd']) {
      result = result && (
        moment(element['createdDate']) >= moment(filterFields['reqDateStart'])) && (moment(element['createdDate']) < moment(filterFields['reqDateEnd']).add(1,'days')
        );
    }    
    
    if (filterFields['receivedDateStart'] && filterFields['receivedDateEnd']) {
      result = result && (
        moment(element['createdDate']) >= moment(filterFields['receivedDateStart'])) && (moment(element['createdDate']) < moment(filterFields['receivedDateEnd']).add(1,'days')
        );
    }   
    
    if (filterFields['createdDateStart'] && filterFields['createdDateEnd']) {
      result = result && (
        moment(element['createdDate']) >= moment(filterFields['createdDateStart'])) && (moment(element['createdDate']) < moment(filterFields['createdDateEnd']).add(1,'days')
        );
    }

    if (filterFields['authOwner']) {
      let owners: string = filterFields['authOwner'];
      if (owners && owners.length > 0){
        result = result && (
          owners.includes(element['authOwner'])
          );
      }
    }

    if (filterFields['owner']) {
      let owners: string = filterFields['owner'];
      if (owners && owners.length > 0){
        result = result && (
          owners.includes(element['owner'])
          );
      }
    }

    if (filterFields['snapshotFilter']) {
      let pendingStatus = 0;
      let addInfoReason = 0;
      let physicianReviewReasson = 0;
      if (lookupArr[0] && lookupArr[1]) {
        pendingStatus = lookupArr[0].find(l => l.lookupStandardValue == 'PendingReview')?.lookupValueID!;
        addInfoReason = lookupArr[1].find(l => l.lookupStandardValue == 'AdditionalInformationNeeded')?.lookupValueID!;
        physicianReviewReasson = lookupArr[1].find(l => l.lookupStandardValue == 'PhysicianReview')?.lookupValueID!;
      }

      let date = formatDate(new Date(),DATE_MDY,'en_US');
      let dueDate = formatDate(element['dueDate'],DATE_MDY,'en_US');

      result = result && (
        (filterFields['snapshotFilter'] != snapshotFilterTypes.OVERDUE || (moment(element['dueDate']) <= moment()))
        &&
        (filterFields['snapshotFilter'] != snapshotFilterTypes.AWAITING_INFO || (element['authStatusID'] == pendingStatus && element['authStatusReasonId'] == addInfoReason))
        &&
        (filterFields['snapshotFilter'] != snapshotFilterTypes.IN_PHYSICIAN_REVIEW || (element['authStatusReasonId'] == physicianReviewReasson))
        &&
        (filterFields['snapshotFilter'] != snapshotFilterTypes.AUTHS_DUE_TODAY || (date === dueDate))
        &&
        (filterFields['snapshotFilter'] != snapshotFilterTypes.TASKS_DUE_TODAY || (date === dueDate))
      )
    }

    element['authNumber'] = originalAuthNumber;
    element['dueDate'] = originalDueDate;

    return !result;
  }
 
  saveAsSessionFilter(formGroup: UntypedFormGroup, filter_type: string): void {
    this.tokenService.saveSessionFilter(this.formGroupToObject(formGroup),filter_type);
  }

  formGroupToObject(formGroup: UntypedFormGroup): {} {
    let object: any = {};
    Object.keys(formGroup.controls).forEach(key => {
      if (formGroup.controls[key].value && ((key !== 'authOwner') || ((key === 'authOwner') 
        && (formGroup.controls[key].value.length && formGroup.controls[key].value.length > 0)))) 
      {
        object[key] = formGroup.controls[key].value;
      }
    });
    return object;
  }

  objectToFormGroup(object:{[key: string]: any}, formGroup: UntypedFormGroup): UntypedFormGroup {
    Object.keys(object).forEach(key => {
      if (object[key] && formGroup.controls[key]) {
          formGroup.controls[key].setValue(object[key]);
      }
    });
    return formGroup;
  }

  resetSessionFilter(filter_type: string) {
    this.tokenService.resetSessionFilter(filter_type);
    this.badgeDataBs.next({text: '', count: 0, filter_type});
  }

  setBadgeData(group: {}, filter_type: string) {
    let badgeData: BadgeData = {text: '', count: 0, filter_type: filter_type};
    const groupKeys = Object.keys(group) as Array<string>;
    for (const key of groupKeys) {
      if (key == 'dueDateStart' || key == 'dueDateEnd' 
          || key == 'reqDateStart' || key == 'reqDateEnd'
          || key == 'createdDateStart' || key == 'createdDateEnd'
          || key == 'completedDateStart' || key == 'completedDateEnd'
          || key == 'snapshotFilter' || key == 'faxStartDate' || key == 'faxEndDate') continue;
      badgeData.text = badgeData.text + this.badgeTextmap[key as keyof Object];
      badgeData.count++;
    }
    if (groupKeys.indexOf('dueDateStart') > -1 || groupKeys.indexOf('dueDateEnd') > -1) {
      badgeData.text = badgeData.text + this.badgeTextmap['dueDate'];
      badgeData.count++;
    }     
    
    if (groupKeys.indexOf('reqDateStart') > -1 || groupKeys.indexOf('reqDateEnd') > -1) {
      badgeData.text = badgeData.text + this.badgeTextmap['reqDate'];
      badgeData.count++;
    }      
    
    if (groupKeys.indexOf('createdDateStart') > -1 || groupKeys.indexOf('createdDateEnd') > -1) {
      badgeData.text = badgeData.text + this.badgeTextmap['createdDate'];
      badgeData.count++;
    }    

    if (groupKeys.indexOf('completedDateStart') > -1 || groupKeys.indexOf('completedDateEnd') > -1) {
      badgeData.text = badgeData.text + this.badgeTextmap['completedDate'];
      badgeData.count++;
    } 
    
    if (groupKeys.indexOf('faxStartDate') > -1 || groupKeys.indexOf('faxEndDate') > -1) {
      badgeData.text = badgeData.text + this.badgeTextmap['faxReceivedDate'];
      badgeData.count++;
    }

    if (groupKeys.indexOf('snapshotFilter') > -1) {
      switch (group['snapshotFilter' as keyof Object] as unknown as number) {
        case snapshotFilterTypes.OVERDUE:
          badgeData.text = badgeData.text + this.badgeTextmap['snapshotOverDue'];
          break;
        case snapshotFilterTypes.AWAITING_INFO:
          badgeData.text = badgeData.text + this.badgeTextmap['snapshotAddlInfo'];
          break;
        case snapshotFilterTypes.IN_PHYSICIAN_REVIEW:
          badgeData.text = badgeData.text + this.badgeTextmap['snapshotPhysReview'];
          break;
          case snapshotFilterTypes.AUTHS_DUE_TODAY:
            badgeData.text = badgeData.text + this.badgeTextmap['snapshotDueToday'];
          break;        
          case snapshotFilterTypes.TASKS_DUE_TODAY:
            badgeData.text = badgeData.text + this.badgeTextmap['snapshotDueToday'];
            break;
      }
      badgeData.count++;
    }

    badgeData.text = badgeData.text.replace(/,\s*$/, "")
    this.badgeDataBs.next(badgeData);
  }

  getSavedGroup(groupFields: {} | null, formGroup:UntypedFormGroup): UntypedFormGroup {
    if (groupFields) {
        let keys = Object.keys(groupFields);
        for (const key of Object.keys(groupFields)) {
          let value = groupFields[key as keyof Object];
          formGroup.controls[key].setValue(value);
        }
    }
    return formGroup;
  }

  private badgeTextmap = {
    authTypeId: 'Auth Type' + ', ',
    urgencyID: 'Priority' + ', ',
    authStatusId: 'Status' + ', ',
    srvcProvider: 'Provider' + ', ',
    memberId: 'MemberID' + ', ',
    authNumber: 'Auth#' + ', ',
    lob: 'LOB' + ', ',
    authGroupId: 'Work Queue' + ', ',
    srvcState: 'State' + ', ',
    dueDate: 'Due Date' + ', ',
    reqDate: 'Requested Date' + ', ',
    createdDate: 'Created Date' + ', ',
    completedDate: 'Completed Date' + ', ',
    snapshotOverDue: 'Overdue' + ', ',
    snapshotAddlInfo: 'Awaiting Information' + ', ',
    snapshotPhysReview: 'PhysicianReview' + ', ',
    snapshotDueToday: 'Due Today' + ', ',
    typeId: 'Type' + ', ',
    statusId: 'Status' + ', ',
    priorityId: 'Priority' + ', ',
    faxQueue: 'Work Queue' + ', ',
    faxReceivedDate: 'Date Received' + ', ',
    owner: 'Owner' + ', ',
    authOwner: 'Direct Reports' + ', ',
    cmOrgRegion: 'CM Org Region' + ', ',
    requestType: 'Request Type' + ', ',
  }
}

