<div class="queue-utilities" data-layout="row" data-layout-align="space-between center">
  <div data-layout="row" data-layout-align="space-between center">
    <span class="count">Showing: {{ queueDisplayCount }}</span>
    <app-refresh (iconClick)="refreshQueue()" [title]="refreshTitleText"></app-refresh>
  </div>
  <button mat-icon-button title="Filter queue" (click)="openFilterDialog()" matTooltipClass="multiline-tooltip" matTooltip="{{filterBadgeData.text}}">
    <mat-icon matBadge={{filterBadgeData.count}}
              matBadgeColor="warn"
              matBadgeSize="small"
              [matBadgeHidden]="filterBadgeData.count < 1"
              class="material-icons-outlined icon-dark"
              aria-hidden="false"
              aria-label="Filter queue">
      filter_list
    </mat-icon>
  </button>
  <span class="cdk-visually-hidden">
    Notification icon with overlaid badge showing the number 1
  </span>
</div>

<cdk-virtual-scroll-viewport name="myAuths" itemSize="1" autosize style="height: calc(100vh - 250px)">
  <div *cdkVirtualFor="let queue of allAuthsListByOwner; templateCacheSize: 0">
    <div *ngIf="!queue.hidden">
      <app-auth-queue [queue]="queue"
                      [isSelectable]="false"
                      [isMyQueue]="true"
                      [timezone]="timezone"
                      [toolTipList]="procCodeDescList">
      </app-auth-queue>
    </div>
  </div>
</cdk-virtual-scroll-viewport>

<app-auth-filter [openDialog]="openDialogEvent"
                 [auths]="unFilteredAuthsListByOwner"
                 (displayCountEvent)="displayCountUpdate($event)"
                 (dialogEvent)="dialogEvent()"
                 [filterType]="filterType">
</app-auth-filter>