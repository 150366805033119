import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthQueueData } from '../shared/components/queue/AuthQueueData';
import { Task } from "../models/task/task";

export interface transferData {
    queueId: number,
    items: any[]
}

export interface snapshotFilter {
  filterType: number
}

@Injectable({
  providedIn: 'root'
})


export class QueueService {
    
    private queues!: any;

    private queueDataSet = new BehaviorSubject<transferData>({queueId:-1, items: []});
    QueueItemsToAdd = this.queueDataSet.asObservable();

  private snapshotFilter = new BehaviorSubject<snapshotFilter>({ filterType:-1});
    SnapshotFilterToApply = this.snapshotFilter.asObservable();

    constructor() {
      }
    
    transferItems(targetQueueId: number, selectedItems: any) {
        this.queueDataSet.next({queueId: targetQueueId, items: selectedItems });
    }

    filterAuthOwnerQueue(sortType: number) {
      this.snapshotFilter.next({ filterType: sortType });
    }

    sortTasks(a: Task, b: Task) {
      const t1 = new Date(a.dueDate).getTime();
      const t2 = new Date(b.dueDate).getTime();
      if (a.priorityRank > b.priorityRank) return 1;
        else if (a.priorityRank < b.priorityRank) return -1;
              else {
                if (t1 > t2) return 1; 
                if (t1 < t2) return -1; 
                return 0;
              }
    }

    sortAuths(a: AuthQueueData, b: AuthQueueData) {
      let aUrgency = '0',
        aDueDate = a.dueDate,
        bUrgency = '0',
        bDueDate = b.dueDate,
        aSortValue = '',
        bSortValue = '';

      if (a.urgency.toLowerCase() != "urgent")
        aUrgency = '1'

      if (b.urgency.toLowerCase() != "urgent")
        bUrgency = '1'

      aSortValue = aUrgency + aDueDate;
      bSortValue = bUrgency + bDueDate;

      if (aSortValue < bSortValue) {
        return -1;
      }
      if (aSortValue > bSortValue) {
        return 1;
      }
      return 0
      }
}
