import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { FormService } from "./form-service.service";
import { TaskService } from "./task-service.service";
import { TokenStorageService } from "./token-storage.service";
import { ConfigService } from "./config-service.service";
import { AutoUnsubscribe } from "../shared/decorators/auto-unsubscribe.decorator";
import { AppSettingsService } from "./appsettings.service";
import { AppSettings } from "../shared/appsettings";
import { DocumentService } from "@CommonLib/services/docStorage.service";

@AutoUnsubscribe
@Injectable({
  providedIn: 'root'
})
/**
 * @brief 1/25/2024 Bhupinder Changed DocumentService reference to Common project.
 */
export class RefreshService {
  user: any;
  userGroupIds!: number[];
  groupSub!: Subscription;
  interval: any;
  refreshInterval: number = 300000;

  constructor(private formService: FormService, private tokenService: TokenStorageService, private taskService:TaskService, private configService: ConfigService,
    private documentService: DocumentService, private appSettings: AppSettings) {
    this.user = this.tokenService.getUser();
    this.configService.userGroupList.subscribe(data => {
      if (data.length > 0) {
        this.userGroupIds = data.map(d => d.queueId);
      }
    });
    this.refreshInterval = this.appSettings.refreshInterval;
  }

  initializeTimers() {
    if (!this.interval){
      this.interval = setInterval(() => {
        this.refreshQueues(this.userGroupIds);
      }, this.refreshInterval);
    }
  }

  pauseTimers() {
    clearInterval(this.interval);
  }

  refreshQueues(userGroupIds: number[]) {
    this.formService.getAuthIntakeWorkQueueByOwner(this.user.profile.preferred_username).subscribe();
    this.formService.getUmDashboardCounts();
    if (userGroupIds != undefined) {
      this.taskService.getTaskListByGroupID(userGroupIds);
      this.formService.getAuthIntakeWorkQueueByGroup(userGroupIds).subscribe();
    }
    this.taskService.getTaskListByOwner();
    this.documentService.getFaxList(this.appSettings.storageServiceBaseUrl);
  }
}
