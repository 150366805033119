import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, Observable, map } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api/apiResponse";
import { AppSettings } from "../shared/appsettings";
import { TokenStorageService } from "./token-storage.service";
import { LetterTemplate } from "../models/letters/letterTemplate";
import { LetterParameters } from "../models/letters/letterParameters";
import { ReportParams } from "../models/reporting/reportParams";
import { AddressBookRecord } from "../models/letters/addressBookRecord";
import { UserEntryParameters } from "../models/letters/userEntryParameters";
import { LetterResult } from "../models/letters/letterResult";
import { add } from "lodash";


@Injectable({
  providedIn: 'root'
})

export class ReportService {
  private reportServiceBaseUrl!: string;
  private reportSubj = new BehaviorSubject<string>("");
  reportObs = this.reportSubj.asObservable();   
  private letterGUIDSubj = new BehaviorSubject<LetterResult>(new LetterResult());
  letterGUIDObs = this.letterGUIDSubj.asObservable();    
  private reportFileSubj = new BehaviorSubject<ArrayBuffer>(new ArrayBuffer(0));
  reportFileObs = this.reportFileSubj.asObservable();   
  private addressBookSubj = new BehaviorSubject<AddressBookRecord[]>([]);
  addressBookObj = this.addressBookSubj.asObservable();  
  private userEntrySubj = new BehaviorSubject<boolean>(false);
  userEntryObs = this.userEntrySubj.asObservable();
  private letterTemplateSubj = new BehaviorSubject<LetterTemplate[]>([]);
  letterTemplateObs = this.letterTemplateSubj.asObservable();

  constructor(private http: HttpClient, private appConfig: AppSettings, private tokenService: TokenStorageService) {
    this.reportServiceBaseUrl = this.appConfig.reportServiceBaseUrl;
  }

  getSSRSReport(authIntakeId: number, reportPath: string | undefined, freeFormCount: number, authDetailNumber: string): void {
    const parameters = new LetterParameters();
    parameters.authIntakeId = authIntakeId;
    parameters.reportName = reportPath!;
    parameters.freeFormCount = freeFormCount;
    parameters.authDetailNumber = authDetailNumber;
    parameters.timeZoneOffset = this.getTimeZoneOffset();
    let reportUrl = this.reportServiceBaseUrl + "Report/GetSSRSReport?authIntakeId=";
    this.http.post<ApiResponse<any>>(reportUrl, JSON.stringify(parameters)).subscribe(
        data => {
            if (data.succeeded == true) {
                this.reportSubj.next(data.data!);         
            }
        }
    );
  }
  
  getReportFile(authIntakeNotificationId: number) {
    let options = { 'responseType': 'arraybuffer' as 'json' };

    let reportUrl = this.reportServiceBaseUrl + "Report/GetReportFile" + "?" + "authIntakeNotificationId=" + authIntakeNotificationId;
    this.http.get<ArrayBuffer>(reportUrl, options).subscribe(
        data => {
                this.reportFileSubj.next(data);         
        }
    );
  }

  getAddressBook(memberId: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let reportUrl = this.reportServiceBaseUrl + "Report/GetAddressBook" + "?" + "memberId=" + memberId;
    this.http.get<ApiResponse<AddressBookRecord[]>>(reportUrl, {headers}).subscribe(
        data => {
                this.addressBookSubj.next(data.data!);         
        }
    );
  }
  
  getLetterTemplates() {
    let reportUrl = this.reportServiceBaseUrl + "Report/GetLetterTemplates";
    this.http.get<ApiResponse<LetterTemplate[]>>(reportUrl).subscribe(
        data => {
          if (data.succeeded == true)
                this.letterTemplateSubj.next(data.data!);         
        }
    );
  }

  generateReportToSend(authIntakeId: string, authIntakeNotificationId: number, reportName: string, freeFormCount: number, 
        letterTypeId: number|undefined, authDetailNumber: string, isCover: boolean, memberId: string, address: AddressBookRecord,
      authDetailId: number): void {
    const parameters = new LetterParameters();
    parameters.authIntakeId = Number(authIntakeId);
    parameters.authIntakeNotificationId = authIntakeNotificationId;
    parameters.reportName = reportName!;
    parameters.freeFormCount = freeFormCount;
    parameters.authDetailNumber = authDetailNumber;
    parameters.letterTypeId = letterTypeId!;
    parameters.userName = this.tokenService.getUser().profile.preferred_username;
    parameters.isCover = isCover;
    parameters.memberId = memberId;
    parameters.isUpdate = true;
    parameters.timeZoneOffset = this.getTimeZoneOffset();
    parameters.address = address;
    parameters.authDetailId = authDetailId;


    if (reportName !== undefined && letterTypeId !== undefined) {
      const userName = this.tokenService.getUser().profile.preferred_username;
      let reportUrl = this.reportServiceBaseUrl + "Report/GetReportToSend/";
      this.http.post<ApiResponse<LetterResult>>(reportUrl, JSON.stringify(parameters)).subscribe(
          data => {
              if (data.succeeded == true) {
                  this.letterGUIDSubj.next(data.data!)
              }
          }
      );
    }
  }

  saveUserEntry(authIntakeNotificationIds:number[], Data:string[] | []) {
    const params : UserEntryParameters = {authIntakeNotificationIds : authIntakeNotificationIds, userEntries : Data};

    let saveUserEntryUrl = this.reportServiceBaseUrl + "Report/SaveUserEntry/";
    return this.http.post<ApiResponse<string>>(saveUserEntryUrl, JSON.stringify(params));
  }

  getTimeZoneOffset(): number {
    return - new Date().getTimezoneOffset()/60;
  }

  getReport(reportName: string, reportId: string|null, format: string, reportFolder: string, authDetailID: number, addAttachments: boolean): Observable<HttpEvent<Blob>> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let parameters: ReportParams[] = [];
    parameters.push({key:'reportName',value:reportName,parameter:false});
    
    if (reportId){
      parameters.push({key:'reportId',value:reportId,parameter:true});
    }
    parameters.push({key:'reportFolder',value:reportFolder,parameter:false});
    if (format){
      parameters.push({key:'format',value:format,parameter:false});
    }
    parameters.push({key:'AuthDetailID',value:authDetailID.toString(),parameter:true});

    if (addAttachments){
      parameters.push({key:'addAttachments',value:'true',parameter:false});
    }

    let reportUrl = this.reportServiceBaseUrl + "Report/GetReport";

    return this.http.request<Blob>(new HttpRequest(
      'GET',
      `${reportUrl}?reportParameters=${JSON.stringify(parameters)}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob',
        headers: headers
      }));
  }
}
