export class AuthIntakeAttachment {
  authIntakeAttachmentId!: number;
  authDetailId!: number;
  attachmentName!: string;
  attachmentTypeId!: number;
  attachmentType!: string;
  attachmentStorageId!: string;
  attachmentUrl!: string;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  obsolete: boolean = false;
  attachmentViewedBy!: string;
  attachmentViewedOn!: string;
  attachmentDownloadedBy!: string;
  attachmentDownloadedOn!: string;
  copiedValue!: boolean;
}
