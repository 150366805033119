import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api/apiResponse";
import { AppSettings } from "../shared/appsettings";
import { TokenStorageService } from "./token-storage.service";
import { NotificationService } from "../shared/notification.service";
import { Task, TaskAddInput, TaskUpdateInput } from "../models/task/task";
import { TaskQueueUpdate } from "../home/team-queue-tasks/team-queue-tasks.component";
import * as moment from "moment";
import { SupervisorFilter } from "../models/supervisorTools/supervisorFilter";
import { ReferTaskTypes } from "../../assets/constants";
import { TasksData } from "../models/task/taskData";
import { InboundFaxData } from "../models/fax/inboundFaxData";
import { OutboundFaxData } from "../models/fax/outboundFaxData";

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private taskServiceBaseUrl!: string;
  private user!:string;
  private userTaskListSubj = new BehaviorSubject<Task[]>([]);
  userTaskList = this.userTaskListSubj.asObservable();  
  private teamTaskListSubj = new BehaviorSubject<Task[]>([]);
  teamTaskList = this.teamTaskListSubj.asObservable();
  private memberTaskListSubj = new BehaviorSubject<Task[]>([]);
  memberTaskList = this.memberTaskListSubj.asObservable();
  private superTaskListSubj = new BehaviorSubject<TasksData>(new TasksData());
  superTaskListObs = this.superTaskListSubj.asObservable();
  private taskByIDSubj = new BehaviorSubject<Task>(new Task());
  taskByID = this.taskByIDSubj.asObservable(); 
  private taskUpdatedSubj = new BehaviorSubject<boolean>(false);
  taskUpdated = this.taskUpdatedSubj.asObservable();
  productId!: number;
  customerId!: number;
  public NewTaskCount: BehaviorSubject<number> = new BehaviorSubject(0);
  private supervisorTaskInboundFaxSubj = new BehaviorSubject<InboundFaxData>(new InboundFaxData);
  supervisorInboundFaxListObs = this.supervisorTaskInboundFaxSubj.asObservable();
  private supervisorTaskOutboundFaxSubj = new BehaviorSubject<OutboundFaxData>(new OutboundFaxData);
  supervisorOutboundFaxListObs = this.supervisorTaskOutboundFaxSubj.asObservable();
  private filterListSubj = new BehaviorSubject<SupervisorFilter[]>([]);
  filterListObj = this.filterListSubj.asObservable();
  private filterDelSubj = new BehaviorSubject<boolean>(false);
  filterDelObj = this.filterDelSubj.asObservable();

  constructor(private http: HttpClient, private appConfig: AppSettings, private tokenService: TokenStorageService,
    private notificationService: NotificationService, private config: AppSettings) {

    this.taskServiceBaseUrl = this.appConfig.taskServiceBaseUrl;
    this.user = this.tokenService.getUser().profile.preferred_username;
    this.productId = this.appConfig.productId;
    this.customerId = this.appConfig.customerId;
  }

  getTaskListByOwner(user?: string): void {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("User", user || this.user);
    queryParams = queryParams.append("ProductId", this.productId);
    queryParams = queryParams.append("CustomerId", this.customerId);

    let tasksListUrl = this.taskServiceBaseUrl + "GetTaskByOwner";
    this.http.get<ApiResponse<Task[]>>(tasksListUrl, { params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
              this.userTaskListSubj.next(data.data!);
              var newCount = 0;
              newCount = data.data?.filter(d => d.showAsNew == true).length ?? 0;
              this.NewTaskCount.next(newCount);
            }
        }
    );
  }

  getTaskListByGroupID(groupIds: number[]): void {
    if (groupIds.length == 0) return;
    let queryParams = new HttpParams();
    const groupIdsStr = groupIds.join(',');
    queryParams = queryParams.append("GroupIDs", groupIdsStr);
    queryParams = queryParams.append("ProductId", this.productId);
    queryParams = queryParams.append("CustomerId", this.customerId);
    let tasksListUrl = this.taskServiceBaseUrl + "GetTaskByGroupIDs";
    this.http.get<ApiResponse<Task[]>>(tasksListUrl, { params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
              this.teamTaskListSubj.next(data.data!);
              var newCount = 0;
              //newCount = data.data?.filter(d => d.showAsNew == true && !(d.statusId == TASKSTATUS_SUCCESSFUL || d.statusId == TASKSTATUS_UNSUCCESSFUL)).length ?? 0;
              //this.NewTeamTaskCount.next(newCount);
            }
        }
    );
  }

  getTasksSupervisor(userList: string, queueList: string, skipRows:number = 0, takeRows: number = 1000, filter: string): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    if (filter === '{}' || filter === 'null') filter = '';

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userNameList", userList);
    queryParams = queryParams.append("queueList", queueList);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("skipRows", skipRows);
    queryParams = queryParams.append("takeRows", takeRows);
    queryParams = queryParams.append("filter", filter);

    let tasksListUrl = this.taskServiceBaseUrl  + "GetTaskSupervisor";
    this.http.get<ApiResponse<TasksData>>(tasksListUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this.superTaskListSubj.next(data.data!);
        }
      }
    );
  }
  
  getTaskListByAuthIntakeID(authIntakeId: number): void {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authIntakeId", authIntakeId);
    queryParams = queryParams.append("ProductId", this.productId);
    queryParams = queryParams.append("CustomerId", this.customerId);
    let tasksListUrl = this.taskServiceBaseUrl + "GetTaskByAuthIntakeID";
    this.http.get<ApiResponse<Task[]>>(tasksListUrl, { params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
                this.teamTaskListSubj.next(data.data!);         
            }
        }
    );
  }
  
  getSupervisorFilters(filterTypeId: number): void {
    const currentUser = this.tokenService.getUser().profile.preferred_username;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", currentUser);
    queryParams = queryParams.append("filterTypeId", filterTypeId);

    let filterListUrl = this.taskServiceBaseUrl + "GetSupervisorFilterList";
    this.http.get<ApiResponse<SupervisorFilter[]>>(filterListUrl, { params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
                this.filterListSubj.next(data.data!);         
            }
        }
    );
  }

  deleteSupervisorFilter(filterId: number, filterTypeId: number): void {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("filterId", filterId);

    let filterDeleteUrl = this.taskServiceBaseUrl + "DeleteSupervisorFilter";
    this.http.delete<ApiResponse<number>>(filterDeleteUrl, {params: queryParams}).subscribe(data => {
      if (data.succeeded) {
        this.notificationService.info("Filter deleted...");
        this.getSupervisorFilters(filterTypeId);
        this.filterDelSubj.next(true);
      }
      else {
        this.notificationService.error("Filter delete failed...");
      }
    })
  }  
  
  addSupervisorFilter(filter: any, filterTypeId: number): void {
    let saveUrl = this.taskServiceBaseUrl + "AddSupervisorFilter";
    this.http.post<ApiResponse<number>>(saveUrl, filter).subscribe(
      data => {
        if (data.succeeded == true) {
          this.notificationService.success("Filter added successfully!");
          this.getSupervisorFilters(filterTypeId);
          return data.data;
        }
        else {
          this.notificationService.error("Error adding filter!");
          return -1;
        }
      }
    );
  }
        
  getSupervisorInboundFax(skipRows: number = 0, takeRows: number = 0, filter: string = ''): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    if (filter === '{}' || filter === 'null') filter = '';

    let queryParams = new HttpParams();
    queryParams = queryParams.append("skipRows", skipRows);
    queryParams = queryParams.append("takeRows", takeRows);
    queryParams = queryParams.append("filter", filter);
    
    let faxListUrl = this.taskServiceBaseUrl  + "GetSupervisorInboundFaxData";
    this.http.get<ApiResponse<InboundFaxData>>(faxListUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this.supervisorTaskInboundFaxSubj.next(data.data!);
        }
        else
        {
            console.log('no data returned, please check query.');
        }
      }
    );
  }

  getSupervisorOutboundFax(skipRows:number = 0, takeRows: number = 1000, filter: string =''): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    if (filter === '{}' || filter === 'null') filter = '';

    let queryParams = new HttpParams();
    queryParams = queryParams.append("skipRows", skipRows);
    queryParams = queryParams.append("takeRows", takeRows);
    queryParams = queryParams.append("filter", filter);

    let faxListUrl = this.taskServiceBaseUrl  + "GetSupervisorOutboundFaxData";
    this.http.get<ApiResponse<OutboundFaxData>>(faxListUrl, { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this.supervisorTaskOutboundFaxSubj.next(data.data!);
        }
        else
        {
            console.log('no data returned please check query');
        }
      }
    );
  }

   getTaskListByMVDID(mvdId: string): void {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("MVDID", mvdId);
    queryParams = queryParams.append("ProductId", this.productId);
    queryParams = queryParams.append("CustomerId", this.customerId);
    let tasksListUrl = this.taskServiceBaseUrl + "GetTaskByMVDID";
    this.http.get<ApiResponse<Task[]>>(tasksListUrl, { params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
                this.memberTaskListSubj.next(data.data!);         
            }
        }
    );
  }
  
  getTaskByTaskID(taskId: number): void {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("TaskID", taskId);
    let tasksListUrl = this.taskServiceBaseUrl + "GetTaskByTaskID";
    this.http.get<ApiResponse<Task>>(tasksListUrl, { params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
                this.taskByIDSubj.next(data.data!);         
            }
        }
    );
  }

  markTaskAsViewed(taskId: number): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let queryParams = new HttpParams();
    queryParams = queryParams.append("taskID", taskId);
    queryParams = queryParams.append("user", this.user);
    let taskViewUrl = this.taskServiceBaseUrl + "MarkTaskAsViewed";

    this.http.post(taskViewUrl, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  completeMDTask(authDetailId: number): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authDetailId", authDetailId);
    queryParams = queryParams.append("userName", this.user);
    queryParams = queryParams.append("updateDate", new Date().toISOString());
    let taskViewUrl = this.taskServiceBaseUrl + "CompleteMDTask";

    this.http.post(taskViewUrl, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }

  addTask(task: TaskAddInput):number {
    let saveUrl = this.taskServiceBaseUrl + "AddTask";
    this.http.post<ApiResponse<number>>(saveUrl, task).subscribe(
      data => {
        if (data.succeeded == true) {
          this.notificationService.success("Task saved successfully!");
          return data.data;
        }
        else {
          this.notificationService.error("Error saving task!");
          return -1;
        }
      }
    );
    return -1;
  }

  updateTask(task:TaskUpdateInput, mvdId:string):void {
    let saveUrl = this.taskServiceBaseUrl + "UpdateTask";
    this.http.put<ApiResponse<number>>(saveUrl, task).subscribe(
      data => {
        if (data.succeeded == true) {
          this.notificationService.success("Task updated successfully!");
          this.taskUpdatedSubj.next(true);
        }
        else {
          this.notificationService.error("Error updating task!");
        }
      }
    );
  }

  updateTaskQueues(params: TaskQueueUpdate) {
    let saveUrl = this.taskServiceBaseUrl + "UpdateTaskQueues";
    this.http.put<ApiResponse<number>>(saveUrl, params).subscribe(
      data => {
        if (data.succeeded == true) {
          this.notificationService.success("Task(s) updated successfully!");
          this.taskUpdatedSubj.next(true);
        }
        else {
          this.notificationService.error("Error updating task!");
        }
      }
    );
  }

  setOwnerAndGroup(referType:ReferTaskTypes, referSelectedUserName: string, workQueueTypeId: number) {
    const currentUser = this.tokenService.getUser().profile.preferred_username;
    switch (referType) {
      case ReferTaskTypes.MY_ASSIGNMENT:
        return {owner: currentUser, groupId: null}
      case ReferTaskTypes.WORK_QUEUE:
        return {owner: null, groupId: workQueueTypeId}
      case ReferTaskTypes.TEAM_MEMBER:
        return {owner: referSelectedUserName, groupId: null}
    }
  }

  setOverdueData(dueDateIn: string) {
    const dueDate = moment(dueDateIn);
    const dueDate24 = moment(dueDateIn).add(-24, 'hours');
    const now = moment(new Date);

    if (dueDate < now) 
      return { style: 'icon-danger', title: 'Overdue' };
    if (dueDate24  < now) {
      return { style:'icon-warning', title: 'Due soon' };
    }
    return null;
  }

  completePhysicianReviewCompleteTasks(authDetailId: number): void {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authDetailId", authDetailId);
    queryParams = queryParams.append("user", this.user);
    let taskViewUrl = this.taskServiceBaseUrl + "ClosePhysicianReviewCompleted";
    this.http.post(taskViewUrl, null, { headers: headers, params: queryParams }).subscribe(r => { });
  }
}
