<div class="home-container">
  <h1 class="cdk-visually-hidden">UM Home</h1>
  <section class="work-queue team-work-queue">
    <div data-layout="row" data-layout-align="space-between center">
      <h2 class="section-header">Team Work Queue</h2>
      <button mat-icon-button
              class="material-icons-outlined icon-dark"
              aria-hidden="false"
              title="Set a default tab"
              aria-label="Set a default tab"
              [matMenuTriggerFor]="teamTabMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #teamTabMenu="matMenu">
        <div id="setTeamDefaultTab" class="menu-header">Set Default Tab</div>
        <mat-radio-group aria-labelledby="setTeamDefaultTab" [(ngModel)]="selectedTeamTab" (change)="teamDefaultTabChanged($event)">
          <button mat-menu-item *ngFor="let t of teamTabs">
            <mat-radio-button [value]="t.value">{{ t.displayValue }}</mat-radio-button>
          </button>
        </mat-radio-group>
      </mat-menu>
    </div>
    <mat-tab-group class="queue-tabs" animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="selectedTeamTab">
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{newTeamAuthCount.value}}"
                matBadgeColor="warn"
                matBadgeSize="small"
                matBadgeOverlap="false"
                [matBadgeHidden]="!(newTeamAuthCount.value > 0)"
                matTooltipClass="tooltip"
                matTooltip={{newAuthCountMsg}}>
            Authorizations
          </span>
        </ng-template>
        <ng-template matTabContent>
          <app-team-queue-auths></app-team-queue-auths>
        </ng-template>
      </mat-tab>
      <mat-tab label="Faxes">
        <ng-template matTabContent>
          <app-team-queue-faxes></app-team-queue-faxes>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{newTeamTaskCount.value}}"
                matBadgeColor="warn"
                matBadgeSize="small"
                matBadgeOverlap="false"
                [matBadgeHidden]="!(newTeamTaskCount.value > 0)"
                matTooltipClass="tooltip"
                matTooltip={{newTaskCountMsg}}>
            Tasks
          </span>
        </ng-template>
        <ng-template matTabContent>
          <app-team-queue-tasks></app-team-queue-tasks>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </section>
  <div class="middle">
    <section data-layout="row wrap" data-layout-align="space-between top" class="user-profile-container">
      <div class="avator">
        <div>
          <mat-icon *ngIf="avatar" class="material-icons-outlined icon btn">
            <img id="avatar" [src]="avatar" style="max-width: 200px; height: 100px;" (click)="removeAvatar()" />
          </mat-icon>
          <mat-icon *ngIf="!avatar" class="material-icons-outlined icon">account_circle</mat-icon>
          <span *ngIf="!avatar">
            <input #fileInput
                  type="file"
                  accept="image/png"
                  style="display: none;"
                  (change)="uploadImage(fileInput.files)" />
          </span>
        </div>
      </div>
      <div data-flex="1 0 auto" class="profile">
        <div class="name">{{ userFullName }}</div>
        <div class="role">{{ userRole }}</div>
        <div class="date">{{ today }}</div>
      </div>
      <div class="notification" *ngIf="bellIcon">
        <button mat-icon-button title="View Notification" (click)="openAlertDialog()">
          <mat-icon aria-label="Notification" aria-hidden="false" matBadge="{{ unreadAlertCount }}" matBadgeColor="warn" matBadgeSize="small" class="material-icons-outlined icon" *ngIf="bellIcon">notifications</mat-icon>
          <span class="cdk-visually-hidden" *ngIf="bellIcon">
            Notification icon with overlaid badge showing the number {{ alerts.length }}
          </span>
        </button>
      </div>
    </section>

    <mat-button-toggle-group class="compact" [value]="toggle" (change)="toggleView($event)">
      <mat-button-toggle [value]="true">Today's Snapshot</mat-button-toggle>
      <mat-button-toggle [value]="false">Member Search</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="toggled-content">
      <div *ngIf="toggle"><app-snapshot></app-snapshot></div>
      <div *ngIf="!toggle"><app-member-search class="home-member-search"></app-member-search></div>
    </div>

  </div>
  <section class="work-queue my-work-queue">
    <div data-layout="row" data-layout-align="space-between center">
      <h2 class="section-header" *ngIf="!isManager">My Assignments</h2>
      <h2 class="section-header" *ngIf="isManager">Team Assignments</h2>
      <button mat-icon-button
              class="material-icons-outlined icon-dark"
              aria-hidden="false"
              title="Set a default tab"
              aria-label="Set a default tab"
              [matMenuTriggerFor]="myTabMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #myTabMenu="matMenu">
        <div id="setMyDefaultTab" class="menu-header">Set Default Tab</div>
        <mat-radio-group aria-labelledby="setMyDefaultTab" [(ngModel)]="selectedMyTab" (change)="myDefaultTabChanged($event)">
          <button mat-menu-item *ngFor="let m of myTabs">
            <mat-radio-button [value]="m.value">{{ m.displayValue }}</mat-radio-button>
          </button>
        </mat-radio-group>
      </mat-menu>
    </div>
    <mat-tab-group class="queue-tabs" animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="selectedMyTab">
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{newAuthCount.value}}"
                matBadgeColor="warn"
                matBadgeSize="small"
                matBadgeOverlap="false"
                [matBadgeHidden]="!(newAuthCount.value > 0)"
                matTooltipClass="tooltip"
                matTooltip={{newAuthCountMsg}}>
            Authorizations
          </span>
        </ng-template>
        <ng-template matTabContent>
          <app-my-queue-auths></app-my-queue-auths>
        </ng-template>
      </mat-tab>
      <mat-tab label="Faxes">
        <ng-template matTabContent>
          <app-my-queue-faxes></app-my-queue-faxes>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{newTaskCount.value}}"
                matBadgeColor="warn"
                matBadgeSize="small"
                matBadgeOverlap="false"
                [matBadgeHidden]="!(newTaskCount.value > 0)"
                matTooltipClass="tooltip"
                matTooltip={{newTaskCountMsg}}>
            Tasks
          </span>
        </ng-template>
        <ng-template matTabContent>
          <app-my-queue-tasks></app-my-queue-tasks>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

<!-- dialog content: New Alerts -->
<ng-template #viewAlertDialogTemplate>
  <div class="alert alert-info" role="alert">
    <span>Acknowledged Alerts will be removed automatically after 15 days.</span>
  </div>
  <div class="button-group push-right mb-15" *ngIf="isAlertChecked">
    <button mat-flat-button color="vdt-primary" (click)="removeAlerts()">Remove</button>
    <button mat-flat-button color="vdt-primary" (click)="setAsUnread()">Set as Unread</button>
  </div>
  <div class="section-container selectable" [ngClass]="{'active': data.checked}"
       *ngFor="let data of alerts">
    <div data-layout="row" data-layout-align="space-between center" data-layout-gap="1em">
      <div data-flex="auto">
        <div class="mb-5">
          <span class="text-sm">{{ data.memberName }}</span>
          <a (click)="openAuthHistory(data.mvdid, data.alertId); $event.preventDefault()" [routerLink]="['']" class="text-sm ml-3-pct" title="View the Authorization History for this member">{{ data.memberId }}</a>
          <a (click)="openAuth(data.authIntakeId, data.alertId, data.authId); $event.preventDefault()" [routerLink]="['']" class="text-sm ml-3-pct" title="Go to this Authorization">{{ data.authId }}</a>
        </div>
        <div (click)="data.checked = !data.checked; getCheckboxes()">
          <div class="text-md bold mb-5">{{ data.alertText }}</div>
          <div>
            <span class="tag tag-info" *ngIf="!data.readDate">Unread</span>
            <span class="tag tag-default" *ngIf="data.readDate">Acknowledged</span>
            <span class="ml-3-pct">{{ data.createdDate | date: dateWithSeconds }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="data.checked">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More actions">
          <mat-icon class="material-icons-outlined icon-dark">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="data.checked = !data.checked">Set as Unread</button>
          <button mat-menu-item>Remove</button>
        </mat-menu>
      </div>
      <div>
        <mat-checkbox [(ngModel)]="data.checked"
                      (change)="getCheckboxes()"
                      name="alert-{{data.alertId}}"
                      aria-label="Select this alert">
        </mat-checkbox>
      </div>
    </div>
  </div>
</ng-template>

