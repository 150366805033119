import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { BehaviorSubject, Subject } from "rxjs";
import { Alert } from '../models/signalR/alert';
import { AppSettings } from '../shared/appsettings';
import { NotificationService } from './notification.service';
import { TokenStorageService } from './token-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaxLockInfo } from '../models/signalR/faxLockInfo';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public notification!: string;
  private hubConnection!: signalR.HubConnection
  public connectionId!: string;
  messages: Subject<string> = new Subject();
  user: any;
  public AlertData = new BehaviorSubject<Alert[]>([]);
  public lockedFaxId = new BehaviorSubject<FaxLockInfo>(new FaxLockInfo());
  public unlockedFaxId = new BehaviorSubject<string>('');
  userData!: User;

  constructor(private config: AppSettings, private tokenService: TokenStorageService, private notifications: NotificationService, private snackBar: MatSnackBar ) {
    this.userData = this.tokenService.getUserConfig();
  }

  public startConnection = () => {
    if (!this.hubConnection || this.hubConnection.state !== signalR.HubConnectionState.Connected) {
      let options: IHttpConnectionOptions = {
        accessTokenFactory: () => {
          return this.tokenService.getToken() ?? '';
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      };
      this.user = this.tokenService.getUser();
      this.hubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(`${this.config.signalRSvcBaseUrl}broadcast`, options)
        .withAutomaticReconnect()
        .build();
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection started');          
          this.connected();
        })
        .catch((err:any) => console.log('Error while starting connection: ' + err))
    }
  }

  public refreshConnection = () => {
    console.log('Token refreshed...reconnecting to SignalR');
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
       this.hubConnection.stop();
    }
    this.startConnection();
  }

  public connected = () => {
    this.hubConnection.invoke("connected", this.user.profile.preferred_username,"")
      .then(() => {
      console.log('User connected ' + this.user.profile.preferred_username);
      })
      .catch((err: any) => {
        console.log('Connected method failed: ' + err)
      });
  }

  public addNotificationListener = () => {
    this.hubConnection.on('echo', (data) => {
      this.messages.next(data);
      console.log(data);
    });
  }
//Send out a message to ALL connected users
  public broadcastMessage=(message:string) => {
    this.hubConnection.invoke('broadcastMessage', message, 5, '')
      .catch((err: any) => console.error(err));
  }

  public clientMessage = (message: string, userId: string) => {
    this.hubConnection.invoke('broadcastMessage', message, 5, userId)
      .catch((err: any) => console.error(err));
  }

//Listen for messages
  public addBroadcastMessageListener = () => {
    this.hubConnection.on('message', (userName: string, message: string, applicationId: number) => {
      if (applicationId == this.config.productId && !userName){
        if (message.split(':').length > 1){
          if (message.split(':')[0] == 'locked'){
            var lockInfo = new FaxLockInfo();
            lockInfo.faxId = message.split(':')[1];
            lockInfo.displayName = message.split(':')[2];
            lockInfo.userName = '';
            lockInfo.lockDate = new Date().toISOString();
            this.lockedFaxId.next(lockInfo);
          } else if (message.split(':')[0] == 'unlocked'){
            this.unlockedFaxId.next(message.split(':')[1]);
          }
        }
      } else if (userName && applicationId == this.config.productId && userName == this.user.profile.preferred_username){
        this.snackBar.open(message, '', { duration: 10000 });
        //TODO: Verify if ok to add this to the alerts.
        //this.notifications.newAlertReceived(message);
      }
    })
  }
  //Listen for alerts
  public addAlertListener = () => {
    this.hubConnection.on('alert', (data: Alert) => {
      if (data) {
        this.snackBar.open(data.alertText, '', { duration: 5000 });
        if (data.attributes.length > 0) {
          let name = data.attributes.find(a => a.name.toLowerCase() == 'member name');
          let authId = data.attributes.find(a => a.name.toLowerCase() == 'auth number');
          let authIntakeId = data.attributes.find(a => a.name.toLowerCase() == 'auth intake id');
          let memberId = data.attributes.find(a => a.name.toLowerCase() == 'member id');
          if (name) {
            data.memberName = name.value;
          }
          if (authId) {
            data.authId = authId.value;
          }
          if (authIntakeId) {
            data.authIntakeId = +authIntakeId.value;
          }
          if (memberId) {
            data.memberId = memberId.value;
          }
        }
        this.notifications.newAlertReceived(data);
      }
    })
  }
  //receieve(message: Message): Message[] {
  //  // read in from local strorage
  //  const messages = this.load();
  //  messages.unshift(message);
  //  localStorage.setItem("messages", JSON.stringify(messages));
  //  return messages;
  //}

  //load(): Message[] {
  //  const messagesLocal = localStorage.getItem("messages");
  //  let messagesResponse = [];
  //  if (messagesLocal !== null) {
  //    messagesResponse = JSON.parse(messagesLocal);
  //  }
  //  return messagesResponse;
  //}

  //clear(): Observable<null> {
  //  const messagesLocal = localStorage.getItem("messages");
  //  let messagesResponse:any = [];
  //  if (messagesLocal !== null) {
  //    localStorage.setItem("messages", JSON.stringify(messagesResponse));
  //  }
  //  return of(null);
  //}
}
