<!-- dialog content: Refer Auth -->
<ng-template #referAuthDialogTemplate>
  <ng-template [ngTemplateOutlet]="referAuthContentTemplate">
  </ng-template>
</ng-template>

<ng-template #referAuthContentTemplate>
  <form [formGroup]="referGroup">
    <section class="section-container selectable" id="myAssignments" title="Select this action" [ngClass]="{'active': myAssignmentsSelected}" (click)="toggleActiveState($event)">
      <div data-layout="row" data-layout-align="space-between start">
        <h2 class="section-title mb-0">Add to My Assignments</h2>
        <mat-icon class="material-icons-outlined icon-dark isSelectedIcon">check</mat-icon>
      </div>
    </section>
    <section class="section-container selectable" id="workQueue" title="Select this action" [ngClass]="{'active': workQueueSelected}" (click)="toggleActiveState($event)">
      <div data-layout="row" data-layout-align="space-between start">
        <h2 class="section-title mb-5">Refer to Work Queue</h2>
        <mat-icon class="material-icons-outlined icon-dark isSelectedIcon">check</mat-icon>
      </div>
      <mat-form-field class="full-width no-hint white" appearance="outline">
        <mat-label>Select a work queue</mat-label>
        <mat-select (selectionChange)="OnQueueSelectionChange($event)"
                    formControlName="workQueueCtrl"
                    (openedChange)="filterWorkQ.value=''">
          <input matInput placeholder="Search" #filterWorkQ>
          <div *ngFor="let workQueueType of workQueueTypes">
            <mat-option [value]="workQueueType.queueId" *ngIf="workQueueType.queueName.toLowerCase().includes((filterWorkQ.value).toLowerCase())">{{ workQueueType.queueName }}</mat-option>
          </div>
        </mat-select>
        <mat-error>Please select a work queue.</mat-error>
      </mat-form-field>
    </section>
    <section class="section-container selectable" id="teamMember" title="Select this action" [ngClass]="{'active': teamMemberSelected}" (click)="toggleActiveState($event)">
      <div data-layout="row" data-layout-align="space-between start">
        <h2 class="section-title mb-5">Refer to a team member</h2>
        <mat-icon class="material-icons-outlined icon-dark isSelectedIcon">check</mat-icon>
      </div>
      <mat-form-field class="full-width white" appearance="outline">
        <mat-label>Search Team Member</mat-label>
        <input type="text"
              matInput
              placeholder="Type name"
              [formControl]="teamMemberSearchCtrl"
              [matAutocomplete]="auto">
        <mat-icon matSuffix class="icon">search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectedTeamMember($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option.firstName }} {{ option.lastName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>Please select a team member.</mat-error>
      </mat-form-field>
    </section>
  </form>
</ng-template>
