import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppSettings } from '../../shared/appsettings';
import { HeaderComponent } from '../header/header.component';

@Component({
  providers: [HeaderComponent],
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  reportingMenu!: boolean;
  faxArchiveMenu!: boolean;
  configurations!: boolean;
  isExpanded = true;
  isShowing = false;
  showAdminSubmenu: boolean = false;
  showLinksSubmenu: boolean = false;
  externalId!: string;
  customerId!: number;

  constructor(private appSettings: AppSettings, private appHeader: HeaderComponent) {
    if (appSettings.features && appSettings.features['reportingMenu']) {
      this.reportingMenu = JSON.parse(appSettings.features['reportingMenu']);
      this.faxArchiveMenu = JSON.parse(appSettings.features['faxArchiveMenu']);
    }
    if (appSettings.features && appSettings.features['configurations']) {
      this.configurations = JSON.parse(appSettings.features['configurations']);
    }
  }

  ngOnInit(): void {   
    this.customerId = this.appSettings.customerId;
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  public openUnlockAuthDialog() {
    this.appHeader.openUnlockAuthDialog();
  }

  public openImpersonateRoleDialog() {
    this.appHeader.openImpersonateRoleDialog();
  }

  public openPortal() {
    this.appHeader.openPortal(this.externalId);
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

}
