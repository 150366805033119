<ng-template #filterTaskDialogTemplate>
  <form [formGroup]="taskQueueFilterGroup">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Due Date Range</mat-label>
      <mat-date-range-input [formGroup]="taskQueueFilterGroup"
                            [rangePicker]="dateRangeTaskMyQueuePicker"
                            cdkFocusInitial>
        <input matStartDate placeholder="Start date" formControlName="dueDateStart">
        <input matEndDate placeholder="End date" formControlName="dueDateEnd">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangeTaskMyQueuePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangeTaskMyQueuePicker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Type</mat-label>
      <mat-select formControlName="typeId"
                  (openedChange)="filterTaskType.value=''">
        <input matInput placeholder="Search" #filterTaskType>
        <div *ngFor="let option of taskTypes">
          <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskType.value).toLowerCase())">
            {{option.lookupStandardLabel}}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priorityId"
                  (openedChange)="filterTaskPriority.value=''">
        <input matInput placeholder="Search" #filterTaskPriority>
        <div *ngFor="let option of taskPriorities">
          <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskPriority.value).toLowerCase())">
            {{option.lookupStandardLabel}}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill" *ngIf="isManager">
      <mat-label>My Direct Reports</mat-label>
      <mat-select formControlName="owner"
                  (openedChange)="filterDirectReports.value=''"
                  multiple>
        <input matInput placeholder="Search" #filterDirectReports>
        <div *ngFor="let s of directReports | async">
          <mat-option [value]="s.userName" *ngIf="s.userName.toLowerCase().includes((filterDirectReports.value).toLowerCase())">{{ s.firstName + ' ' + s.lastName }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>
    <hr />

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Member ID</mat-label>
      <input matInput value="" formControlName="memberId">
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Authorization #</mat-label>
      <input matInput value="" formControlName="authNumber">
    </mat-form-field>
  </form>
</ng-template>
