<div mat-dialog-title class="close">
  <button mat-flat-button color="iq-primary" class="iq-button-close" [mat-dialog-close]>
    Close
  </button>
  <button mat-flat-button color="iq-primary" class="iq-button-refresh" (click)="updateStatus()">
    Update Status
  </button>
</div>
<mat-dialog-content>
  <iframe #iframe id="iqconnectFrame" *ngIf="safeUrl" [src]="safeUrl" frameborder="0" (load)="init()" style="width: 100%; height: 100%"></iframe>
</mat-dialog-content>
