import { Component, OnInit, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Task } from "../../models/task/task";
import { LookupValue } from '../../models/Lookups/lookupValue';
import { LookupService } from '../../services/lookup.service';
import { TaskService } from '../../services/task-service.service';
import { QueueService } from '../../services/queue.service';
import { FilterService } from '../../services/filter-service.service';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';
import { MYQUEUETASKS, TASKSTATUS_SUCCESSFUL, TASKSTATUS_UNSUCCESSFUL } from 'projects/um/src/assets/constants';

@AutoUnsubscribe

@Component({
  selector: 'app-my-queue-tasks',
  templateUrl: './my-queue-tasks.component.html',
  styleUrls: ['./my-queue-tasks.component.scss']
})
export class MyQueueTasksComponent implements OnInit {
  @ViewChild('filterTaskDialogTemplate') filterTaskTemplate: TemplateRef<any> | undefined;

  filterBadgeData = { text: '', count: 0 }
  openDialogEvent = new EventEmitter<boolean>();
  tasks!: Task[];
  totalTasks = 0;
  lookupValues!: LookupValue[];
  successId!: number[];
  unsuccessfulId!: number[];
  filterType = MYQUEUETASKS;

  constructor(private queueService: QueueService, private taskService: TaskService, private lookupService: LookupService, private filterService: FilterService) {}

  //Refresh task queue icon 
  refreshTitleText = 'Refresh task queue';
  refreshQueue() {
    this.taskService.getTaskListByOwner();
  }

  sub!: Subscription;
  allTasksList = new BehaviorSubject<Task[]>([]);

  ngOnInit(): void {
    //this.lookupService.getAllLookupValues();
    this.sub = this.lookupService.LookupData.subscribe(l => {
      if (l && l.length > 0) {
        this.lookupValues = l;
        this.successId = l.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_SUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
        this.unsuccessfulId = l.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_UNSUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
        if (this.tasks) {
          this.statusFilter();
          this.allTasksList.next(this.tasks);
          this.totalTasks = this.tasks.filter(t => t.hidden == false).length;        
        }
      }
    })
    
    this.sub.add(this.taskService.userTaskList.subscribe(data => {
      if (data.length > 0) {
        this.tasks = data;
        this.tasks.sort((a,b) => this.queueService.sortTasks(a, b));
        this.tasks = this.filterService.applySessionFilter(this.tasks, MYQUEUETASKS);
        this.statusFilter();
        this.allTasksList.next(this.tasks);
        this.totalTasks = this.tasks.filter(t => t.hidden == false).length;
      }
    }));
    this.taskService.getTaskListByOwner();
    this.sub.add((this.taskService.taskUpdated.subscribe(data => {
      if (data) {
        this.taskService.getTaskListByOwner();
      }
    })));
    this.sub.add(this.filterService.badgeDataBs.subscribe(bd => {
      if (bd.filter_type == MYQUEUETASKS) {
        this.filterBadgeData = bd;
      }
    }));
  }

  statusFilter() {
    this.tasks = this.tasks.filter(t=> (!this.successId.includes(t.statusId) || this.unsuccessfulId.includes(t.statusId)));
  }

  openFilterDialog() {
    this.openDialogEvent.emit(true);
  }

  dialogEvent(tasks: Task[]) {
    this.tasks = tasks;
    this.statusFilter();
    this.totalTasks = this.tasks.filter(a => a.hidden == false).length
    this.allTasksList.next(this.tasks);
  }

  displayCountEvent(count:number) {
    this.totalTasks = count;
  }
}
