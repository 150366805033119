import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@CommonLib/components/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogFaxComponent } from './dialog-fax.component';
import { AuthSearchModule } from '../../auth-search/auth-search.module';
import { ReferFaxComponent } from '../refer-fax/refer-fax.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    AuthSearchModule,
    FormsModule
  ],
  declarations: [
    DialogFaxComponent,
    ReferFaxComponent
  ],
  exports: [
    DialogFaxComponent
  ]
})
export class DialogFaxModule { }
